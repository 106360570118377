/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LoginCredential } from '../models/login-credential';
import { UserRole } from '../models/user-role';
import { GetActiveRequest } from '../models/get-active-request';
import { EntityCallFunctionRequest } from '../models/entity-call-function-request';
import { SetUserRoleRequest } from '../models/set-user-role-request';
import { ChangePassword } from '../models/change-password';
@Injectable({
  providedIn: 'root',
})
class UserService extends __BaseService {
  static readonly loginUserPath = '/auth/login';
  static readonly clearSessionPath = '/auth/clear-session';
  static readonly getSingleSignOnTokenPath = '/auth/read_sso_token/{hash}';
  static readonly getUserRolePath = '/user/getactive';
  static readonly callUserRoleFunctionPath = '/user/callUserRoleFunction';
  static readonly setUserRolePath = '/user/setactive';
  static readonly changePasswordPath = '/user/change-password';
  static readonly autoLoginPath = '/auth/auto_login';
  static readonly checkConnectivityPath = '/auth/check_connectivity';
  static readonly logoutUserPath = '/user/logout';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Logs user into the system
   * @param loginCredential Address object that needs to be added
   * @return successful operation
   */
  loginUserResponse(loginCredential: LoginCredential): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = loginCredential;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/auth/login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * Logs user into the system
   * @param loginCredential Address object that needs to be added
   * @return successful operation
   */
  loginUser(loginCredential: LoginCredential): __Observable<Array<string>> {
    return this.loginUserResponse(loginCredential).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * Get Sessionr
   * @return successful operation
   */
  clearSessionResponse(): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/auth/clear-session`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * Get Sessionr
   * @return successful operation
   */
  clearSession(): __Observable<boolean> {
    return this.clearSessionResponse().pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * Get Sessionr
   * @param hash undefined
   * @return successful operation
   */
  getSingleSignOnTokenResponse(hash: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/auth/read_sso_token/${encodeURIComponent(String(hash))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Get Sessionr
   * @param hash undefined
   * @return successful operation
   */
  getSingleSignOnToken(hash: string): __Observable<string> {
    return this.getSingleSignOnTokenResponse(hash).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Get Sessionr
   * @param body Set of Passwords
   * @return successful operation
   */
  getUserRoleResponse(body: GetActiveRequest): __Observable<__StrictHttpResponse<UserRole>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user/getactive`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserRole>;
      })
    );
  }
  /**
   * Get Sessionr
   * @param body Set of Passwords
   * @return successful operation
   */
  getUserRole(body: GetActiveRequest): __Observable<UserRole> {
    return this.getUserRoleResponse(body).pipe(
      __map(_r => _r.body as UserRole)
    );
  }

  /**
   * @param body Set of Passwords
   * @return successful operation
   */
  callUserRoleFunctionResponse(body: EntityCallFunctionRequest): __Observable<__StrictHttpResponse<UserRole>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user/callUserRoleFunction`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserRole>;
      })
    );
  }
  /**
   * @param body Set of Passwords
   * @return successful operation
   */
  callUserRoleFunction(body: EntityCallFunctionRequest): __Observable<UserRole> {
    return this.callUserRoleFunctionResponse(body).pipe(
      __map(_r => _r.body as UserRole)
    );
  }

  /**
   * @param body Set of Passwords
   * @return successful operation
   */
  setUserRoleResponse(body: SetUserRoleRequest): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user/setactive`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param body Set of Passwords
   * @return successful operation
   */
  setUserRole(body: SetUserRoleRequest): __Observable<boolean> {
    return this.setUserRoleResponse(body).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * Get Sessionr
   * @param body Set of Passwords
   * @return successful operation
   */
  changePasswordResponse(body: ChangePassword): __Observable<__StrictHttpResponse<UserRole>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user/change-password`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserRole>;
      })
    );
  }
  /**
   * Get Sessionr
   * @param body Set of Passwords
   * @return successful operation
   */
  changePassword(body: ChangePassword): __Observable<UserRole> {
    return this.changePasswordResponse(body).pipe(
      __map(_r => _r.body as UserRole)
    );
  }

  /**
   * Logs user into the system
   * @return successful operation
   */
  autoLoginResponse(): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/auth/auto_login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * Logs user into the system
   * @return successful operation
   */
  autoLogin(): __Observable<Array<string>> {
    return this.autoLoginResponse().pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * Logs user into the system
   * @return successful operation
   */
  checkConnectivityResponse(): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/auth/check_connectivity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * Logs user into the system
   * @return successful operation
   */
  checkConnectivity(): __Observable<boolean> {
    return this.checkConnectivityResponse().pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * Logs user out of the system
   */
  logoutUserResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user/logout`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Logs user out of the system
   */
  logoutUser(): __Observable<null> {
    return this.logoutUserResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
}

module UserService {
}

export { UserService }
