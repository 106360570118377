import {Component, OnInit} from '@angular/core';
import {TranslationService} from '../../shared/services/translation.service';
import {FileMapping, Settings} from '../../pit/structure';
import {ConfigService} from '../../shared/services/config.service';

@Component({
  selector: 'app-login-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  settings: Settings;

  fileMapping: FileMapping;

  constructor(public translationService: TranslationService,
              private configService: ConfigService) {

  }


  ngOnInit(): void {
    this.settings = this.configService.getSettings();
    this.fileMapping = this.configService.getFileMapping();
  }

}
