<div *ngIf="authService.languagesConfig" class="navbar-dropdown languages-dropdown ml-auto ml-xl-0">
  <div *ngIf="authService.defaultLang" class="dropdown">

    <button mat-button [matMenuTriggerFor]="menu">
      <i class="ti ti-user"></i> <span class="d-none d-md-inline-block">{{ authService.defaultLang.countryCode }}</span>
    </button>
    <mat-menu #menu="matMenu">


      <button *ngFor="let language of authService.languagesConfig.languages" (click)="switchLanguage(language)" mat-menu-item [innerHTML]="language.countryCode">
      </button>

    </mat-menu>
<!--    <a href="#" *ngIf="authService.defaultLang" class="p-2 dropdown-toggle" id="languages" data-toggle="dropdown" aria-expanded="false">-->
<!--      {{ authService.defaultLang.countryCode }}-->
<!--    </a>-->
    <ul class="dropdown-menu shadow dropdown-menu-right" aria-labelledby="languages">
      <li>
        <a *ngFor="let language of authService.languagesConfig.languages" (click)="switchLanguage(language)" class="dropdown-item" >
          {{language.countryCode}}
        </a>
      </li>
    </ul>
  </div>
</div>
