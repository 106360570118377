<div *ngIf="(isLoading | async) || (isNavigating | async)"
     [ngClass]="{'loadingPanel': true } "></div>
<div class="loader" *ngIf="(isLoading | async) || (isNavigating | async)">
  <ejs-progressbar
    #linear
    id="linear"
    type="Linear"
    [maximum]="max"
    [minimum]="min"
    [value]="value"
    [animation]="animationIndeterminate"
    progressThickness="5"
    isIndeterminate="true"
    progressColor="#1C64C4">
  </ejs-progressbar>
</div>
