<div *ngIf="callBackService.currentMessageBox">
  <h5>{{ callBackService.currentMessageBox.message }}</h5>
  <app-buttons-ok
    (onClose)="closeDialog()"
    *ngIf="callBackService.currentMessageBox.queryButtonEnum === queryButtonEnum.OK"></app-buttons-ok>

  <app-buttons-ok-cancel

    (onClose)="closeDialog()"
    *ngIf="callBackService.currentMessageBox.queryButtonEnum === queryButtonEnum.OK_CANCEL"></app-buttons-ok-cancel>

  <app-buttons-yes-no
    (onClose)="closeDialog()"
    *ngIf="callBackService.currentMessageBox.queryButtonEnum === queryButtonEnum.YES_NO"></app-buttons-yes-no>

  <app-buttons-yes-no-cancel
    (onClose)="closeDialog()"
    *ngIf="callBackService.currentMessageBox.queryButtonEnum === queryButtonEnum.YES_NO_CANCEL"></app-buttons-yes-no-cancel>

  <app-buttons-abort-retry-ignore
    (onClose)="closeDialog()"
    *ngIf="callBackService.currentMessageBox.queryButtonEnum === queryButtonEnum.ABORT_RETRY_IGNORE"></app-buttons-abort-retry-ignore>

  <app-buttons-retry-cancel
    (onClose)="closeDialog()"
    *ngIf="callBackService.currentMessageBox.queryButtonEnum === queryButtonEnum.RETRY_CANCEL"></app-buttons-retry-cancel>
</div>
