<div class="main-wrapper">

</div>
<div #pinDialog class="showEle pin-modal-wrapper">
  <div class="pin-modal-header">
    <div class="pin-modal-header-icon-wrapper">
      <div style="float: left; width: 100%;  padding: 5px; color: white;">
        {{ messageStackService.messages[0].title }}
      </div>
    </div>
  </div>

  <div class="pin-modal-content">
    {{ messageStackService.messages[0].text }}

  </div>
  <div class="pin-modal-content">
    <button (click)="this.removeUserMessage()" class="btn btn-outline-secondary" type="button">Ok</button>
  </div>


</div>
