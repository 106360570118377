import { Component, OnInit } from '@angular/core';
import {RoutingProviderService} from '../../../pit/services/routing-provider.service';
import {MessageStackService} from '../../services/message-stack.service';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.css']
})
export class MessageDialogComponent implements OnInit {

  constructor(public messageStackService: MessageStackService) { }

  ngOnInit(): void {
  }

  removeUserMessage() {
    this.messageStackService.moveUserMessageToHistory();

  }

}
