import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {LoaderService} from '../../services/loader.service';
import {ConfigService} from '../../services/config.service';
import {Settings} from '../../../pit/structure';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.less']
})
export class LoaderComponent implements OnInit {
  isLoading: Subject<boolean> = this.loaderService.isLoading;

  isNavigating: Subject<boolean> = this.loaderService.isNavigating;

  public min = 0;

  public max = 100;

  public value = 60;

  settings: Settings;

  public animationIndeterminate = {enable: true, duration: 8000, delay: 5000};

  constructor(private loaderService: LoaderService,
              private configService: ConfigService) {

  }

  ngOnInit() {
    this.settings = this.configService.getSettings();


  }
}
