/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SetAppIdRequest } from '../models/set-app-id-request';
import { AnswerCallBackQueryRequest } from '../models/answer-call-back-query-request';
import { EntityData } from '../models/entity-data';
@Injectable({
  providedIn: 'root',
})
class SessionService extends __BaseService {
  static readonly rollbackPath = '/session/rollback';
  static readonly commitPath = '/session/commit';
  static readonly setAppIdPath = '/session/setAppId';
  static readonly answerCallBackQueryPath = '/session/answercallbackquery';
  static readonly getSessionPath = '/session';
  static readonly getCurrentUserEntityDataPath = '/session/get-current-user-entity-data';
  static readonly soapHealthCheckPath = '/soap-health-check';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Rollback Entities
   */
  rollbackResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/session/rollback`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Rollback Entities
   */
  rollback(): __Observable<null> {
    return this.rollbackResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get Entity Descriptor
   */
  commitResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/session/commit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Get Entity Descriptor
   */
  commit(): __Observable<null> {
    return this.commitResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param body ID of Organization to return
   * @return successful operation
   */
  setAppIdResponse(body: SetAppIdRequest): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/session/setAppId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param body ID of Organization to return
   * @return successful operation
   */
  setAppId(body: SetAppIdRequest): __Observable<boolean> {
    return this.setAppIdResponse(body).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * Get Entity Descriptor
   * @param body ID of Organization to return
   */
  answerCallBackQueryResponse(body: AnswerCallBackQueryRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/session/answercallbackquery`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Get Entity Descriptor
   * @param body ID of Organization to return
   */
  answerCallBackQuery(body: AnswerCallBackQueryRequest): __Observable<null> {
    return this.answerCallBackQueryResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get Sessionr
   * @return successful operation
   */
  getSessionResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/session`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Get Sessionr
   * @return successful operation
   */
  getSession(): __Observable<{}> {
    return this.getSessionResponse().pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Get Sessionr
   * @return successful operation
   */
  getCurrentUserEntityDataResponse(): __Observable<__StrictHttpResponse<EntityData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/session/get-current-user-entity-data`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityData>;
      })
    );
  }
  /**
   * Get Sessionr
   * @return successful operation
   */
  getCurrentUserEntityData(): __Observable<EntityData> {
    return this.getCurrentUserEntityDataResponse().pipe(
      __map(_r => _r.body as EntityData)
    );
  }

  /**
   * @return successful operation
   */
  soapHealthCheckResponse(): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/soap-health-check`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @return successful operation
   */
  soapHealthCheck(): __Observable<boolean> {
    return this.soapHealthCheckResponse().pipe(
      __map(_r => _r.body as boolean)
    );
  }
}

module SessionService {
}

export { SessionService }
