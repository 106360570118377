import {Injectable} from '@angular/core';
import {TranslationFile, TranslationScopes} from '../../pit/structure';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  translation: TranslationFile;

  constructor() {
    if (localStorage.getItem('currentLanguage')) {
      this.translation = JSON.parse(localStorage.getItem('currentLanguage'));
    }
  }

  getTranslation(scope: string, key: string): string {

    if (this.translation && this.translation[TranslationScopes.CONTENT_FROM_CONFIG_JSON] && this.translation[TranslationScopes.CONTENT_FROM_CONFIG_JSON][key]) {
      scope = TranslationScopes.CONTENT_FROM_CONFIG_JSON;
    }

    return this.translation && this.translation[scope] && this.translation[scope][key] ? this.translation[scope][key] : scope + '.' + key;
  }
}
