import {Component, ElementRef, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import Bootstrap from 'bootstrap/dist/js/bootstrap';
import {Router} from '@angular/router';
import {AuthService} from './shared/services/auth.service';
import {DOCUMENT} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {QueryButtonEnum, ReleaseNotes, Settings, UserMessagesTypeEnum} from './pit/structure';
import {CallbackService} from './shared/services/callback.service';
import {ConfigService} from './shared/services/config.service';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {EmitType} from '@syncfusion/ej2-base';
import {TranslationService} from './shared/services/translation.service';
import {map} from 'rxjs/operators';
import {UserService} from './shared/services/swagger/services/user.service';
import {RoutingProviderService} from './pit/services/routing-provider.service';
import {ResolverService} from './shared/services/resolver.service';
import {MessageStackService} from './shared/services/message-stack.service';
import {settings} from 'cluster';
import { ResponsivenessService } from './shared/services/responsiveness.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {


  title = 'angular-bootstrap-examples';

  releaseNotes: ReleaseNotes;

  modalDirect: Bootstrap.Modal;

  queryButtonEnum = QueryButtonEnum;

  @ViewChild('ejDialog') ejDialog!: DialogComponent;

  @ViewChild('ejDialogWrapper', {read: ElementRef, static: true})

  ejDialogWrapper!: ElementRef;

  addColumnBtn = false;

  public targetElement!: HTMLElement;

  userMessagesTypeEnum = UserMessagesTypeEnum;

  settings: Settings;

  public hideDialog: EmitType<object> = () => {
    this.ejDialog.hide();
  };

  public onOverlayClick: EmitType<object> = () => {
    this.ejDialog.hide();
  };

  messageStack: boolean;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.responsivenessService.onResize(window.innerWidth);
  }

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private router: Router,
    private authService: AuthService,
    public callBackService: CallbackService,
    public routingProviderService: RoutingProviderService,
    public messageStackService: MessageStackService,
    private configService: ConfigService,
    public translationService: TranslationService,
    private resolverService: ResolverService,
    private responsivenessService: ResponsivenessService
  ) {

    this.settings = this.configService.getSettings();

    if (localStorage.getItem('defaultLang')) {
      this.authService.setDefaultLang(JSON.parse(localStorage.getItem('defaultLang')));
    }


  }


  ngOnInit(): void {
  }


  onOpenDialog(dialog: any): void {
    this.addColumnBtn = true;

    if (dialog === 'release') {
      this.ejDialog.header = 'Release Notes';
      this.messageStack = false;
      this.releaseNotes = this.configService.getReleaseNotes();
    }
    this.ejDialog.show();
  };

  onBeforeOpen(args: any) {
    if (this.addColumnBtn) {
      this.addColumnBtn = false;
    } else {
      args.cancel = true;
    }
  }

  open(element): void {
    this.modalDirect = new Bootstrap.Modal(element, {});
  }


  navigateToLogin(): void {
    this.router.navigate(['login']).then(() => {
      this.document.getElementById('btn-session-modal-close').click();
      this.document.getElementById('no-session').remove();
    });
  }

  checkConnectivity(): void {
    this.routingProviderService.isSoapOffline = false;
    this.routingProviderService.checkConnectivity().subscribe();

    this.resolverService.checkSoap().pipe(map((res: boolean) => {

      this.routingProviderService.isSoapOffline = !res;


      return res;
    })).subscribe();
  }


}
