/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RefEntityDataRequest } from '../models/ref-entity-data-request';
import { EntityHandleRequest } from '../models/entity-handle-request';
import { EntityData } from '../models/entity-data';
import { CreateEntityDataRequest } from '../models/create-entity-data-request';
import { SetEntityDataRequest } from '../models/set-entity-data-request';
import { EntityDataFromHandleRequest } from '../models/entity-data-from-handle-request';
import { EntityDataRequest } from '../models/entity-data-request';
import { AttributeDescriptor } from '../models/attribute-descriptor';
import { AttributeClassField } from '../models/attribute-class-field';
import { EntityDescriptorResponse } from '../models/entity-descriptor-response';
import { EntityDescriptor } from '../models/entity-descriptor';
import { AssignEntityRequest } from '../models/assign-entity-request';
import { EntityCallFunctionRequest } from '../models/entity-call-function-request';
import { EntityCallFunctionContextResponse } from '../models/entity-call-function-context-response';
import { EntityCallFunctionByHandleResponse } from '../models/entity-call-function-by-handle-response';
import { HandleRequest } from '../models/handle-request';
import { FilterParameterDescriptor } from '../models/filter-parameter-descriptor';
import { DeleteEntityRequest } from '../models/delete-entity-request';
@Injectable({
  providedIn: 'root',
})
class EntityService extends __BaseService {
  static readonly getListDataPath = '/entity/getRefEntityData';
  static readonly synchronizeEntityPath = '/entity/synchronizeEntity/{entityHandleId}';
  static readonly synchronizeListPath = '/entity/synchronizeEntityList/{entityHandleId}';
  static readonly getEntityHandlePath = '/entity/entityHandle';
  static readonly createEntityDataPath = '/entity/createEntityData';
  static readonly setEntityDataPath = '/entity/setEntityData';
  static readonly getEntityDataFromHandlePath = '/entity/entityDataFromHandle';
  static readonly getEntityDataPath = '/entity/entityData';
  static readonly getAttributeDescriptorArrayPath = '/entity/attributeDescriptor';
  static readonly getEntityDescriptorPath = '/entity/descriptor/{className}';
  static readonly assignEntityPath = '/entity/assign';
  static readonly entityCallFunctionPath = '/entity/callfunction';
  static readonly entityCallFunctionHandlerPath = '/entity/getcallfunctionhandler';
  static readonly entityCallFunctionContextPath = '/entity/getCallFunctionContext';
  static readonly entityCallFunctionByHandlePath = '/entity/callfunctionbyhandler';
  static readonly deleteEntityPath = '/entity/delete';
  static readonly unassignEntityPath = '/entity/unassign';
  static readonly beginEditPath = '/entity/beginedit';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get Ref Entity Data
   * @param RefEntityDataRequest Ref Entity Rrequest
   */
  getListDataResponse(RefEntityDataRequest: RefEntityDataRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = RefEntityDataRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/entity/getRefEntityData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Get Ref Entity Data
   * @param RefEntityDataRequest Ref Entity Rrequest
   */
  getListData(RefEntityDataRequest: RefEntityDataRequest): __Observable<null> {
    return this.getListDataResponse(RefEntityDataRequest).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Sync Entity Handle
   * @param entityHandleId ID of Organization to return
   */
  synchronizeEntityResponse(entityHandleId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/entity/synchronizeEntity/${encodeURIComponent(String(entityHandleId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Sync Entity Handle
   * @param entityHandleId ID of Organization to return
   */
  synchronizeEntity(entityHandleId: string): __Observable<null> {
    return this.synchronizeEntityResponse(entityHandleId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Sync Entity List Handle
   * @param entityHandleId ID of Organization to return
   */
  synchronizeListResponse(entityHandleId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/entity/synchronizeEntityList/${encodeURIComponent(String(entityHandleId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Sync Entity List Handle
   * @param entityHandleId ID of Organization to return
   */
  synchronizeList(entityHandleId: string): __Observable<null> {
    return this.synchronizeListResponse(entityHandleId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get Entity Handle
   * @param entityHandleRequest Entity Rrequest
   */
  getEntityHandleResponse(entityHandleRequest: EntityHandleRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entityHandleRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/entity/entityHandle`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Get Entity Handle
   * @param entityHandleRequest Entity Rrequest
   */
  getEntityHandle(entityHandleRequest: EntityHandleRequest): __Observable<null> {
    return this.getEntityHandleResponse(entityHandleRequest).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Create Entity Data
   * @param createEntityDataRequest Entity Rrequest
   * @return successful operation
   */
  createEntityDataResponse(createEntityDataRequest: CreateEntityDataRequest): __Observable<__StrictHttpResponse<EntityData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = createEntityDataRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/entity/createEntityData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityData>;
      })
    );
  }
  /**
   * Create Entity Data
   * @param createEntityDataRequest Entity Rrequest
   * @return successful operation
   */
  createEntityData(createEntityDataRequest: CreateEntityDataRequest): __Observable<EntityData> {
    return this.createEntityDataResponse(createEntityDataRequest).pipe(
      __map(_r => _r.body as EntityData)
    );
  }

  /**
   * Set Entity Data
   * @param setEntityDataRequest Entity Rrequest
   * @return successful operation
   */
  setEntityDataResponse(setEntityDataRequest: SetEntityDataRequest): __Observable<__StrictHttpResponse<EntityData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = setEntityDataRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/entity/setEntityData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityData>;
      })
    );
  }
  /**
   * Set Entity Data
   * @param setEntityDataRequest Entity Rrequest
   * @return successful operation
   */
  setEntityData(setEntityDataRequest: SetEntityDataRequest): __Observable<EntityData> {
    return this.setEntityDataResponse(setEntityDataRequest).pipe(
      __map(_r => _r.body as EntityData)
    );
  }

  /**
   * Get Entity Data From Handle
   * @param entityDataRequest Entity Rrequest
   * @return successful operation
   */
  getEntityDataFromHandleResponse(entityDataRequest: EntityDataFromHandleRequest): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entityDataRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/entity/entityDataFromHandle`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Get Entity Data From Handle
   * @param entityDataRequest Entity Rrequest
   * @return successful operation
   */
  getEntityDataFromHandle(entityDataRequest: EntityDataFromHandleRequest): __Observable<any> {
    return this.getEntityDataFromHandleResponse(entityDataRequest).pipe(
      __map(_r => _r.body as any)
    );
  }

  /**
   * Get Entity Data
   * @param entityDataRequest Entity Rrequest
   * @return successful operation
   */
  getEntityDataResponse(entityDataRequest: EntityDataRequest): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entityDataRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/entity/entityData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Get Entity Data
   * @param entityDataRequest Entity Rrequest
   * @return successful operation
   */
  getEntityData(entityDataRequest: EntityDataRequest): __Observable<any> {
    return this.getEntityDataResponse(entityDataRequest).pipe(
      __map(_r => _r.body as any)
    );
  }

  /**
   * Get Attribute Descriptor
   * @param attributeClassField Address object that needs to be added
   * @return successful operation
   */
  getAttributeDescriptorArrayResponse(attributeClassField: Array<AttributeClassField>): __Observable<__StrictHttpResponse<AttributeDescriptor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = attributeClassField;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/entity/attributeDescriptor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AttributeDescriptor>;
      })
    );
  }
  /**
   * Get Attribute Descriptor
   * @param attributeClassField Address object that needs to be added
   * @return successful operation
   */
  getAttributeDescriptorArray(attributeClassField: Array<AttributeClassField>): __Observable<AttributeDescriptor> {
    return this.getAttributeDescriptorArrayResponse(attributeClassField).pipe(
      __map(_r => _r.body as AttributeDescriptor)
    );
  }

  /**
   * Get Entity Descriptor
   * @param className ID of Organization to return
   * @return successful operation
   */
  getEntityDescriptorResponse(className: string): __Observable<__StrictHttpResponse<EntityDescriptorResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/entity/descriptor/${encodeURIComponent(String(className))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityDescriptorResponse>;
      })
    );
  }
  /**
   * Get Entity Descriptor
   * @param className ID of Organization to return
   * @return successful operation
   */
  getEntityDescriptor(className: string): __Observable<EntityDescriptorResponse> {
    return this.getEntityDescriptorResponse(className).pipe(
      __map(_r => _r.body as EntityDescriptorResponse)
    );
  }

  /**
   * Assign Entity
   * @param body ID of Organization to return
   * @return successful operation
   */
  assignEntityResponse(body: AssignEntityRequest): __Observable<__StrictHttpResponse<Array<EntityDescriptor>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/entity/assign`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EntityDescriptor>>;
      })
    );
  }
  /**
   * Assign Entity
   * @param body ID of Organization to return
   * @return successful operation
   */
  assignEntity(body: AssignEntityRequest): __Observable<Array<EntityDescriptor>> {
    return this.assignEntityResponse(body).pipe(
      __map(_r => _r.body as Array<EntityDescriptor>)
    );
  }

  /**
   * entityCallFunction
   * @param body ID of Organization to return
   * @return successful operation
   */
  entityCallFunctionResponse(body: EntityCallFunctionRequest): __Observable<__StrictHttpResponse<Array<EntityDescriptor>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/entity/callfunction`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EntityDescriptor>>;
      })
    );
  }
  /**
   * entityCallFunction
   * @param body ID of Organization to return
   * @return successful operation
   */
  entityCallFunction(body: EntityCallFunctionRequest): __Observable<Array<EntityDescriptor>> {
    return this.entityCallFunctionResponse(body).pipe(
      __map(_r => _r.body as Array<EntityDescriptor>)
    );
  }

  /**
   * entityCallFunctionHandler
   * @param body ID of Organization to return
   * @return successful operation
   */
  entityCallFunctionHandlerResponse(body: EntityCallFunctionRequest): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/entity/getcallfunctionhandler`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * entityCallFunctionHandler
   * @param body ID of Organization to return
   * @return successful operation
   */
  entityCallFunctionHandler(body: EntityCallFunctionRequest): __Observable<number> {
    return this.entityCallFunctionHandlerResponse(body).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * entityCallFunctionContext
   * @param body ID of Organization to return
   * @return successful operation
   */
  entityCallFunctionContextResponse(body: EntityCallFunctionRequest): __Observable<__StrictHttpResponse<EntityCallFunctionContextResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/entity/getCallFunctionContext`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityCallFunctionContextResponse>;
      })
    );
  }
  /**
   * entityCallFunctionContext
   * @param body ID of Organization to return
   * @return successful operation
   */
  entityCallFunctionContext(body: EntityCallFunctionRequest): __Observable<EntityCallFunctionContextResponse> {
    return this.entityCallFunctionContextResponse(body).pipe(
      __map(_r => _r.body as EntityCallFunctionContextResponse)
    );
  }

  /**
   * Assign Entity
   * @param body ID of Organization to return
   * @return successful operation
   */
  entityCallFunctionByHandleResponse(body: HandleRequest): __Observable<__StrictHttpResponse<EntityCallFunctionByHandleResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/entity/callfunctionbyhandler`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityCallFunctionByHandleResponse>;
      })
    );
  }
  /**
   * Assign Entity
   * @param body ID of Organization to return
   * @return successful operation
   */
  entityCallFunctionByHandle(body: HandleRequest): __Observable<EntityCallFunctionByHandleResponse> {
    return this.entityCallFunctionByHandleResponse(body).pipe(
      __map(_r => _r.body as EntityCallFunctionByHandleResponse)
    );
  }

  /**
   * get filter parameter descriptors
   *
   * get filter parameter descriptors
   * @param body undefined
   * @return successful operation
   */
  deleteEntityResponse(body: DeleteEntityRequest): __Observable<__StrictHttpResponse<Array<FilterParameterDescriptor>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/entity/delete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<FilterParameterDescriptor>>;
      })
    );
  }
  /**
   * get filter parameter descriptors
   *
   * get filter parameter descriptors
   * @param body undefined
   * @return successful operation
   */
  deleteEntity(body: DeleteEntityRequest): __Observable<Array<FilterParameterDescriptor>> {
    return this.deleteEntityResponse(body).pipe(
      __map(_r => _r.body as Array<FilterParameterDescriptor>)
    );
  }

  /**
   * get filter parameter descriptors
   *
   * get filter parameter descriptors
   * @param body undefined
   * @return successful operation
   */
  unassignEntityResponse(body: DeleteEntityRequest): __Observable<__StrictHttpResponse<Array<FilterParameterDescriptor>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/entity/unassign`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<FilterParameterDescriptor>>;
      })
    );
  }
  /**
   * get filter parameter descriptors
   *
   * get filter parameter descriptors
   * @param body undefined
   * @return successful operation
   */
  unassignEntity(body: DeleteEntityRequest): __Observable<Array<FilterParameterDescriptor>> {
    return this.unassignEntityResponse(body).pipe(
      __map(_r => _r.body as Array<FilterParameterDescriptor>)
    );
  }

  /**
   * @param body undefined
   * @return successful operation
   */
  beginEditResponse(body: HandleRequest): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/entity/beginedit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param body undefined
   * @return successful operation
   */
  beginEdit(body: HandleRequest): __Observable<boolean> {
    return this.beginEditResponse(body).pipe(
      __map(_r => _r.body as boolean)
    );
  }
}

module EntityService {
}

export { EntityService }
