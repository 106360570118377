import { Component, OnInit } from '@angular/core';
import {CallbackService} from '../../../services/callback.service';
import {QueryButtonEnum} from '../../../../pit/structure';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.css']
})
export class MessageBoxComponent implements OnInit {

  queryButtonEnum = QueryButtonEnum;

  constructor(public callBackService: CallbackService,
              public dialogRef: MatDialogRef<MessageBoxComponent>) { }

  ngOnInit(): void {

  }

  closeDialog() {

    this.dialogRef.close();
  }
}
