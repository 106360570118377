import {Injectable} from '@angular/core';
import {ConfigEntry, Configuration, FileMapping, ReleaseNotes, Settings, SupportedFilesConfig} from '../../pit/structure';
import {from, Observable} from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  configuration: Configuration;

  releaseNotes: ReleaseNotes;

  fileMapping: FileMapping;


  fileMap: ConfigEntry[] = [
    {
      name: 'FileMapping',
      path: 'assets/config/file-map.json',
      config: null,
      observer: this.getFileObserve('assets/config/file-map.json'),

    },
  ];


  configFiles: ConfigEntry[] = [
    {name: 'settings', path: 'assets/config/settings.json', config: null, observer: this.getFileObserve('assets/config/settings.json')},
    {
      name: 'releaseNotes',
      path: 'assets/config/release_notes.json',
      config: null,
      observer: this.getFileObserve('assets/config/release_notes.json'),

    },
    {name: 'config', path: 'assets/config/config.json', config: null, observer: this.getFileObserve('assets/config/config.json')},


  ];

  constructor(private httpClient: HttpClient,
              private router: Router) {
  }


  setOriginalConfig(config: Configuration): void {
    localStorage.setItem('original_config', JSON.stringify(config));
  }

  getOriginalConfig(): Configuration {
    return JSON.parse(localStorage.getItem('original_config'));
  }

  getConfiguration(): Configuration {
    this.configuration = JSON.parse(localStorage.getItem('config'));
    return this.configuration;
  }

  setConfiguration(config: Configuration): void {
    localStorage.setItem('config', JSON.stringify(config));
    ;
  }

  getReleaseNotes(): ReleaseNotes {
    this.releaseNotes = JSON.parse(localStorage.getItem('releaseNotes'));
    return this.releaseNotes;
  }

  getSettings(): Settings {
    const settings: any = localStorage.getItem('settings');
    if (settings === null) {
      return null;
    }
    return JSON.parse(settings);
  }

  getConnectionSettings(): Settings {
    const settings: any = localStorage.getItem('connection-settings');
    if (settings === null) {
      return null;
    }
    return JSON.parse(settings);
  }

  getData = (param) => {
    return param;
  };

  loadConfigFiles(configFiles: ConfigEntry[]): Observable<any> {

    return from(configFiles).pipe(
      concatMap((res: any) => {
        return res.observer;
      }),
      catchError(error => {
        console.log(error);
        return error;
      }),
      map((result, index) => ({config: result, index})),
    );
  }

  getFileObserve(path: string): Observable<any> {
    const time: Date = new Date();
    return this.httpClient.get<string>(path + '?time=' + time.getTime());
  }

  setFileMapping(fileMapping: FileMapping): void {
    fileMapping.imageFolder = 'assets/' + fileMapping.imageFolder;
    localStorage.setItem('fileMapping', JSON.stringify(fileMapping));
    this.fileMapping = fileMapping;
  }

  getFileMapping(): FileMapping {
    return JSON.parse(localStorage.getItem('fileMapping'));
  }

  getSupportedFiles(): SupportedFilesConfig {
    return JSON.parse(localStorage.getItem('supportedFiles'));
  }
}

