import {Injectable} from '@angular/core';
import {EntityDescriptorResponse} from '../../shared/services/swagger/models/entity-descriptor-response';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  attributeDescriptors: EntityDescriptorResponse[] = [];

  constructor() { }

  addAttributeDescriptorToCache(attributeDescriptor: EntityDescriptorResponse): void {
    this.attributeDescriptors.push(attributeDescriptor);
  }

  getAttributeDescriptorFromCache(className: string): EntityDescriptorResponse {
    return this.attributeDescriptors.find(r => r.Classname === className);
  }
}
