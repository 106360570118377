import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LoaderService {

  isLoading = new Subject<boolean>();

  isNavigating: Subject<boolean> = new Subject<boolean>();

  show() {
    if(document.body.classList.contains('login'))
    {
      document.getElementById("statusBackdrop").classList.add("show");
    }
    this.isLoading.next(true);
  }

  hide() {
    document.getElementById("statusBackdrop").classList.remove("show");
    this.isLoading.next(false);
  }
}
