import {Injectable} from '@angular/core';
import {EMPTY, Observable, of} from 'rxjs';
import {EntityHandleRequest} from './swagger/models/entity-handle-request';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {EntityService} from './swagger/services/entity.service';
import {SetEntityDataRequest} from './swagger/models/set-entity-data-request';
import {NamedValue} from './swagger/models/named-value';


@Injectable({
  providedIn: 'root'
})
export class EntitySmartService {

  constructor(private entityService: EntityService) {
  }

  getEntityHandle(classname: string, entityId: string): Observable<any> {
    const entityHandleRequest: EntityHandleRequest = {Classname: classname, EntityId: entityId};
    return this.entityService.getEntityHandle(entityHandleRequest).pipe(
      catchError(error => {
        return EMPTY;
      }), map((entityHandData) => {
          return entityHandData ? entityHandData : EMPTY;
        },
      ));
  }

  setAttributeHandover(namedValue: NamedValue, entityHandle: number): Observable<any> {
    const setEntityDataRequest: SetEntityDataRequest = {AttributeValues: [namedValue], EntityHandleId: entityHandle};
    return this.entityService.setEntityData(setEntityDataRequest).pipe(mergeMap((res: any) => {
      return of(true);
    }), catchError((error) => {
      console.log(error);
      return error;
    }));
  }


}
