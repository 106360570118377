/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EntityListRequest } from '../models/entity-list-request';
import { AggregationListDataResponse } from '../models/aggregation-list-data-response';
import { AggregationListDataRequest } from '../models/aggregation-list-data-request';
import { GetDataByAttributeRequest } from '../models/get-data-by-attribute-request';
import { FilterParameterDescriptor } from '../models/filter-parameter-descriptor';
import { GetFilterParameterDescriptorsRequest } from '../models/get-filter-parameter-descriptors-request';
@Injectable({
  providedIn: 'root',
})
class EntitylistService extends __BaseService {
  static readonly getEntityListDataPath = '/entitylist/getdata';
  static readonly getHandleAndEntityListDataPath = '/entitylist/gethandleanddata';
  static readonly getAggregationListDataPath = '/entitylist/aggregation';
  static readonly getDataByAttributePath = '/entitylist/getdatabyattribute';
  static readonly getFilterParameterDescriptorsPath = '/entitylist/getFilterParameterDescriptors';
  static readonly exportEntityListPath = '/entitylist/export';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get Dashboard Entity Data
   * @param entityListRequest Dashboard Entity Rrequest
   */
  getEntityListDataResponse(entityListRequest: EntityListRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entityListRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/entitylist/getdata`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Get Dashboard Entity Data
   * @param entityListRequest Dashboard Entity Rrequest
   */
  getEntityListData(entityListRequest: EntityListRequest): __Observable<null> {
    return this.getEntityListDataResponse(entityListRequest).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get Dashboard Entity Data
   * @param entityListRequest Dashboard Entity Rrequest
   */
  getHandleAndEntityListDataResponse(entityListRequest: EntityListRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entityListRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/entitylist/gethandleanddata`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Get Dashboard Entity Data
   * @param entityListRequest Dashboard Entity Rrequest
   */
  getHandleAndEntityListData(entityListRequest: EntityListRequest): __Observable<null> {
    return this.getHandleAndEntityListDataResponse(entityListRequest).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param entityListRequest undefined
   * @return successful operation
   */
  getAggregationListDataResponse(entityListRequest: AggregationListDataRequest): __Observable<__StrictHttpResponse<AggregationListDataResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entityListRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/entitylist/aggregation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AggregationListDataResponse>;
      })
    );
  }
  /**
   * @param entityListRequest undefined
   * @return successful operation
   */
  getAggregationListData(entityListRequest: AggregationListDataRequest): __Observable<AggregationListDataResponse> {
    return this.getAggregationListDataResponse(entityListRequest).pipe(
      __map(_r => _r.body as AggregationListDataResponse)
    );
  }

  /**
   * Get List Data By Attribute
   * @param entityListRequest Dashboard Entity Rrequest
   */
  getDataByAttributeResponse(entityListRequest: GetDataByAttributeRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entityListRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/entitylist/getdatabyattribute`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Get List Data By Attribute
   * @param entityListRequest Dashboard Entity Rrequest
   */
  getDataByAttribute(entityListRequest: GetDataByAttributeRequest): __Observable<null> {
    return this.getDataByAttributeResponse(entityListRequest).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * get filter parameter descriptors
   *
   * get filter parameter descriptors
   * @param body undefined
   * @return successful operation
   */
  getFilterParameterDescriptorsResponse(body: GetFilterParameterDescriptorsRequest): __Observable<__StrictHttpResponse<Array<FilterParameterDescriptor>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/entitylist/getFilterParameterDescriptors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<FilterParameterDescriptor>>;
      })
    );
  }
  /**
   * get filter parameter descriptors
   *
   * get filter parameter descriptors
   * @param body undefined
   * @return successful operation
   */
  getFilterParameterDescriptors(body: GetFilterParameterDescriptorsRequest): __Observable<Array<FilterParameterDescriptor>> {
    return this.getFilterParameterDescriptorsResponse(body).pipe(
      __map(_r => _r.body as Array<FilterParameterDescriptor>)
    );
  }

  /**
   * list all writable clients
   *
   * list all writable clients
   * @param body undefined
   * @return successful operation
   */
  exportEntityListResponse(body: EntityListRequest): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/entitylist/export`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<any>;
      })
    );
  }
  /**
   * list all writable clients
   *
   * list all writable clients
   * @param body undefined
   * @return successful operation
   */
  exportEntityList(body: EntityListRequest): __Observable<any> {
    return this.exportEntityListResponse(body).pipe(
      __map(_r => _r.body as any)
    );
  }
}

module EntitylistService {
}

export { EntitylistService }
