import {Injectable} from '@angular/core';
import {
  Children,
  Configuration,
  Handover,
  MatrixData,
  Menu,
  ReferenceInfos,
  UrlArrayQueryParams,
  View,
  Workflow,
  WorkflowView
} from '../structure';
import {Params, Router} from '@angular/router';
import {DashboardFilter} from '../../shared/models/DashboardFilter';
import {InputDashboardData, InputData, InputFormData, TimelineFilter} from '../input-data';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {UserService} from '../../shared/services/swagger/services/user.service';
import {FormDataService} from '../../shared/services/form-data.service';
import {ConfigService} from '../../shared/services/config.service';
import {MatrixDataResolverService} from '../resolver/matrix-data-resolver.service';
import {FilterParameter} from '../../shared/services/swagger/models/filter-parameter';
import {Client} from '../../shared/services/swagger/models/client';
import {NavigationElement, NavigationService} from '../../shared/services/navigation.service';
import {AuthService} from '../../shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoutingProviderService {

  initQueryParams: Params;

  activeEntityId: string;

  formDirty: boolean;

  lastListUrlParams: string[] = [];

  lastUrl: string;

  lastListQueryParams: Params[] = [];

  activeEndpointName: string;

  activeWorkflowMenuName: string;

  activeReferenceInfos: ReferenceInfos;

  activeReferenceHandle: number;

  isSessionTimeout: boolean;

  inputDashboardData: InputDashboardData[] = [];

  attributeHandOvers: Handover[];

  lastEntityId: string;

  isOffline = false;

  isSoapOffline = false;

  dashboardFilter: DashboardFilter;

  timelineFilter: TimelineFilter;

  matrixData: MatrixData;

  filterParameters: FilterParameter[] = [];

  isDisabledLoaderDarkening: boolean;

  listHistory: string[] = [];

  initialRoute: NavigationElement;

  static getEndpointFromInputFOrmData(inputFormData: InputFormData, inputData: InputData, configuration: Configuration): NavigationElement {
    const endpoint: string[] = [];
    endpoint.push(inputFormData.workflow.name);
    if (inputFormData.tabView) {
      endpoint.push(inputFormData.tabData.name);
    } else {
      endpoint.push(inputFormData.view.name);
    }


    return NavigationService.getRouteFromEndpoint(
      endpoint.join(':'), configuration, inputData.entityId,
      inputFormData.tabData ? inputFormData.view : null, null, null, inputFormData.workflow);

  }

  constructor(private userService: UserService,
              private router: Router,
              private configService: ConfigService) {
    this.listHistory = JSON.parse(localStorage.getItem('list_history'));
    this.initialRoute = JSON.parse(localStorage.getItem('initial_route'));
  }

  setActiveSidebarMenuName(endpoint: string, configuration: Configuration): void {
    // this.activeEndpointName = null;
    configuration.menus.main.forEach((menu: Menu) => {
      if (menu.children) {
        const activeMenu: Children = menu.children.find(r => r.action.endpoint === endpoint);
        if (activeMenu) {
          this.activeWorkflowMenuName = menu.name;
          this.activeEndpointName = activeMenu.action.endpoint;
        }
      } else {

        if (menu.action.endpoint === endpoint) {
          this.activeWorkflowMenuName = menu.name;
          this.activeEndpointName = null;
        }
        if (menu.name.toLowerCase() === endpoint) {
          this.activeWorkflowMenuName = endpoint;
          this.activeEndpointName = null;
        }
      }
    });
  }

  getWorkFlowAndViewFromEndpoint(endpoint: string): WorkflowView {
    let view: View;
    let tabWorkflowView: WorkflowView = null;
    const endpointNameArr: string[] = endpoint.split(':');
    const workflow: Workflow = this.configService.getConfiguration().workflows.find(r => r.name === endpointNameArr[0]);

    if (workflow) {
      let tabData: View = null;
      view = workflow.views[endpointNameArr[1]] ? workflow.views[endpointNameArr[1]] : null;

      if (endpointNameArr.length === 3) {
        const tabView: View = workflow.views[endpointNameArr[2]] ? workflow.views[endpointNameArr[2]] : null;
        view.name = endpointNameArr[1];

        tabData = workflow.views[endpointNameArr[1]];
        tabWorkflowView = this.getWorkFlowAndViewFromEndpoint(workflow.name + ':' + tabView.name);
      } else {
        if (view) {
          if (view.children && view.children.length > 0) {
            view.name = endpointNameArr[1];
            const firstTabChild: Children = view.children[0];
            tabWorkflowView = this.getWorkFlowAndViewFromEndpoint(firstTabChild.path);
          }
        }
      }
    }

    return {workflow, view, tabWorkflowView};
  }

  deleteLastEntry(): void {
    this.lastListUrlParams.splice(this.lastListUrlParams.length - 1, 1);
    this.lastListQueryParams.splice(this.lastListQueryParams.length - 1, 1);
  }

  addLastListUrlParams(lastListUrlParams: string): void {
    this.lastListUrlParams.push(lastListUrlParams);
  }

  addInputDashboardData(inputDashboardData: InputDashboardData): void {
    this.inputDashboardData.push(inputDashboardData);
  }

  removeLastInputDashboardData(): void {
    this.inputDashboardData.splice(this.inputDashboardData.length - 1, 1);
  }

  setLastInputDashboardData(inputDashboardData: InputDashboardData): InputDashboardData {
    return this.inputDashboardData[this.inputDashboardData.length - 1];
  }

  getLastInputDashboardData(): InputDashboardData {
    return this.inputDashboardData[this.inputDashboardData.length - 1];
  }

  setAttributeHandover(attributeHandovers: Handover[]): void {
    this.attributeHandOvers = attributeHandovers;
  }

  checkConnectivity(): Observable<boolean> {
    this.isOffline = false;
    return this.userService.checkConnectivity().pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      this.isOffline = true;
      window.alert('backend is not reachable!');
      return error;
    }));
  }


  navigateBack(): void {
    const configuration: Configuration = this.configService.getConfiguration();

    if (this.listHistory.length > 0) {
      this.router.navigateByUrl(this.listHistory[this.listHistory.length - 1]).then(() => {
      });
    }
    // else {
    //   // this.authService.navigateToFirstAction(AuthService.getUserRole());
    //
    // }
  }

  getLastListHistoryUrlAndParams(): NavigationElement {
    const navigationElement: NavigationElement = {urlArr: [], dashboardFilter: {}};
    if (this.listHistory && this.listHistory.length > 0) {
      const urlArrayQueryParams: UrlArrayQueryParams = (NavigationService.parseUrl(this.listHistory[this.listHistory.length - 1]));
      navigationElement.urlArr = urlArrayQueryParams.urlArray;
      navigationElement.dashboardFilter = urlArrayQueryParams.queryParams;


    }

    return navigationElement;
  }

  getLastUrl(): string {
    if (!this.lastUrl) {
      return null;
    } else {
      return this.lastUrl;
    }
  }

  setInitQueryParams(params: Params): void {
    this.initQueryParams = params;
  }

  getInitQueryParams(): Params {
    return this.initQueryParams;
  }

  setTimelineFilter(timelineFilter: TimelineFilter): void {
    this.timelineFilter = timelineFilter;
  }

  clearTimelineFilter(): void {
    this.timelineFilter = null;
  }

  getTimelineFilter(): TimelineFilter {
    return this.timelineFilter;
  }

  setMatrixData(matrixData: MatrixData): void {
    this.matrixData = matrixData;
  }

  getMatrixData(): MatrixData {
    return this.matrixData;
  }

  setFilterParameters(filterParameters: FilterParameter[]): void {
    this.filterParameters = filterParameters;
  }

  getFilterParameters(): FilterParameter[] {
    return this.filterParameters;
  }

  disableLoaderDarkening(): void {
    this.isDisabledLoaderDarkening = true;
  }

  enableLoaderDarkening(): void {
    this.isDisabledLoaderDarkening = false;
  }

  clearAttributeHandovers(): void {
    this.attributeHandOvers = [];
  }

  getLastListHistory(): NavigationElement {

    return this.listHistory && this.listHistory.length > 0 ? {
      urlArr: this.listHistory[this.listHistory.length - 1].split('/'),
      dashboardFilter: null
    } : this.initialRoute;
  }

  addListHistory(url: string): void {
    this.listHistory.push(url);
    localStorage.setItem('list_history', JSON.stringify(this.listHistory));
  }

  setInitialRoute(navigationElement: NavigationElement) {
    this.initialRoute = navigationElement;
    localStorage.setItem('initial_route', JSON.stringify(this.initialRoute));

  }
}
