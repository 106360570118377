import {Injectable} from '@angular/core';
import {EntityData} from './swagger/models/entity-data';
import {
  CatalogColumns,
  Children,
  Column,
  FilterFile,
  ListControlEnum,
  ListSettings,
  Permissions,
  Section,
  SortingEnum,
  View,
  ViewEnum,
  Workflow
} from '../../pit/structure';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {EMPTY, from, Observable, of} from 'rxjs';
import {DashboardDataService} from './dashboard-data.service';
import {ConfigService} from './config.service';
import {NavigationService} from './navigation.service';
import {EntityService} from './swagger/services/entity.service';
import {EntityDataRequest} from './swagger/models/entity-data-request';
import {NamedValue} from './swagger/models/named-value';
import {FormGroup} from '@angular/forms';
import {EntityDescriptor} from './swagger/models/entity-descriptor';
import {SetEntityDataRequest} from './swagger/models/set-entity-data-request';
import {FormDataService} from './form-data.service';
import {DashboardFilter} from '../models/DashboardFilter';
import {CreateEntityDataRequest} from './swagger/models/create-entity-data-request';
import {EntityDataFromHandleRequest} from './swagger/models/entity-data-from-handle-request';
import {EntitylistService} from './swagger/services/entitylist.service';
import {EntityListRequest} from './swagger/models/entity-list-request';
import {InputDashboardData, InputData, InputFormData, InputNavigationData} from '../../pit/input-data';
import {PermissionService} from './permission.service';
import {TranslationService} from './translation.service';
import {BaseFilters, EntityCallFunctionByHandleResponse, EntityCallFunctionRequest, HandleRequest} from './swagger/models';
import {FileService} from './swagger/services/file.service';
import {CallbackService} from './callback.service';
import {RoutingProviderService} from '../../pit/services/routing-provider.service';
import {ListService} from './list.service';
import {Params} from '@angular/router';
import {ResponsivenessService} from './responsiveness.service';
import {wrapInParensIfNecessary} from '@angular/localize/src/tools/src/source_file_utils';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  entityData: EntityData[];


  constructor(
    private dashboardDataService: DashboardDataService,
    public configService: ConfigService,
    public formDataService: FormDataService,
    public navigationService: NavigationService,
    public entityListService: EntitylistService,
    public entityService: EntityService,
    private permissionService: PermissionService,
    public fileService: FileService,
    public translationService: TranslationService,
    public callbackService: CallbackService,
    private listService: ListService,
    private responsivenessService: ResponsivenessService,
    private routingProviderService: RoutingProviderService
  ) {
  }


  getListData(inputDashboardData: InputDashboardData, inputFormData: InputFormData, inputData: InputData): Observable<any> {

    if (inputFormData.tabData && inputFormData.tabData.children.length > 0) {
      const pathName: string = inputFormData.workflow.name + ':' + inputFormData.view.name;
      const tabChild: Children = inputFormData.tabData.children.find(r => r.path === pathName);

      if (tabChild && tabChild.attribute && inputData.entityHandle) {
        return this.listService.getListDataByAttribute(tabChild.attribute, inputData.entityHandle,
          inputFormData.view, inputDashboardData.dashboardFilter, tabChild.filterFile);
      } else {

        // Filter Lists without context
        // const attributes: string[] = this.formDataService.getAttributeNames(inputFormData.view);
        const getEntityListRequest: EntityListRequest = {
          AttributeNames: this.getViewColumns(inputFormData.view, inputDashboardData.dashboardFilter),
          Handle: inputDashboardData.entityHandle,
          BaseFilter: inputDashboardData.dashboardFilter.baseFilter ? JSON.parse(inputDashboardData.dashboardFilter.baseFilter) : null,
          ActualEntityHandle: inputDashboardData.dashboardFilter.filterFile ? inputData.entityHandle : null,
          Limit: inputDashboardData.dashboardFilter.limit,
          Start: inputDashboardData.dashboardFilter.start,
          filterFile: inputDashboardData.dashboardFilter.filterFile ? inputDashboardData.dashboardFilter.filterFile : null
        };
        this.dashboardDataService.setDashboardSorting(getEntityListRequest, inputDashboardData.dashboardFilter);
        return this.entityListService.getEntityListData(getEntityListRequest);

      }
    } else {

      // Main Lists
      const attributes: string[] = this.formDataService.getAttributeNames(inputFormData.view);
      const getEntityListRequest: EntityListRequest = {
        AttributeNames: attributes,
        Handle: inputDashboardData.entityHandle,
        BaseFilter: inputDashboardData.dashboardFilter.baseFilter ? JSON.parse(inputDashboardData.dashboardFilter.baseFilter) : null,
        ActualEntityHandle: inputDashboardData.dashboardFilter.filterFile ? inputData.entityHandle : null,
        Limit: inputDashboardData.dashboardFilter.limit,
        Start: inputDashboardData.dashboardFilter.start,
        filterFile: inputDashboardData.dashboardFilter.filterFile ? inputDashboardData.dashboardFilter.filterFile : null
      };
      this.dashboardDataService.setDashboardSorting(getEntityListRequest, inputDashboardData.dashboardFilter);
      return this.entityListService.getEntityListData(getEntityListRequest);


    }
  }

  getViewColumns(view: View,
                 dashboardFilter: DashboardFilter): string[] {

    const result: string[] = [];

    let columnAttributes: Column[] = [];
    let filterView: FilterFile;
    let filterFileName: string;
    let className: string = view.binding;


    if (view.filters) {
      if (dashboardFilter.filterFile) {
        filterFileName = dashboardFilter.filterFile;

      } else {

        filterFileName = view.defaultFilterFileName;
      }

      filterView = view.filters.find(r => r.file === filterFileName);
      dashboardFilter.filterFile = filterView.file;
    }

    if (filterView && filterView.columns && filterView.columns.length > 0) {
      columnAttributes = filterView.columns;
      className = filterView.binding;
    } else {
      const columns: Column[] = this.formDataService.addColumnsForControlsWithAttributes(view);
      view.columns = [...view.columns, ...columns];
      columnAttributes = view.columns;
    }

    columnAttributes.forEach(column => {
      result.push(column.attribute);

      if (column.useDisplayColor && column.colorAttribute) {
        result.push(column.colorAttribute);
      }
    });
    if (!columnAttributes.find(r => r.attribute === '$Displayname') && this.responsivenessService.isTabletOrMobileView()) {
      result.push('$Displayname');
    }

    return result;
  }

  getDashboardDate(dashboardFilter: DashboardFilter,
                   view: View,
                   inputData: InputData = null,
                   getSearchableAttributes: boolean = null,
                   sync: boolean = false,
                   queryParams: Params = null): Observable<any> {

    const columns: Column[] = view.columns;
    let className: string = view.binding;
    const entityListRequest: EntityListRequest = this.dashboardDataService.getDashboardRequest();

    let syncObs: Observable<any>;


    entityListRequest.GetSearchableAttributes = getSearchableAttributes;
    const attributes: string[] = [];
    entityListRequest.AttributeNames = this.getViewColumns(view, dashboardFilter);

    if (!dashboardFilter.baseFilter) {
      // if (entityListRequest.BaseFilter.length === 0) {
      //   entityListRequest.BaseFilter = [{ClassName: className, FilterConditions: null}];
      // }
      entityListRequest.BaseFilter = [{ClassName: className, FilterConditions: null}];
      entityListRequest.BaseFilter[0].ClassName = className;
    } else {
      entityListRequest.BaseFilter = JSON.parse(dashboardFilter.baseFilter);

      let isAllValuesNull = true;

      entityListRequest.BaseFilter.forEach((baseFilterX: BaseFilters) => {
        if (baseFilterX.FilterConditions && baseFilterX.FilterConditions.length > 0 && baseFilterX.FilterConditions[0].Value !== '') {
          isAllValuesNull = false;
        }
      });
    }


    if (view.listControl && view.listControl === ListControlEnum.FROZEN_GRID) {
      entityListRequest.Limit = 0;
    } else {
      entityListRequest.Limit = dashboardFilter.limit;
    }

    entityListRequest.Start = (dashboardFilter.page - 1) * entityListRequest.Limit;


    // FilterFile in DashbaordFilter in Url

    if (dashboardFilter.filterFile) {
      entityListRequest.filterFile = dashboardFilter.filterFile;
      if (inputData) {
        entityListRequest.ActualEntityHandle = inputData.entityHandle;
      }
    } else {
      entityListRequest.filterFile = null;
    }

    // Filterfile in View

    if (view.filters && dashboardFilter.filterFile) {
      // When filterlist
      if (view.columns.length === 0) {
        entityListRequest.ActualEntityHandle = inputData.entityHandle;
      }
    }

    if (view.filter) {
      entityListRequest.filterFile = view.filter.file;
    }

    entityListRequest.Sortings = [];

    if (queryParams) {

      const inputFormData: InputFormData = {queryParams: queryParams};
      entityListRequest.FilterParameters = this.dashboardDataService.getFilterParameters(inputFormData);
    }

    if (dashboardFilter.sortField) {
      entityListRequest.Sortings.push({
        SortingDirection: dashboardFilter.sortDirection ? dashboardFilter.sortDirection : SortingEnum.E_ASCENDING,
        ComplexAttributeName: dashboardFilter.sortField

      });
    }


    if (!queryParams || !queryParams['searchHandle']) {
      return this.getListDataAndSync(entityListRequest, sync).pipe(mergeMap((listData: any) => {
        const listDataEntityData: EntityData = listData;
        return of(listData);
      }));
    } else {
      entityListRequest.Handle = queryParams['searchHandle'];
      return this.syncListData(entityListRequest);
    }


  }

  getListDataAndSync(entityListRequest: EntityListRequest, sync: boolean): Observable<any> {
    return this.entityListService.getHandleAndEntityListData(entityListRequest).pipe(
      catchError(error => {
        return error;
      }), mergeMap((res: any) => {
          const data: EntityData = res;
          entityListRequest.Handle = data.Handle;
          if (sync) {
            return this.syncListData(entityListRequest).pipe(mergeMap((listData: any) => {
              return of(listData);
            }));
          } else {
            return of(res);
          }

        },
      ));

  }

  syncListData(entityListRequest: EntityListRequest): Observable<any> {
    return this.getSynchronizeListWithDBObservable(entityListRequest.Handle).pipe(mergeMap((result: any) => {
      return this.getData(entityListRequest);
    }));
  }

  getData(entityListRequest: EntityListRequest): Observable<any> {
    return this.entityListService.getEntityListData(entityListRequest).pipe(
      map((dashboardData: any) => {
        entityListRequest.Handle = dashboardData.Handle;
        return dashboardData ? dashboardData : EMPTY;
      }));
  }

  getSynchronizeListWithDBObservable(entityHandle: number): Observable<any> {
    return this.entityService.synchronizeList(entityHandle.toString());
  }


  getEntityDataRequest(attributeNames: string[], className: string, entityId: string): Observable<any> {
    const entityDataRequest: EntityDataRequest = {
      Attributename: attributeNames,
      Classname: className,
      EntityId: entityId
    };
    return this.entityService.getEntityData(entityDataRequest).pipe(
      catchError(error => {
        return error;
      }), map((entityData) => {
          return entityData ? entityData : EMPTY;
        },
      ));
  }

  getEntityDataFromHandleRequest(attributeNames: string[], handleId: number): Observable<any> {
    const entityDataRequest: EntityDataFromHandleRequest = {
      loadAnimation: false,
      Attributename: attributeNames,
      Handle: handleId
    };
    return this.entityService.getEntityDataFromHandle(entityDataRequest).pipe(
      catchError(error => {
        return error;
      }), map((entityData) => {
          return entityData ? entityData : EMPTY;
        },
      ));
  }

  getEntityData(workflow: Workflow, view: View, entityId: string): Observable<any> {
    const attributeNames: string[] = this.formDataService
      .getAllFieldsFromForm(this.formDataService.getColumns(this.navigationService.getClassName(), view));
    return this.getEntityDataRequest(attributeNames, view.binding, entityId);
  }

  getEntityDataFromHandle(workflow: Workflow, view: View, handleId: number): Observable<any> {
    const attributeNames: string[] = this.formDataService
      .getAllFieldsFromForm(this.formDataService.getColumns(this.navigationService.getClassName(), view));
    return this.getEntityDataFromHandleRequest(attributeNames, handleId);
  }


  createEntity(formGroup: FormGroup, entityDescriptors: EntityDescriptor[], className: string): Observable<any> {
    const namedValueArr: NamedValue[] = [];
    // const formValues: string[] = this.formGroup.value;

    for (const controlsKey in formGroup.controls) {
      const attributeDescriptor = entityDescriptors.find(r => r.Name === controlsKey);

      if (attributeDescriptor) {
        if (attributeDescriptor.DataType !== 'Entity') {

          if (attributeDescriptor.DataType === 'Float') {
            namedValueArr.push({Name: controlsKey, Value: '0', DataType: attributeDescriptor.DataType});
          } else {
            namedValueArr.push({
              Name: controlsKey,
              Value: formGroup.controls[controlsKey].value,
              DataType: attributeDescriptor.DataType
            });
          }


        } else {
          namedValueArr.push({Name: controlsKey, Value: formGroup.controls[controlsKey].value, DataType: 'ref'});
        }
      }

    }

    const createEntityDataRequest: CreateEntityDataRequest = {AttributeValues: namedValueArr, Classname: className};
    return this.entityService.createEntityData(createEntityDataRequest);

  }

  updateSingleFormField(formGroup: FormGroup,
                        controlName: string,
                        value: string,
                        entityDescriptors: EntityDescriptor[],
                        entityHandle: number,
                        commit: boolean,
                        inputFormData: InputFormData,
                        inputData: InputData): Observable<any> {

    const namedValueArr: NamedValue[] = [];
    const attributeDescriptor = entityDescriptors.find(r => r.Name === controlName);

    if (attributeDescriptor) {
      if (attributeDescriptor.DataType === 'Float' && value === '') {
        value = null;
      }

      if (attributeDescriptor.DataType !== 'Entity') {
        namedValueArr.push({Name: controlName, Value: value, DataType: attributeDescriptor.DataType});
      } else {
        namedValueArr.push({Name: controlName, Value: value, DataType: 'String'});
      }
    } else {
      const attributeKeyArr: string[] = controlName.split('.');

      if (attributeKeyArr.length > 1) {
        controlName = attributeKeyArr[0];
        namedValueArr.push({Name: controlName, Value: value, DataType: 'ref'});
      }
    }

    const setEntityDataRequest: SetEntityDataRequest = {
      loadAnimation: false,
      AttributeValues: namedValueArr,
      EntityHandleId: entityHandle,
      Commit: commit
    };

    return this.entityService.setEntityData(setEntityDataRequest).pipe(map((res: any) => {
      if (inputFormData && inputFormData.topBarElements) {
        // this.formDataService.getCallFunctionActivators(inputFormData.topBarElements.buttonActivators, inputData).subscribe(() => {
        // });
      }
      if (inputFormData && inputFormData.viewBarElements) {
        // this.formDataService.getCallFunctionActivators(inputFormData.viewBarElements.buttonActivators, inputData).subscribe(() => {
        // });
      }

    }));
  }

  getEntityHandleFromEntityData(entityData: EntityData): number {
    return entityData.Handle;
  }

  getEntityId(entityData: EntityData): string {
    return entityData.Attributes.Attribute.find(r => r.Name === 'ID').Value;
  }

  getEntityClassname(entityData: EntityData): string {
    return entityData.Classname;
  }

  updateRefFields(formGroup: FormGroup, column: Column, entityData: EntityData, entityHandle: number): void {
    entityData.Attributes.Attribute.forEach((field: EntityData) => {

      let refFieldName = '';

      if (field.Name === 'ID') {
        refFieldName = column.refName;
        field.Value = entityHandle.toString();
      } else {
        refFieldName = column.refName + '.' + field.Name;
      }

      if (formGroup.controls[refFieldName]) {
        formGroup.controls[refFieldName].setValue(field.Value);
      }

    });
  }

  clearRefFields(formGroup: FormGroup, column: Column): void {
    for (let key in formGroup.controls) {
      if (column.refName !== null && column.refName !== '') {
        if (key.indexOf(column.refName) === 0) {
          formGroup.controls[key].setValue('');
        }
      }
    }
  }

  getRequiredFields(entities: EntityData, entityDescriptors: EntityDescriptor[], columns: Column[], formGroup: FormGroup): Column[] {
    const result: Column[] = [];

    columns.forEach((column: Column) => {
      let columnName: string = column.attribute;

      if (column.refName) {
        const refNameArr: string[] = column.refName.split('.');

        if (refNameArr.length > 1) {
          columnName = refNameArr[0];
        } else {
          columnName = column.refName;
        }
      }

      const entityData = entities.Attributes.Attribute.find(r => r.Name === columnName);
      const entityDescriptor: EntityDescriptor = entityDescriptors.find(r => r.Name === columnName);


      if (entityData && entityDescriptor) {
        const permission: Permissions = this.permissionService.getAndSetPermission(entityData.Flags, entityDescriptor.Flags, null, null);

        if ((column && permission.required) && (entityData.Value === '' || entityData.Value === null)) {
          column.permissions = permission;
          result.push(column);
        }
      }

    });

    return result;
  }

  getCallFunctionRequest(functionName: string, handle: number): Observable<any> {
    const entityCallFunction: EntityCallFunctionRequest = {Functionname: functionName, Handle: handle};
    return this.entityService.entityCallFunction(entityCallFunction);
  }

  getCallFunctionWithHandlerRequest(functionName: string, handle: number): Observable<any> {
    const entityCallFunction: EntityCallFunctionRequest = {Functionname: functionName, Handle: handle};
    return this.entityService.entityCallFunctionHandler(entityCallFunction);
  }

  getCallFunctionByHandleRequest(handle: number): Observable<EntityCallFunctionByHandleResponse> {
    const handleRequest: HandleRequest = {Handle: handle};
    return this.entityService.entityCallFunctionByHandle(handleRequest);
  }


  getEntityDataAndUpdateForm(inputFormData: InputFormData, inputData: InputData): Observable<any> {
    const workFlow: Workflow = inputFormData.workflow;

    const view: View = inputFormData.view;

    const hiddenAttributes: Section[] = this.formDataService.addSectionsForControlsWithAttributes(view);
    if (hiddenAttributes.length > 0) {
      view.sections = [...view.sections, ...hiddenAttributes];
    }


    return this.getEntityDataFromHandle(workFlow, view, inputData.entityHandle).pipe(mergeMap((res: any) => {
      const obsArray: Observable<any>[] = [];
      inputData.entityData = res;
      const columns: Column[] = this.formDataService.getColumns(view.binding, view);
      this.formDataService.updateForm(inputData.entityData, inputFormData.entityDescriptors, inputData.formGroup, inputData.locale);
      this.getPermissionsByEntityData(res, inputFormData, columns, inputData);
      this.getRequiredFields(inputData.entityData, inputFormData.entityDescriptors, columns, inputData.formGroup);
      this.formDataService.refreshSvgComponents(inputFormData);

      // return of(true);
      if (inputFormData.topBarElements && inputFormData.topBarElements.buttonActivators && inputFormData.topBarElements.buttonActivators.length > 0) {
        obsArray.push(this.formDataService.initializeTopBar(inputFormData, inputData, inputFormData.topBarElements));
      }

      if (inputFormData.viewBarElements && inputFormData.viewBarElements.buttonActivators && inputFormData.viewBarElements.buttonActivators.length > 0) {
        obsArray.push(this.formDataService.initializeViewBar(inputFormData, inputData, inputFormData.viewBarElements));
      }

      if (obsArray.length > 0) {
        return from(obsArray).pipe(mergeMap((obs: Observable<any>) => {
          return obs;
        }), catchError((error) => {
          console.log(error);
          return error;
        }));
      } else {
        return of(true);
      }

    }));


  }

  getPermissionsByEntityData(entityDataArr: EntityData, inputFormData: InputFormData, columns: Column[], inputData: InputData) {
    let entityDescriptorFlags: number;


    columns.forEach((column: Column) => {
      let columnName: string = column.attribute;

      if (column.refName) {
        const refNameArr: string[] = column.refName.split('.');

        if (refNameArr.length > 1) {
          columnName = refNameArr[0];
        } else {
          columnName = column.refName;
        }

      }

      const entityData = entityDataArr.Attributes.Attribute.find(r => r.Name === columnName);
      const entityDescriptor: EntityDescriptor = inputFormData.entityDescriptors.find(r => r.Name === columnName);


      if (entityData && entityDescriptor) {
        entityDescriptorFlags = entityDescriptor.Flags;

        this.permissionService.getAndSetPermission(entityData.Flags, entityDescriptorFlags, column, inputData.formGroup);
      }
    });
  }

  // getKanbanXData(inputFormData: InputFormData, inputData: InputData): Observable<any> {
  //   const createEntityData: CreateEntityDataRequest = {AttributeValues: [], Classname: inputFormData.view.binding};
  //
  //   return this.entityService.createEntityData(createEntityData).pipe(mergeMap((res: any) => {
  //
  //     const entityDescriptor: EntityDescriptor = inputFormData.entityDescriptors.find(r => r.Columnname === inputFormData.view.kanban.x.attribute);
  //
  //     const columns: Column[] = [];
  //     columns.push({attribute: '$Displayname'});
  //
  //     const dashboardFilter: DashboardFilter = DashboardDataService.getDefaultDashboardFilter();
  //     const refView: View = {binding: entityDescriptor.FkForeignEntityName, columns};
  //
  //     return this.getDashboardDate(dashboardFilter, refView, inputData).pipe(mergeMap((res: any) => {
  //       const entityHandleRequest: EntityHandleRequest = {
  //         EntityId: '0000071f:00000003',
  //         Classname: entityDescriptor.FkForeignEntityName
  //       };
  //       return this.entityService.getEntityHandle(entityHandleRequest).pipe(mergeMap((res: any) => {
  //
  //         const getEntityListRequest: EntityListRequest = {
  //           AttributeNames: ['$Displayname'],
  //           Handle: res,
  //           BaseFilter: null,
  //           Limit: 10,
  //           Start: 0,
  //         };
  //         // this.dashboardDataService.setDashboardSorting(getEntityListRequest, this.inputDashboardData.dashboardFilter);
  //         return this.entityListService.getEntityListData(getEntityListRequest);
  //       }));
  //     }));
  //   }));
  // }

  getEntityChildren(entityData: any,
                    refView: View,
                    inputFormData: InputFormData,
                    inputData: InputData,
                    column: Column,
                    inputDashboardData: InputDashboardData,
                    level: number): Observable<any> {

    if ((column.treeView.attributes && column.treeView.attributes[level]) || !column.treeView.attributes) {
      return from(entityData).pipe(mergeMap((entityDataX: EntityData) => {
        const dashboardFilter: DashboardFilter = this.dashboardDataService.getDashboardRequest();
        const baseFilters: BaseFilters[] = [];

        baseFilters.push({
          ClassName: column.treeView.attributes ? column.treeView.attributes[level].binding : column.refEntity,
          FilterConditions: [{
            Value: entityDataX.Handle.toString(),
            DataType: 'Entity',
            FilterOperator: 'Equal',
            Name: column.treeView.attribute ? column.treeView.attribute : column.treeView.attributes[level].attribute
          }]
        });

        dashboardFilter.baseFilter = JSON.stringify(baseFilters);
        dashboardFilter.start = 0;
        dashboardFilter.limit = 0;

        if (column.treeView && column.treeView.attributes && column.treeView.attributes.length > 0) {
          dashboardFilter.sortDirection = column.treeView.attributes[level].filter &&
          column.treeView.attributes[level].filter.defaultFilterSortingDirection ? column.treeView.attributes[level].filter.defaultFilterSortingDirection : 'eAscending';
          dashboardFilter.sortField = column.treeView.attributes[level].filter &&
          column.treeView.attributes[level].filter.defaultFilterSortingField ? column.treeView.attributes[level].filter.defaultFilterSortingField : 'id';

        } else {
          dashboardFilter.sortDirection = refView.filter ? refView.filter.defaultFilterSortingDirection : 'eAscending';
          dashboardFilter.sortField = refView.filter ? refView.filter.defaultFilterSortingField : 'id';

        }

        inputDashboardData.dashboardFilter = dashboardFilter;

        return this.getDashboardData(refView, inputData, inputDashboardData).pipe(mergeMap(res => {
          entityDataX.Children = res.EntityDataArray.EntityData;
          // If there are more child go on
          if (entityDataX.Children && entityDataX.Children.length > 0) {
            return of(entityData);
          } else {
            return of(entityData);
          }
        }));
      }));
    } else {
      return of(entityData);
    }

  }


  getColumnsForRefEntity(refName: string,
                         refEntity: string,
                         catalogColumns: CatalogColumns[],
                         displayedColumns: string[],
                         searchableAttributes: string[],
                         filter: FilterFile,
                         inputFormData: InputFormData,
                         isRefCatalog: boolean = false): View {
    const columns: Column[] = [];

    if (!isRefCatalog && (inputFormData.view.type === ViewEnum.LIST || inputFormData.view.type === ViewEnum.KANBAN)) {
      inputFormData.view.columns.forEach((columnX: Column) => {
        if (columnX.catalogColumns) {
          catalogColumns.forEach((catalogColumn: CatalogColumns) => {
            columns.push({attribute: catalogColumn.attribute});
          });
        } else {
          if (columnX.refName === refName) {

            const columnCopy: Column = Object.assign({}, columnX);
            columnCopy.attribute = columnX.name;
            columns.push(columnCopy);
          }
        }
      });
      // this.columns = columns;
      return {
        columns, binding: refEntity, filter,
        searchableAttributes
      };
    } else {

      if (catalogColumns && catalogColumns.length > 0) {
        catalogColumns.forEach((catalogColumn: CatalogColumns) => {
          columns.push({attribute: catalogColumn.attribute});
        });
      }

      if (displayedColumns && displayedColumns.length > 0) {
        displayedColumns.forEach((attribute: string) => {
          columns.push({attribute});
        });
      }

      if (!displayedColumns && !catalogColumns) {
        columns.push({attribute: '$Displayname'});
      }
    }


    // this.columns = columns;
    return {
      columns,
      binding: refEntity,
      filter: filter,
      searchableAttributes: searchableAttributes
    };
  }

  getDashboardData(refView: View, inputData: InputData, inputDashboardData: InputDashboardData, getSearchableAttributes = false, sync: boolean = false): Observable<any> {
    return this.getDashboardDate(inputDashboardData.dashboardFilter, refView, inputData, getSearchableAttributes, sync).pipe(mergeMap((res: any) => {

      inputDashboardData.pageView = this.dashboardDataService.setPageCount(inputDashboardData.dashboardFilter.limit,
        inputDashboardData.entityCount,
        inputDashboardData.activePage);

      return of(res);
    }));

  }

  checkRequiredFields(inputFormData: InputFormData, inputData: InputData): Column[] {
    const columns: Column[] = this.formDataService.getColumns(inputFormData.view.binding, inputFormData.view);
    return this.getRequiredFields(inputData.entityData, inputFormData.entityDescriptors, columns, inputData.formGroup);
  }

  setListEntityData(entityData: any,
                    inputFormData: InputFormData,
                    inputDashboardData: InputDashboardData,
                    inputNavigationData: InputNavigationData,
                    inputData: InputData,
                    dashboardColumns: Column[], locale: string): void {

    let filterView: FilterFile;
    let filterFileName: string;

    inputDashboardData.entityData = null;
    inputDashboardData.entityData = entityData.EntityDataArray.EntityData;
    inputDashboardData.entityCount = entityData.FullSize;
    inputDashboardData.entityHandle = entityData.Handle;

    if (inputNavigationData && inputNavigationData.queryParams.page) {
      inputDashboardData.activePage = parseInt(inputNavigationData.queryParams.page, 10);
    }

    if (inputFormData.view.filters) {
      if (inputFormData.view.defaultFilterFileName) {
        filterFileName = inputFormData.view.defaultFilterFileName;
      } else {
        if (inputDashboardData.dashboardFilter.filterFile) {
          filterFileName = inputDashboardData.dashboardFilter.filterFile;
        }
      }


      filterView = inputFormData.view.filters.find(r => r.file === filterFileName);
      inputDashboardData.dashboardFilter.filterFile = filterView.file;

    }

    if (filterView && filterView.columns && filterView.columns.length > 0) {
      filterView.columns.forEach((column: Column) => {
        dashboardColumns.push(column);

        if (column.useDisplayColor && column.colorAttribute) {
          dashboardColumns.push({attribute: column.colorAttribute, refName: column.colorAttribute.split('.')[0], visible: false});
        }
      });
    } else {
      inputFormData.view.columns.forEach((column: Column) => {
        dashboardColumns.push(column);

        if (column.useDisplayColor && column.colorAttribute) {
          dashboardColumns.push({attribute: column.colorAttribute, refName: column.colorAttribute.split('.')[0], visible: false});
        }
      });
    }


    if (!dashboardColumns.find(r => r.attribute === 'ID')) {
      dashboardColumns.push({attribute: 'ID', label: 'ID'});
    }
    if (!dashboardColumns.find(r => r.attribute === '$Displayname') && this.responsivenessService.isTabletOrMobileView()) {
      dashboardColumns.push({attribute: '$Displayname', label: '$Displayname', visible: false});
    }
    // inputDashboardData.dashboardFilter = Object.assign(DashboardDataService.getDefaultDashboardFilter(),
    //   inputDashboardData.dashboardFilter);

    // inputDashboardData.currentDashboardFilter = Object.assign(DashboardDataService.getDefaultDashboardFilter(),
    //   inputNavigationData.queryParams);


    // if (inputFormData.view.sortField) {
    //   inputDashboardData.dashboardFilter.sortField = inputFormData.view.sortField;
    //   inputDashboardData.dashboardFilter.sortDirection =
    //     inputFormData.view.sortDirection ? inputFormData.view.sortDirection : SortingEnum.E_ASCENDING;
    //
    // }
    // inputDashboardData.dashboardFilter.start = parseInt(inputDashboardData.dashboardFilter.start.toString(), 10);
    // inputDashboardData.dashboardFilter.limit = parseInt(inputDashboardData.dashboardFilter.limit.toString(), 10);
    //
    // inputDashboardData.listExtendedFilterFormGroup = this.formDataService.createForm(inputFormData.view.columns);
    //
    // if (inputDashboardData.dashboardFilter.start === 0) {
    //   inputDashboardData.cache = [];
    // }

    this.dashboardDataService.calculatePagination(inputDashboardData);
    //
    // inputDashboardData.dataGridToolbarForm =
    //   this.dashboardDataService.getFilterFormGroup(dashboardColumns, inputDashboardData, inputData);

    if (inputFormData.view.defaultFilterColumns && inputDashboardData.listExtendedFilterFormGroup) {
      inputDashboardData.isExtendedFilterBar = true;

      inputFormData.view.defaultFilterColumns.forEach((column: Column) => {
        if (inputDashboardData.listExtendedFilterFormGroup.controls[column.attribute]) {
          inputDashboardData.listExtendedFilterFormGroup.controls[column.attribute].setValue(true);
        }
      });


      if (Object.keys(inputData.formGroup.controls).length === 0) {
        this.formDataService.setFilters(inputDashboardData, inputFormData, inputData);
      }

    }


    inputDashboardData.jsonDataHeader = this.dashboardDataService.convertToJsonDataHeader(
      inputDashboardData.entityData,
      inputFormData,
      inputDashboardData,
      dashboardColumns
    );

    inputDashboardData.jsonData = this.dashboardDataService.convertToJsonDataArray(
      inputFormData,
      inputDashboardData,
      inputDashboardData.entityData,
      inputFormData.entityDescriptors,
      dashboardColumns,
      locale
    );

    inputDashboardData.actionButtons = [];

    if ((inputFormData.view.listSettings && inputFormData.view.listSettings.activators) || (inputFormData.view.filters)) {
      let listSettings: ListSettings;

      if (inputFormData.view.listSettings && inputFormData.view.listSettings.activators) {
        listSettings = inputFormData.view.listSettings;
      }

      if (inputFormData.view.filters && inputDashboardData.dashboardFilter.filterFile) {
        const filterView: FilterFile = inputFormData.view.filters.find(r => r.file === inputDashboardData.dashboardFilter.filterFile);

        if (filterView && filterView.listSettings) {
          listSettings = filterView.listSettings;
        }
      }

      if (listSettings) {
        inputDashboardData.actionButtons = listSettings.activators;
      }


    }

    inputDashboardData.cache = [];
  }

  setEntityData($event, inputFormData: InputFormData, inputData: InputData): void {
    // this.inputData.formGroup.disable();

    if (inputFormData.isControlFocused && inputFormData.isCommitHovered) {
      return null;
    }
    this.routingProviderService.disableLoaderDarkening();

    let value: string;
    if ($event.target.dataType !== undefined && $event.target.dataType === 'Time') {
      value = $event.target.value;
    } else {
      if (inputData.formGroup.controls[$event.target.id]) {
        value = inputData.formGroup.controls[$event.target.id].value;
      }

    }

    if (inputData.entityHandle && inputData.formGroup.controls[$event.target.id]) {
      const updateObs: Observable<any> = this.updateSingleFormField(
        inputData.formGroup,
        $event.target.id,
        value,
        inputFormData.entityDescriptors,
        inputData.entityHandle,
        false,
        inputFormData,
        inputData).pipe(mergeMap((res: any) => {
        // this.inputFormData.isControlFocused = false;
        // this.inputFormData.controlFocusedName = null;

        return this.getEntityDataAndUpdateForm(inputFormData, inputData);
      }), catchError((error) => {
        inputData.formGroup.enable();
        return error;

      }));

      this.callbackService.setInitialRequest(updateObs);
      updateObs.subscribe(() => {

      }, error => {
      }, () => {
        this.formDataService.refreshSvgComponents(inputFormData);
        this.routingProviderService.enableLoaderDarkening();
      });
    } else {
      this.routingProviderService.enableLoaderDarkening();
    }


  }
}
