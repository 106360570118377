import {Injectable} from '@angular/core';
import {
  Activator,
  Column, Configuration,
  EntityDescriptorFlagsEnum,
  EntityObservers,
  Permissions, View,
  Workflow,
  WorkflowView
} from '../../pit/structure';
import {FormGroup, Validators} from '@angular/forms';
import {EntityDescriptor} from './swagger/models/entity-descriptor';
import {InputFormData} from '../../pit/input-data';
import {Observable, of, from} from 'rxjs';
import {concatMap, map, mergeMap} from 'rxjs/operators';
import {EntityDescriptorResponse} from './swagger/models/entity-descriptor-response';
import {FormDataService} from './form-data.service';
import {EntityService} from './swagger/services/entity.service';
import {ConfigService} from './config.service';
import {CacheService} from '../../pit/services/cache.service';


export enum PermissionEnum {
  NOTHING = 'nothing',
  READ_ONLY = 'read_only',
  VISIBLE = 'visible',
  READ_ONLY_VISIBLE = 'read_only_visible',
  REQUIRES = 'required',
  READ_ONLY_REQUIRED = 'read_only_required',
  VISIBLE_REQUIRED = 'visible_required',
  READ_ONLY_VISIBLE_REQUIRED = 'read_only_visible_required',
}

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private entityService: EntityService,
              private configService: ConfigService,
              private cacheService: CacheService) {
  }

  setColumnPermissions(writeable: boolean,
                       visible: boolean,
                       required: boolean,
                       column: Column,
                       formGroup: FormGroup,
                       permissionEnum: PermissionEnum): Permissions {


    const permissions: Permissions = {required, visible, writeable};


    if (required && formGroup && column) {
      formGroup.controls[column.attribute].setValidators([Validators.required]);
    }

    if (!writeable && formGroup) {
      formGroup.controls[column.attribute].disable();
    }

    if (writeable && formGroup) {
      formGroup.controls[column.attribute].enable();
    }

    if (column && permissionEnum) {
      column.permissionType = permissionEnum;
      column.permissions = permissions;
    }

    return permissions;
  }

  getAndSetPermission(entityFlags: number, attributeFlags: number, column: Column, formGroup: FormGroup): Permissions {
    if (((attributeFlags & EntityDescriptorFlagsEnum.FLAG_HAS_READ_PRIVILEGE) === 0)
      && ((attributeFlags & EntityDescriptorFlagsEnum.FLAG_HAS_WRITE_PRIVILEGE) === 0)) {
      return this.setColumnPermissions(false, false, false, column, formGroup, PermissionEnum.READ_ONLY);
    } else {
      if (((attributeFlags & EntityDescriptorFlagsEnum.FLAG_HAS_READ_PRIVILEGE) !== 0) && ((attributeFlags & EntityDescriptorFlagsEnum.FLAG_HAS_WRITE_PRIVILEGE) === 0)) {

        switch (entityFlags) {
          case 3:
            return this.setColumnPermissions(false, true, false, column, formGroup, PermissionEnum.READ_ONLY_VISIBLE);
          default:
            return this.setColumnPermissions(true, true, false, column, formGroup, PermissionEnum.VISIBLE);
        }
      } else {

        switch (entityFlags) {

          case 0:
            return this.setColumnPermissions(true, false, false, column, formGroup, PermissionEnum.NOTHING);
          case 1:
            return this.setColumnPermissions(false, false, false, column, formGroup, PermissionEnum.READ_ONLY);
          case 2:
            return this.setColumnPermissions(true, true, false, column, formGroup, PermissionEnum.VISIBLE);
          case 3:
            return this.setColumnPermissions(false, true, false, column, formGroup, PermissionEnum.READ_ONLY_VISIBLE);
          case 4:
            return this.setColumnPermissions(true, false, true, column, formGroup, PermissionEnum.REQUIRES);
          case 5:
            return this.setColumnPermissions(false, false, true, column, formGroup, PermissionEnum.READ_ONLY_REQUIRED);
          case 6:
            return this.setColumnPermissions(true, true, true, column, formGroup, PermissionEnum.VISIBLE_REQUIRED);
          case 7:
            return this.setColumnPermissions(false, true, true, column, formGroup, PermissionEnum.READ_ONLY_VISIBLE_REQUIRED);
        }
      }
    }
  }

  getEntityDescriptorFlags(flags: number): EntityDescriptorFlagsEnum {

    let result: EntityDescriptorFlagsEnum = null;

    if ((flags & EntityDescriptorFlagsEnum.FLAG_HAS_READ_PRIVILEGE) !== 0) {
      result = EntityDescriptorFlagsEnum.FLAG_HAS_READ_PRIVILEGE;
    }

    if ((flags & EntityDescriptorFlagsEnum.FLAG_HAS_READ_PRIVILEGE) !== 0) {
      result = EntityDescriptorFlagsEnum.FLAG_HAS_READ_PRIVILEGE;
    }

    if ((flags & EntityDescriptorFlagsEnum.FLAG_HAS_WRITE_PRIVILEGE) !== 0) {
      result = EntityDescriptorFlagsEnum.FLAG_HAS_WRITE_PRIVILEGE;
    }

    if ((flags & EntityDescriptorFlagsEnum.FLAG_CREATE_DELETE_PRIVILEGE) !== 0) {
      result = EntityDescriptorFlagsEnum.FLAG_CREATE_DELETE_PRIVILEGE;
    }

    // return EntityDescriptorFlagsEnum.FLAG_CREATE_DELETE_PRIVILEGE;
    return result;
  }

  isPermittedAttribute(attribute: Column, entityDescriptors: EntityDescriptor[], flag: EntityDescriptorFlagsEnum): boolean {
    let entityDescriptor: EntityDescriptor;

    if (attribute.attribute.indexOf('.') > -1) {
      const refAttributeArr: string[] = attribute.attribute.split('.');
      entityDescriptor = entityDescriptors.find(r => r.Name === refAttributeArr[0]);
    } else {
      entityDescriptor = entityDescriptors.find(r => r.Name.toLowerCase() === attribute.attribute.toLowerCase());
    }
    if (!entityDescriptor) {
      return false;
    }
    return (entityDescriptor.Flags & flag) !== 0;
  }

  checkActivatorPermissions(activator: Activator): Observable<Activator> {
    const workflowVIew: WorkflowView = FormDataService.getWorkFlowAndViewFromEndpoint(activator.action.endpoint, this.configService.getConfiguration());
    const className: string = workflowVIew.tabWorkflowView ? workflowVIew.tabWorkflowView.view.binding : workflowVIew.view.binding;

    const attributeDescriptor: EntityDescriptorResponse = this.cacheService.getAttributeDescriptorFromCache(className);

    if (!attributeDescriptor) {
      return this.entityService.getEntityDescriptor(className).pipe(mergeMap((entityDescriptorResponse: EntityDescriptorResponse) => {
        this.cacheService.addAttributeDescriptorToCache(entityDescriptorResponse);
        activator.permission = this.getEntityDescriptorFlags(entityDescriptorResponse.Flags);
        return of(activator);
      }));
    } else {
      activator.permission = this.getEntityDescriptorFlags(attributeDescriptor.Flags);
      return of(activator);
    }
  }
}
