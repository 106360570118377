import {Injectable} from '@angular/core';
import {ToastComponent} from '@syncfusion/ej2-angular-notifications';
import {Tooltip} from '@syncfusion/ej2-popups';
import {
  Activator,
  MessageToast,
  Message,
  ToastEvent,
  ToastEventEnum, MessageTypeEnum
} from '../../pit/structure';
import {TranslationService} from './translation.service';
import {ConfigService} from './config.service';


@Injectable({
  providedIn: 'root'
})
export class MessageStackService {
  toast: ToastComponent;

  messages: Message[] = [];

  messagesHistory: Message[] = [];

  constructor(public translationService: TranslationService) {
  }

  addMessage(message: Message): void {
    this.messages.push(message);


  }


  checkAndShowToastEvent(activator: Activator, toastEventEnum: ToastEventEnum): void {
    if (activator.toasts) {
      const toastEvent: ToastEvent = activator.toasts.find(r => r.event === toastEventEnum);

      if (toastEvent) {
        const message: Message = {
          title: toastEvent.title ? toastEvent.title : '',
          text: toastEvent.text ? toastEvent.text : '',
          type: MessageTypeEnum.SUCCESS
        };

        this.addMessage(message);
      }
    }
  }

  moveUserMessageToHistory() {

    if (this.messages.length > 0) {
      this.messagesHistory.push(this.messages[0]);
      this.messages = this.messages.slice(1, this.messages.slice.length);
    }
  }


}
