<div class="info-messages-area">
  <div class="message">
    <div class="toast-container" id="toast_Container"></div>
<!--    <button class="button" onclick="popUp()">Create Toast</button>-->

    <ng-container *ngFor="let message of messageStackService.messages">
      <app-single-toast
        [message]="message"
        [timeout]="settings.toastTimeout ? settings.toastTimeout : 5000"></app-single-toast>

    </ng-container>

  </div>

</div>
