import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {QueryParamsResolverService} from './pit/resolver/query-params-resolver.service';
import {ConfigFilesResolverService} from './pit/resolver/config-files-resolver.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    resolve: {
      queryParamsData: QueryParamsResolverService,
      configFiles: ConfigFilesResolverService,
    },
  },
  {
    path: '',
    loadChildren: () => import('./pit/pit.module').then(m => m.PitModule)
  },
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollOffset: [0, 0],
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
  }),],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
