import {Injectable} from '@angular/core';
import {DashboardFilter} from '../models/DashboardFilter';
import {DashboardDataService} from './dashboard-data.service';
import {
  Activator,
  AxisData, Children,
  FilterFile, Section,
  Timeline,
  TimelineCategory,
  TimelineResource, TimeLineResourcesData,
  TimelineResult,
  View,
  Workflow, WorkflowView
} from '../../pit/structure';
import {AttributeClassField} from './swagger/models/attribute-class-field';
import {from, Observable, of} from 'rxjs';
import {FormDataService} from './form-data.service';
import {catchError, concatMap, map, mergeMap} from 'rxjs/operators';
import {DataService} from './data.service';
import {EntityService} from './swagger/services/entity.service';
import {EntityData} from './swagger/models/entity-data';
import {EntityListRequest} from './swagger/models/entity-list-request';
import {EntitylistService} from './swagger/services/entitylist.service';
import {FilterCondition} from './swagger/models/filter-condition';
import {BaseFilters} from './swagger/models/base-filters';
import {BarTypeEnum, InputData, InputFormData, TimelineFilter} from '../../pit/input-data';
import {SessionService} from './swagger/services/session.service';
import {View as SchedulerView} from '@syncfusion/ej2-angular-schedule';
import {GetDataByAttributeRequest} from './swagger/models/get-data-by-attribute-request';
import {UrlSegment} from '@angular/router';
import {TimelineDiagramConfig} from 'mermaid/dist/config.type';
import {FilterParameter} from './swagger/models/filter-parameter';
import {EntitySmartService} from './entity-smart.service';
import {FormControl, FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ResolverService {

  constructor(
    private formDataService: FormDataService,
    private entityListService: EntitylistService,
    private sessionService: SessionService,
    private entityService: EntityService,
    private entitySmartService: EntitySmartService,
    private dataService: DataService
  ) {
  }

  getEntityDescriptors(workflow: Workflow, view: View, dashboardFilterParams: DashboardFilter, routeViewName: string): Observable<any> {
    if (workflow) {
      const dashboardFilter: DashboardFilter = Object.assign(
        DashboardDataService.getDefaultDashboardFilter(),
        dashboardFilterParams
      );

      const workflowName: string = workflow.name;

      // Wenn es eine TabView ist
      if (view.children && view.children.length > 0) {
        view = workflow.views[routeViewName];
      }

      if (view.sections || (view.columns && view.columns.length > 0)) {
        if (view.sections) {
          const section: Section[] = this.formDataService.addSectionsForControlsWithAttributes(view);
          view.sections = [...view.sections, ...section];
        }

        const attributeClassFields: AttributeClassField[] = this.formDataService.getAttributeFields(view.binding, view);

        return this.formDataService.getEntityDescriptorsRequest(attributeClassFields);
      }

      if (view.filters && view.filters.length > 0) {
        const listFilter: FilterFile = view.filters.find(r => r.file === dashboardFilter.filterFile);

        if (listFilter) {
          const filterView: View = {columns: listFilter.columns};
          const attributeClassFields: AttributeClassField[] = this.formDataService.getAttributeFields(view.binding, filterView);

          return this.formDataService.getEntityDescriptorsRequest(attributeClassFields);
        }
      }
    }

    return of(null);
  }

  getMonday(d): Date {
    d = new Date(d);
    var day = d.getDay(), diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  getTimeLineData(
    view: View,
    timelineResourceData: TimeLineResourcesData,
    startDate: Date = null,
    endDate: Date = null,
    viewMode: SchedulerView = null,
    selectedDate: Date = null,
    tabChild: Children = null,
    entityHandle: number = null,
    filterParameters: FilterParameter[] = null
  ): Observable<TimelineResult> {
    let attributeNames: string[] = [];
    const timelineConfig: Timeline = view.timeline;

    timelineConfig.appointment.Subject.forEach(
      (attribute: string) => {
        attributeNames.push(attribute);
      }
    );

    attributeNames.push(timelineConfig.appointment.StartTime);
    if (timelineConfig.appointment.EndTime) {
      attributeNames.push(timelineConfig.appointment.EndTime);
    }

    if (timelineConfig.appointment.groupAttribute) {
      attributeNames.push(timelineConfig.appointment.groupAttribute);
    }

    if (timelineConfig.settings.StatusColor) {
      attributeNames.push(timelineConfig.settings.StatusColor);
    }

    // attributeNames.push(timelineConfig.appointment.Loc);
    // attributeNames.push(timelineConfig.yAxis);
    // attributeNames.push('ref_VT_TGA_Object.$Displayname');

    const refResult: string [] = [];
    attributeNames.forEach(
      (attributeName: string) => {
        if (attributeName.indexOf('.') > -1) {
          const attributeNameArr: string[] = attributeName.split('.');
          if (refResult.indexOf(attributeNameArr[0]) === -1) {
            refResult.push(attributeNameArr[0]);
          }
        }
      }
    );

    attributeNames = [...attributeNames, ...refResult];

    if (startDate && endDate && startDate.getTime() === endDate.getTime()) {
      endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 1);
    }

    if (!startDate) {
      const today: Date = new Date();

      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      selectedDate = today;

      const d = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate() + 1);
      endDate = d;
      // Week
      // endDate = new Date(startDate.getTime() + ((7 * 24 * 60 * 60) * 1000));
    } else {
      selectedDate = startDate;
    }

    const baseFilters: BaseFilters[] = [];

    let filterConditions: FilterCondition[] = [];

    if (timelineConfig.appointment.EndTime) {
      filterConditions.push({
        Name: timelineConfig.appointment.StartTime,
        FilterJoin: 'eAnd',
        FilterOperator: 'Less',
        Value: endDate.toISOString()
      });
  
      baseFilters.push({ClassName: view.binding, FilterConditions: filterConditions});
  
      filterConditions = [];
  
      filterConditions.push({
        Name: timelineConfig.appointment.EndTime,
        FilterJoin: 'eAnd',
        FilterOperator: 'Greater',
        Value: startDate.toISOString()
      });
    } else {
      const startDateAdjusted = new Date(startDate.valueOf());
      startDateAdjusted.setUTCHours(startDateAdjusted.getUTCHours() - 5);
      filterConditions.push({
        Name: timelineConfig.appointment.StartTime,
        FilterJoin: 'eAnd',
        FilterOperator: 'Greater',
        Value: startDateAdjusted.toISOString()
      });

      baseFilters.push({ClassName: view.binding, FilterConditions: filterConditions});
  
      filterConditions = [];

      const endDateAdjusted = new Date(endDate.valueOf());
      endDateAdjusted.setUTCHours(endDateAdjusted.getUTCHours() + 5);
      filterConditions.push({
        Name: timelineConfig.appointment.StartTime,
        FilterJoin: 'eAnd',
        FilterOperator: 'Less',
        Value: endDateAdjusted.toISOString()
      });
    }

    baseFilters.push({ClassName: view.binding, FilterConditions: filterConditions});
    if (tabChild && tabChild.attribute) {
      const getDataByAttribute: GetDataByAttributeRequest = {
        AttributeNames: attributeNames,
        filterFile: timelineConfig.appointment.filter ? timelineConfig.appointment.filter.file : null,
        Handle: entityHandle,
        Start: 0,
        Limit: 0,
        ListAttribute: tabChild.attribute
      };

      const timelineFilter: TimelineFilter = {start: startDate, end: endDate, selectedDate, viewMode};

      return this.entityListService.getDataByAttribute(getDataByAttribute).pipe(
        map(
          (res: any) => {
            const entityData: EntityData[] = res.EntityDataArray.EntityData;

            return this.formDataService.convertTimeLineData(entityData, timelineConfig, timelineFilter, timelineResourceData);
          }
        )
      );
    } else {
      const entityListRequest: EntityListRequest = {
        AttributeNames: attributeNames,
        BaseFilter: baseFilters,
        filterFile: timelineConfig.appointment.filter ? timelineConfig.appointment.filter.file : null,
        FilterParameters: filterParameters,
        ActualEntityHandle: entityHandle,
        Sortings: [],
        Start: 0,
        Limit: 0
      };

      const timelineFilter: TimelineFilter = {start: startDate, end: endDate, selectedDate, viewMode};

      return this.entityListService.getHandleAndEntityListData(entityListRequest).pipe(
        map(
          (res: any) => {
            const entityData: EntityData[] = res.EntityDataArray.EntityData;
            return this.formDataService.convertTimeLineData(entityData, timelineConfig, timelineFilter, timelineResourceData);
          }
        )
      );
    }
  }

  getTimeLineGroupListData(axisData: AxisData, entityHandle: number = null): Observable<TimelineCategory[]> {
    const baseFilters: BaseFilters[] = [];
    baseFilters.push({FilterConditions: null, ClassName: axisData.groupBinding ? axisData.groupBinding : axisData.binding});

    const entityListData: EntityListRequest = {
      AttributeNames: ['id', axisData.groupAttribute],
      BaseFilter: baseFilters,
      Sortings: [],
      filterFile: axisData.filterFile,
      ActualEntityHandle: axisData.filterFile && entityHandle ? entityHandle : null,
      Start: 0,
      Limit: 0
    };

    return this.entityListService.getHandleAndEntityListData(entityListData).pipe(
      mergeMap(
        (res: any) => {
          const result: TimelineCategory[] = [];
          const entityData: EntityData[] = res.EntityDataArray.EntityData;

          entityData.forEach((entityDataX: EntityData) => {
            const id: EntityData = entityDataX.Attributes.Attribute.find(r => r.Name === 'id');
            const name: EntityData = entityDataX.Attributes.Attribute.find(r => r.Name === axisData.groupAttribute);
            result.push({id: entityDataX.Handle, text: name.Value, color: null});
          });

          return of(result);
        }
      )
    );
  }

  getTimeLineResources(view: View, entityHandle: number = null): Observable<TimeLineResourcesData> {
    const timelineConfig: Timeline = view.timeline;
    let attributeNames: string[] = this.formDataService.getAttributeNamesAndRefs(view.timeline.yAxis.attributes);


    let groupObs: Observable<any>;


    if (timelineConfig.yAxis.groupAttribute) {
      attributeNames.push(timelineConfig.yAxis.groupAttribute);
      groupObs = this.getTimeLineGroupListData(timelineConfig.yAxisGroup, entityHandle);
    } else {
      groupObs = of([1]);
    }

    return groupObs.pipe(
      mergeMap(
        (timelineGroups: TimelineCategory[]) => {
          return this.getTimelineCategories(entityHandle, view, attributeNames, timelineGroups, timelineConfig);
        }
      )
    );
  }

  getTimelineCategories(entityHandle: number,
                        view: View,
                        attributeNames: string[],
                        timelineGroups: TimelineCategory[],
                        timelineConfig: Timeline): Observable<any> {
    const binding: string = view.timeline.yAxis.binding;
    const filterFile: string = view.timeline.yAxis.filterFile;
    let timeLineResourcesData: TimeLineResourcesData;
    const baseFilter: BaseFilters[] = [{FilterConditions: null, ClassName: view.timeline.yAxis.binding}];

    const entityListRequest: EntityListRequest = {
      AttributeNames: attributeNames,
      BaseFilter: baseFilter,
      Sortings: [],
      filterFile,
      ActualEntityHandle: filterFile && entityHandle ? entityHandle : null,
      Limit: 0,
      Start: 0
    };

    return this.entityListService.getHandleAndEntityListData(entityListRequest).pipe(
      mergeMap(
        (res: any) => {
          if (!res.EntityDataArray.EntityData) {
            throw new Error('Timeline Resource Data is empty');
          }
          const entityData: EntityData[] = res.EntityDataArray.EntityData;
          const result: TimelineResource[] = [];

          let i = 1;
          entityData.forEach((entityDataX: EntityData) => {
            const id: EntityData = entityDataX.Attributes.Attribute.find(r => r.Name.toLowerCase() === 'id');
            const textArr: string[] = [];
            view.timeline.yAxis.attributes.forEach(
              (attributeName: string) => {
                const entityAxis: EntityData = entityDataX.Attributes.Attribute.find(r => r.Name === attributeName);
                textArr.push(entityAxis.Value);
              }
            );


            let timelineCategory: TimelineCategory;

            if (timelineConfig.yAxis.groupAttribute) {
              const groupEntityData: EntityData = entityDataX.Attributes.Attribute.find(r => r.Name === timelineConfig.yAxis.groupAttribute);
              timelineCategory = timelineGroups.find(r => r.id === parseInt(groupEntityData.Value, 10));
            }


            result.push({
              handle: entityDataX.Handle,
              id: entityDataX.Handle,
              text: textArr,
              color: '',
              groupId: timelineCategory ? timelineCategory.id : null

            });
            i++;
          });

          const timelineCategoryMatrix: TimelineCategory[] = this.createTimeLineCategoryMatrix(timelineGroups, result);

          timeLineResourcesData = {
            timelineResources: result,
            timelineCategory: timelineGroups,
            timelineResourceRawData: entityData,
            timelineCategoryMatrix
          };

          // if (timelineConfig.yAxis.groupAttribute && timelineConfig.yAxis.groupAttribute !== '') {
          //   const attributeNames: string[] = this.getRefAttributeNames(timelineConfig.yAxis.groupAttribute);
          //   // return this.getTimelineGroups(attributeNames, timelineConfig).pipe(mergeMap((entityRes: any) => {
          //   //   // const entityData: EntityData[] = entityRes.EntityDataArray.EntityData;
          //   //   // // timeLineResourcesData.timelineCategory = this.createTimeLineCategoriesFromEntityListData(entityData, attributeNames);
          //   //   return of(timelineCategories);
          //   // }));
          //
          // }

          return of(timeLineResourcesData);
        }
      )
    );

  }

  createTimeLineCategoryMatrix(timeLineGroups: TimelineCategory[], timeLineResources: TimelineResource[]) {
    const result: TimelineCategory[] = [];

    timeLineGroups.forEach(
      (timeLineCategory: TimelineCategory) => {
        const resultTimeLineResources: TimelineResource[] = timeLineResources.filter(r => r.groupId === timeLineCategory.id);

        if (resultTimeLineResources.length > 0) {
          result.push({id: timeLineCategory.id, color: null, text: timeLineCategory.text});
          resultTimeLineResources.forEach(
            (timeLineResource: TimelineResource) => {
              result.push({id: timeLineResource.id, color: null, text: timeLineResource.text.join(' ')});
            }
          );
        }
      }
    );

    return result;
  }

  checkSoap(): Observable<any> {
    return this.sessionService.soapHealthCheck().pipe(
      mergeMap(
        (res: boolean) => {
          if (!res) {
            window.alert('wsdl is not reachable');
          }

          return of(res);
        }), catchError(error => {
          return error;
        }
      )
    );
  }

  getTimelineGroups(attributeNames: string[], timelineConfig: Timeline): Observable<any> {
    const baseFilter: BaseFilters[] = [{FilterConditions: null, ClassName: timelineConfig.yAxis.binding}];
    const entityListRequest: EntityListRequest = {
      AttributeNames: attributeNames,
      BaseFilter: baseFilter,
      Sortings: [],
      filterFile: timelineConfig.yAxis.filterFile,
      Start: 0,
      Limit: 0
    };

    return this.entityListService.getHandleAndEntityListData(entityListRequest);
  }

  getRefAttributeNames(attributeName: string): string[] {
    const result: string[] = [];
    const nameArr: string[] = attributeName.split('.');

    if (nameArr.length > 1) {
      result.push(nameArr[0]);
      result.push(nameArr[0] + '.id');
      if (result[1] !== 'id') {
        result.push(nameArr[0] + '.' + nameArr[1]);
      }
    }

    return result;
  }

  createTimeLineCategoriesFromEntityListData(entityData: EntityData[], categoryNames: string[]): TimelineCategory[] {
    const timelineCategories: TimelineCategory[] = [];

    entityData.forEach((entityDataDataRow: EntityData) => {
      const handle: number = parseInt(entityDataDataRow.Attributes.Attribute.find(r => r.Name === categoryNames[0]).Value, 10);
      const id: string = entityDataDataRow.Attributes.Attribute.find(r => r.Name === categoryNames[1]).Value;
      const categoryName: string = entityDataDataRow.Attributes.Attribute.find(r => r.Name === categoryNames[2]).Value;
      timelineCategories.push({id: handle, text: categoryName, color: ''});
    });

    return timelineCategories;
  }


  // getEntityDescriptorsObs(attributeClassFields: AttributeClassField[]): Observable<AttributeDescriptor> {
  //   // return this.formDataService.getEntityDescriptorsRequest(attributeClassFields).pipe(mergeMap((res: EntityDescriptor) => {
  //   //
  //   // }));
  //
  // }

  getTabChild(tabData: View, workflow: Workflow, urlSegment: UrlSegment[]): Children {
    let tabChild: Children;

    if (tabData) {
      if (urlSegment[0].path === 'step') {
        tabChild = tabData.children.find(r => r.path === workflow.name + ':' + urlSegment[1].path);
      } else {
        tabChild = tabData.children.find(r => r.path === urlSegment[1].path);
      }

      //
      // this.referenceInfos = this.child.referenceInfos;

      if (!tabChild) {
        throw new Error('TabChild is undefined');
      }

      return tabChild;
    }

    return null;
  }

  getTopOrViewBar(endpoint: string, barType: BarTypeEnum) {
    let view: View;

    const workflowViewUrl: WorkflowView = this.formDataService.getWorkFlowAndViewFromEndpoint(endpoint);
    view = workflowViewUrl.view;

    return {
      buttonActivators: this.formDataService.getAllButtonBarButtons(view, barType)
    };
  }

  getSingleDetailView(endpoint: string, handle: number, entityId: string, defaultActivator: Activator = null): Observable<any> {
    const workflowView: WorkflowView = this.formDataService.getWorkFlowAndViewFromEndpoint(endpoint);
    const form = {};
    if (defaultActivator && defaultActivator.action.attributeHandover) {

      form[defaultActivator.action.attributeHandover.target] = new FormControl(handle);
    }

    const observers: Observable<any>[] = [];
    const inputFormData: InputFormData = {
      workflow: workflowView.workflow,
      view: workflowView.view,
      topBarElements: {buttonActivators: [], buttonBarTitle: []},
      viewBarElements: {buttonActivators: []}
    };
    const inputData: InputData = {};

    observers.push(this.getEntityDescriptors(
      workflowView.workflow,
      workflowView.view, null, null).pipe(map((res: any) => {
      inputFormData.entityDescriptors = res;
      inputFormData.refEntities = this.formDataService.resolveRefEntities(inputFormData.entityDescriptors);
      this.formDataService.getColumnsInView(workflowView.view, inputFormData);
      inputData.formGroup = this.formDataService.createForm(
        this.formDataService.getColumns(workflowView.view.binding, workflowView.view));
      inputData.entityId = entityId;
      return {inputData, inputFormData, workflowView};
    })));

    if (defaultActivator.action.mode === 'createandassignentity') {
      observers.push(of([1]).pipe(mergeMap((res: any) => {
        return this.dataService.createEntity(new FormGroup(form),
          inputFormData.entityDescriptors, workflowView.view.binding).pipe(mergeMap((handleRes: number) => {
          inputData.entityHandle = handleRes;
          inputFormData.viewBarElements.buttonActivators = this.formDataService.getAllButtonBarButtons(workflowView.view, BarTypeEnum.VIEWBAR);
          return this.dataService.getEntityDataAndUpdateForm(inputFormData, inputData).pipe(map((res: any) => {

            return {inputData, inputFormData, workflowView};
          }));
        }));
      })));
    } else {
      observers.push(this.entityService.synchronizeEntity(handle.toString()).pipe(mergeMap((syncResult: any) => {
        inputData.entityHandle = handle;
        inputFormData.topBarElements.buttonBarTitle = this.formDataService.getAllTopBarTitles(workflowView.view, inputFormData.topBarElements.buttonBarTitle);
        inputFormData.topBarElements.buttonActivators = this.formDataService.getAllButtonBarButtons(workflowView.view, BarTypeEnum.TOPBAR);
        inputFormData.viewBarElements.buttonActivators = this.formDataService.getAllButtonBarButtons(workflowView.view, BarTypeEnum.VIEWBAR);
        return this.dataService.getEntityDataAndUpdateForm(inputFormData, inputData).pipe(map((res: any) => {

          return {inputData, inputFormData, workflowView};
        }));
      }), catchError(error => {
        console.log(error);
        return error;
      })));
    }

    return from(observers).pipe(concatMap((obs: Observable<any>) => {
      return obs;
    }));
  }
}
