import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResponsivenessService {
  currentWindowWidth: number = window.innerWidth;

  currentView: ResponsiveView = this.getResponsiveViewFromWindowWidth();

  viewChangedEmitter: EventEmitter<ResponsiveView> = new EventEmitter();

  constructor() {
  }

  onResize(width: number) {
    if (this.currentView === this.getResponsiveViewFromWindowWidth(width)) {
      return;
    }
    this.currentWindowWidth = width;
    this.currentView = this.getResponsiveViewFromWindowWidth();

    this.viewChangedEmitter.emit(this.currentView);
  }

  isMobileView(): boolean {
    return ResponsiveView.mobile === this.currentView;
  }

  isTabletOrMobileView(): boolean {
    return (ResponsiveView.tablet === this.currentView || ResponsiveView.mobile === this.currentView);
  }

  isTabletView(): boolean {
    return ResponsiveView.tablet === this.currentView;
  }

  isDesktopView(): boolean {
    return ResponsiveView.desktop === this.currentView;
  }

  private getResponsiveViewFromWindowWidth(currentWidth: number = this.currentWindowWidth): ResponsiveView {
    if (currentWidth <= 767) {
      return ResponsiveView.mobile;
    } else if (currentWidth <= 991) {
      return ResponsiveView.tablet;
    } else {
      return ResponsiveView.desktop;
    }
  }
}

export enum ResponsiveView {
  'mobile',
  'tablet',
  'desktop'
}
