import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {TranslationService} from '../../shared/services/translation.service';



@Component({
  selector: 'app-two-factor-modal',
  templateUrl: './two-factor-modal.component.html',
  styleUrls: ['./two-factor-modal.component.css']
})
export class TwoFactorModalComponent implements OnInit {

  code: string;

  constructor(public dialogRef: MatDialogRef<TwoFactorModalComponent>,
              public translationService: TranslationService) {
  }

  ngOnInit(): void {
  }

  sendCode(): void {
    this.dialogRef.close(this.code);
  }

  cancel2Fa(): void {
    this.dialogRef.close();
  }

  onEnter($event: any): void {
    if ($event.keyCode === 13) {
      this.sendCode();
    }
  }
}
