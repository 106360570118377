<div
  [ngClass]="{'toast-header-close-icon': true,
              'toast-error': message.type === messageTypeEnum.ERROR,
              'toast-notice': message.type === messageTypeEnum.NOTICE,
              'toast-success': message.type === messageTypeEnum.SUCCESS
              }"
  id="toast_Clone">
  <div [ngClass]="{'toast-progress': true}"
       [ngStyle]="{'animation': 'reSizeProgressBar linear ' + timeout + 'ms'}"></div>
  <div class="toast-message">
    <div class="toast-title">{{message.title}}</div>
    <div class="toast-content">{{ message.text }}</div>
  </div>
  <div class="toast-close-icon icons " onclick="Close(event)"></div>
</div>
