import {Component, OnInit} from '@angular/core';
import {ConfigService} from '../../services/config.service';
import {Settings} from '../../../pit/structure';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  settings: Settings;
  constructor(private configService: ConfigService) { }

  ngOnInit(): void {
    this.settings = this.configService.getSettings();
  }

}
