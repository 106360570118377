<!--<div *ngIf=-->
<!--<app-message-dialog *ngIf="(settings && settings.userMessagesType === userMessagesTypeEnum.DIALOG) && messageStackService.messages.length > 0"></app-message-dialog>-->
<app-message-toast *ngIf="messageStackService.messages.length > 0"></app-message-toast>

<router-outlet></router-outlet>

<button id="btn-no-session-dialog" (click)="open(sessionModal)" data-toggle="modal"
        data-target="#no-session" style="display: none;"></button>

<button id="btn-release-notes-dialog" (click)="onOpenDialog('release')" style="display: none;"></button>

<div #sessionModal class="modal fade" id="no-session" tabindex="-1" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="Katalog">Ihre Sitzung ist abgelaufen!</h5>
        <button id="btn-session-modal-close" type="button" class="btn btn-close-modal" data-dismiss="modal"
                aria-label="Schließen">
          <i class="ti ti-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <button (click)="navigateToLogin()" type="button" class="btn btn-outline-secondary">Neu einloggen</button>
      </div>
    </div>
  </div>
</div>

<div class="status-bar">
  <app-loader></app-loader>
</div>


<div class="status-bar-backdrop" id="statusBackdrop"></div>

<div [ngClass]="{'offline-backdrop': true, 'show': routingProviderService.isOffline || this.routingProviderService.isSoapOffline}" id="offlineBackDrop">

  <h1>Keine Verbindung zu Pit FM</h1>
  <button (click)="checkConnectivity()" type="button" class="btn btn-danger" >erneut versuchen</button>
  <span class="e-icons e-formProperties"
        id="reportBadge"
        (click)="onOpenDialog('message')"
        style="padding-right: 30px; padding-top: 12px;">
    <div style="position: relative; display: inline-block; margin: 0px 0px 16px 13px;">
      <span id="counterBadge" class="e-badge e-badge-dark e-badge-overlap e-badge-notification">0/0</span>
    </div>
  </span>

</div>


