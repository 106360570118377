import {
  Action,
  Activator,
  Children,
  Column,
  FilterBar,
  FilterFile,
  Handover, HeaderColumn, MatrixData,
  Menu,
  NavButtonEnum,
  TimelineResult,
  View,
  Workflow
} from './structure';
import {EntityDescriptor} from '../shared/services/swagger/models/entity-descriptor';
import {EntityData} from '../shared/services/swagger/models/entity-data';
import {FormGroup} from '@angular/forms';
import {Params, UrlSegment} from '@angular/router';
import {DashboardFilter} from '../shared/models/DashboardFilter';
import {ViewBarElements} from './resolver/view-bar-resolver.service';
import {TopBarElements} from './resolver/title-entity-descriptor-resolver.service';
import {ViewChild} from '@angular/core';
import {ColumnsModel} from '@syncfusion/ej2-angular-kanban';
import {
  TimelineViewsService,
  AgendaService,
  GroupModel,
  EventSettingsModel,
  ResizeService,
  DragAndDropService,
  ScheduleComponent, ActionEventArgs, CellClickEventArgs, View as SchedulerView
} from '@syncfusion/ej2-angular-schedule';
import {FilterParameter} from '../shared/services/swagger/models/filter-parameter';
import {DataRow} from "./views/datagrid/structure";


export interface InputFormData {
  workflow?: Workflow;
  view?: View;
  tab?: View;
  tabChild?: Children;
  tabView?: View;
  tabData?: View;
  entityDescriptors?: EntityDescriptor[];
  refEntities?: EntityDescriptor[];
  topBarElements?: TopBarElements;
  viewBarElements?: ViewBarElements;
  step?: Children;
  sideBarData?: Action;
  quickMenu?: boolean;
  quickMenuData?: InputFormData;
  isFocusIn?: boolean;
  refresh?: boolean;
  stepNumber?: number;
  showSearchBar?: boolean;
  userLocale?: string;
  queryParams?: Params;
  filterParameters?: FilterParameter[];
  mouseInfo?: MouseInfo;
  isFilterBar?: boolean;
  dashboardFilter?: DashboardFilter;
  isControlFocused?: boolean;
  controlFocusedName?: string;
  isCommitHovered?: boolean;
  isSubmitClicked?: boolean;
}

export interface MouseInfo {
  clientX: number;
  clientY: number;
}

export interface NavButton {
  name: NavButtonEnum;
  activators: Activator[];
}

export interface Scheduler {
  timeAttribute?: string;
  ressourceAttribute?: string;
  popup: Popup;
}

export interface Gantt {
  timeAttribute?: string;
  ressourceAttribute?: string;
  popup: Popup;
}

export interface Popup {
  attributes: Column[];
}


export enum BarTypeEnum {
  TOPBAR = 'topbar',
  VIEWBAR = 'viewbar'
}

export interface InputData {
  entityId?: string;
  entityHandle?: number;
  entityData?: EntityData;
  titleEntityData?: EntityData,
  formGroup?: FormGroup;
  recurrenceValue?: string;
  svgData?: SvgData;
  handovers?: Handover[];
  isRollBack?: boolean;
  selectedEntityId?: string;
  locale?: string;
  entityIdData?: string[];
  timelineData?: TimelineResult;
  matrixData?: MatrixData;
  eventObj?: any;
  filterParameters?: FilterParameter[];
}

export interface TimelineFilter {
  start: Date;
  end: Date;
  selectedDate: Date;

  viewMode: SchedulerView;
}

export interface SvgData {
  position?: string;
  id?: string;
}

export interface Position {
  X: number;
  Y: number;

}

export interface InputNavigationData {
  urlArr?: UrlSegment[];
  url?: string;
  queryParams?: Params;
  parentListView?: View;
  menu?: Menu;
}

export interface InputDashboardData {
  entityCount: number;
  entityData?: EntityData[];
  entityHandle?: number;
  activePage?: number;
  pageLabelFrom?: number;
  pageLabelTill?: number;
  pageCount?: number;
  pageView?: number[];
  dashboardFilter: DashboardFilter;
  currentDashboardFilter?: DashboardFilter;
  dashboardSettings: DashboardSettings;
  isExtendedFilterBar?: boolean;
  listFilterFormGroup?: FormGroup;
  dataGridToolbarForm?: FormGroup;
  filterFormGroup?: FormGroup;
  listExtendedFilterFormGroup?: FormGroup;
  extendedFilterFormGroup?: FormGroup;
  extendedFilterColumns?: Column[];
  jsonData?: DataRow[];
  jsonDataHeader?: any[];
  cache?: boolean[];
  keyword?: string;
  refFilterHandles?: KeyValue[];
  startIndexDoneArray?: boolean[];
  startValue?: number;
  endValue?: number;
  selected?: any[];
  checkboxMode?: boolean;
  actionButtons?: Activator[];
  selectedFilterFile?: string;
  isTreeView?: boolean;
  initDashboardFilter?: Params;
  ganttData?: EntityData;
  filters?: FilterFile[];
  headerColumns?: HeaderColumn[];
}

export interface InputContextTreeData {
  entityData?: EntityData[];
  entityDataRes?: any;
  selectedNodes: any[];
  jsonData?: any[];
  selectedNode?: EntityData;
}


export interface DashboardSettings {
  entriesPerPage: any[];
  showPages?: boolean;
  showEntriesPerPageSelectBox: boolean;
  showEntriesLabel: boolean;
}

export interface KeyValue {
  key: string;
  value: any;
}

