import {Injectable} from '@angular/core';
import {Column, FilterFile, View, WorkflowView} from '../../pit/structure';
import {DashboardFilter} from '../models/DashboardFilter';
import {EMPTY, Observable} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {GetDataByAttributeRequest} from './swagger/models/get-data-by-attribute-request';
import {EntityService} from './swagger/services/entity.service';
import {EntitylistService} from './swagger/services/entitylist.service';
import {NavigationService} from './navigation.service';
import {ConfigService} from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ListService {

  constructor(
    private entityService: EntityService,
    private entityListService: EntitylistService
  ) {
  }

  getListDataByAttribute(listAttribute: string, entityHandle: number, view: View, dashboardFilter: DashboardFilter, filterFile: FilterFile = null): Observable<any> {
    const columns: Column[] = view.columns ? view.columns : [{attribute: 'id'}];
    const attributes: string[] = [];

    if (view.tabsTitle && view.tabsTitle !== '') {
      columns.push({attribute: view.tabsTitle});
    }

    columns.forEach(column => {
      attributes.push(column.attribute);

      if (column.useDisplayColor && column.colorAttribute) {
        attributes.push(column.colorAttribute);
      }
    });

    return this.entityService.synchronizeEntity(entityHandle.toString()).pipe(mergeMap((res: any) => {
      const getDataByAttribute: GetDataByAttributeRequest = {
        AttributeNames: attributes,
        ListAttribute: listAttribute,
        Handle: entityHandle,
        Start: dashboardFilter ? (dashboardFilter.page - 1) * dashboardFilter.limit : 0,
        Limit: dashboardFilter ? dashboardFilter.limit : 0,
        filterFile: filterFile ? filterFile.file : null,
        ActualEntityHandle: entityHandle,
        RestrictionCLassName: view.restrictionClassName ? view.restrictionClassName : null
      };

      return this.entityListService.getDataByAttribute(getDataByAttribute).pipe(
        catchError(error => {
          console.log(error);
          return error;
        }), map((dashboardData) => {
            return dashboardData ? dashboardData : EMPTY;
          },
        ));

    }));

  }


}
