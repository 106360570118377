import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LanguagesElement} from '../../../pit/structure';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.css']
})
export class LanguageSwitcherComponent implements OnInit {


  constructor(
    public translateService: TranslateService,
    private httpClient: HttpClient,
    public authService: AuthService
  ) {

  }


  ngOnInit(): void {

  }

  switchLanguage(language: LanguagesElement): void {
    this.authService.setDefaultLang(language, true);

  }
}
