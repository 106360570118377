import {Injectable} from '@angular/core';
import {ConfigService} from '../../shared/services/config.service';
import {FormDataService} from '../../shared/services/form-data.service';
import {RoutingProviderService} from '../services/routing-provider.service';
import {ActivatedRouteSnapshot, Params, RouterStateSnapshot} from '@angular/router';

import {EMPTY, from, Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../shared/services/auth.service';
import {UserService} from '../../shared/services/swagger/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class QueryParamsResolverService {


  constructor(private configService: ConfigService,
              private formDataService: FormDataService,
              private httpClient: HttpClient,
              private userService: UserService,
              private routingProviderService: RoutingProviderService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let params: Params = Object.keys(route.queryParams).length > 0 ? route.queryParams : null;


    const base64Decodes: string = params && params['t'] ? atob(params['t']) : null;

    if (base64Decodes) {
      const searchParams: URLSearchParams = new URLSearchParams(base64Decodes);
      params = {};
      searchParams.forEach((value: string, key: string) => {
        params[key] = value;
      });
    }

    if (params) {
      this.routingProviderService.setInitQueryParams(params);
    }

    return of(params);
  }

}
