import {Inject, Injectable} from '@angular/core';
import {from, of, Observable} from 'rxjs';
import {MessageBox, PitException, QueryButtonEnum, QueryIconEnum} from '../../pit/structure';
import {SessionService} from './swagger/services/session.service';
import {AnswerCallBackQueryRequest} from './swagger/models/answer-call-back-query-request';
import {catchError, mergeMap} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {SettingsComponent} from '../components/dialogs/settings/settings.component';
import {MessageBoxComponent} from '../components/dialogs/message-box/message-box.component';
import {RoutingProviderService} from "../../pit/services/routing-provider.service";
import {Router} from "@angular/router";


@Injectable({
  providedIn: 'root'
})
export class CallbackService {

  initialRequest: Observable<any>;

  currentMessageBox: MessageBox;


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private sessionService: SessionService,
    private httpClient: HttpClient,
    public dialog: MatDialog,
    private routingProviderService: RoutingProviderService,
    private router: Router) {
  }

  isBitSet(bit: number, mask: number): boolean {
    return (bit & mask) !== 0;
  }

  checkButtonType(buttonsAndIconsType: number): QueryButtonEnum {


    if (buttonsAndIconsType === 0) {
      return QueryButtonEnum.OK;
    }

    if (this.isBitSet(QueryButtonEnum.OK_CANCEL, buttonsAndIconsType)) {
      return QueryButtonEnum.OK_CANCEL;
    }

    if (this.isBitSet(QueryButtonEnum.ABORT_RETRY_IGNORE, buttonsAndIconsType)) {
      return QueryButtonEnum.ABORT_RETRY_IGNORE;
    }

    if (this.isBitSet(QueryButtonEnum.YES_NO_CANCEL, buttonsAndIconsType)) {
      return QueryButtonEnum.YES_NO_CANCEL;
    }

    if (this.isBitSet(QueryButtonEnum.YES_NO, buttonsAndIconsType)) {
      return QueryButtonEnum.YES_NO;
    }

    if (this.isBitSet(QueryButtonEnum.YES_NO, buttonsAndIconsType)) {
      return QueryButtonEnum.RETRY_CANCEL;
    }
  }

  checkIconType(buttonsAndIconsType: number): QueryIconEnum {
    if (this.isBitSet(QueryIconEnum.ICON_ERROR, buttonsAndIconsType)) {
      return QueryIconEnum.ICON_ERROR;
    }

    if (this.isBitSet(QueryIconEnum.ICON_QUESTION, buttonsAndIconsType)) {
      return QueryIconEnum.ICON_QUESTION;
    }

    if (this.isBitSet(QueryIconEnum.ICON_WARNING, buttonsAndIconsType)) {
      return QueryIconEnum.ICON_WARNING;
    }

    if (this.isBitSet(QueryIconEnum.ICON_INFORMATION, buttonsAndIconsType)) {
      return QueryIconEnum.ICON_INFORMATION;
    }
  }

  setInitialRequest(initialRequest: Observable<any>): void {
    this.initialRequest = initialRequest;
  }

  setMessageBox(message: string, buttonsAndIconsType: number, type): void {
    this.currentMessageBox = {
      title: '',
      message,
      type,
      queryButtonEnum: this.checkButtonType(buttonsAndIconsType),
      queryIconEnum: this.checkIconType(buttonsAndIconsType)
    };
  }

  answerCallBackQuery(result: number): Observable<any> {
    const answerCallBackQuery: AnswerCallBackQueryRequest = {Result: result, Type: 2};
    return this.sessionService.answerCallBackQuery(answerCallBackQuery).pipe(catchError((error: PitException) => {
      this.setMessageBox(error.error.errorDetail.message, error.error.errorDetail.buttonsAndIconsType, error.error.type);
      return null;
    }), mergeMap((res: any) => {
      this.routingProviderService.isDisabledLoaderDarkening = false;
      return this.initialRequest.pipe(mergeMap((res: any) => {
        if (res.urlArr) {
          this.router.navigate(res.urlArr, res.dashboardFilter ?
            {queryParams: res.dashboardFilter} : {}).then(() => {
            this.routingProviderService.lastEntityId = null;
          });

        }
        return res;
      }));


    }));
  }


  openCallBackDialog(): void {
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      width: '800px',
      disableClose: true,
      data: {}
    });
  }
}
