import {BrowserModule} from '@angular/platform-browser';
import {forwardRef, LOCALE_ID, NgModule, Provider} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {ApiInterceptorService} from './shared/services/api-interceptor.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {LoaderComponent} from './shared/components/loader/loader.component';
import {LoaderService} from './shared/services/loader.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NavigationService} from './shared/services/navigation.service';
import {MatDialogModule} from '@angular/material/dialog';
import {NavComponent} from './login/login-nav/nav.component';
import {FooterComponent} from './shared/components/footer/footer.component';
import {RoutingProviderService} from './pit/services/routing-provider.service';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AuthService} from './shared/services/auth.service';
import {DateTimeAdapter, OWL_DATE_TIME_LOCALE} from 'ng-pick-datetime';
import {MessageStackService} from './shared/services/message-stack.service';


import localeDe from '@angular/common/locales/de';
import localeUs from '@angular/common/locales/en-US-POSIX';

import {registerLocaleData} from '@angular/common';
import {CallbackService} from './shared/services/callback.service';
import {DialogModule} from '@syncfusion/ej2-angular-popups';
import {ProgressBarModule} from '@syncfusion/ej2-angular-progressbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {SettingsComponent} from './shared/components/dialogs/settings/settings.component';
import {LanguageSwitcherComponent} from './shared/components/language-switcher/language-switcher.component';
import {MatMenuModule} from '@angular/material/menu';
import {MessageDialogComponent} from './shared/components/message-dialog/message-dialog.component';
import {MessageToastComponent} from './shared/components/message-toast/message-toast.component';
import {ToastComponent} from './shared/components/message-toast/single-toast/toast.component';
import { TwoFactorModalComponent } from './login/two-factor-modal/two-factor-modal.component';



registerLocaleData(localeDe);
registerLocaleData(localeUs);


export const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => ApiInterceptorService),
  multi: true
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoaderComponent,
    NavComponent,
    FooterComponent,
    LanguageSwitcherComponent,
    MessageDialogComponent,
    MessageToastComponent,
    ToastComponent,
    TwoFactorModalComponent
  ],
  imports: [
    ProgressBarModule,
    BrowserModule,
    AppRoutingModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatInputModule,
    HttpClientModule,
    TranslateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    FormsModule,
    DialogModule,
    MatSidenavModule,
    MatMenuModule
  ],
  providers: [
    AuthService,
    HttpClientModule,
    MatSnackBarModule,
    API_INTERCEPTOR_PROVIDER,
    LoaderService,
    NavigationService,
    MatDialogModule,
    RoutingProviderService,
    CallbackService,
    MessageStackService,
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'de-DE'},
    {provide: LOCALE_ID, useValue: 'de'}

  ],
  exports: [
  ],
  entryComponents: [SettingsComponent, TwoFactorModalComponent, SettingsComponent],

  bootstrap: [AppComponent]
})
export class AppModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
