import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, of, from, config} from 'rxjs';
import {InputNavigationData} from '../input-data';
import {ConfigService} from '../../shared/services/config.service';
import {FileManager, renderFileManager} from '@syncfusion/ej2-angular-richtexteditor';
import {catchError, finalize, map, mergeMap} from 'rxjs/operators';
import {ConfigEntry, FileMapping, FileMappingEnum, Languages, Settings} from '../structure';
import {AuthService} from '../../shared/services/auth.service';
import {RoutingProviderService} from "../services/routing-provider.service";
import {ResolverService} from "../../shared/services/resolver.service";

@Injectable({
  providedIn: 'root'
})
export class ConfigFilesResolverService {

  constructor(private configService: ConfigService,
              private authService: AuthService,
              private routingProviderService: RoutingProviderService,
              private resolverService: ResolverService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // localStorage.removeItem('sessionId');
    // localStorage.removeItem('user');
    // localStorage.removeItem('countryCode');

    const configFiles: ConfigEntry[] = [];
    const time: Date = new Date();
    // window.location.href +
    const configFolder =  'assets/config/';
    return this.configService.loadConfigFiles(this.configService.fileMap).pipe(mergeMap((res: any) => {
      const fileMapping: FileMapping = res.config;
      this.configService.setFileMapping(fileMapping);
      configFiles.push({
        config: null,
        name: 'config',
        path: configFolder + fileMapping.config,
        observer: this.configService.getFileObserve(configFolder + fileMapping.config + '?time=' + time.getTime())
      });
      configFiles.push({
        config: null,
        name: 'settings',
        path: configFolder + fileMapping.settings,
        observer: this.configService.getFileObserve(configFolder + fileMapping.settings + '?time=' + time.getTime())
      });
      configFiles.push({
        config: null,
        name: 'connection-settings',
        path: 'assets/connection-settings.json',
        observer: this.configService.getFileObserve('assets/connection-settings.json' + '?time=' + time.getTime())
      });
      configFiles.push({
        config: null,
        name: 'languages',
        path: configFolder + fileMapping.languages,
        observer: this.configService.getFileObserve(configFolder + fileMapping.languages + '?time=' + time.getTime()).pipe(map((result: Languages) => {
          this.authService.languagesConfig = result;
          if (!localStorage.getItem('defaultLang')) {
            this.authService.defaultLang = result.languages.find(r => r.default === true);

            if (!this.authService.defaultLang) {
              this.authService.defaultLang = result.languages[0];
            }
          } else {
            this.authService.defaultLang = result.languages.find(r => r.countryCode === JSON.parse(localStorage.getItem('defaultLang')).countryCode.toUpperCase());
          }

          localStorage.setItem('defaultLang', JSON.stringify(this.authService.defaultLang));
          this.authService.setDefaultLang(this.authService.defaultLang);
        }))
      });
      configFiles.push({
        config: null,
        name: 'releaseNotes',
        path: configFolder + fileMapping.releaseNotes,
        observer: this.configService.getFileObserve(configFolder + 'release_notes.json')
      });
      configFiles.push({
        config: null,
        name: 'supportedFiles',
        path: configFolder + fileMapping.supportedFiles,
        observer: this.configService.getFileObserve(configFolder + fileMapping.supportedFiles)
      });

      localStorage.setItem('i18nFolder', fileMapping.i18nFolder);

      return this.configService.loadConfigFiles(configFiles).pipe(mergeMap((result: any) => {
        configFiles[result.index].config = result.config;
        if (configFiles[result.index].name === FileMappingEnum.CONFIG) {
          this.configService.setOriginalConfig(configFiles[result.index].config);
        }

        this.setConfigurationToStorage(configFiles[result.index]);
        return of(result);
      }), catchError(error => {
        console.log(error);
        return error;
      }), finalize(() => {
        this.resolverService.checkSoap().pipe(map((res: boolean) => {
          this.routingProviderService.isSoapOffline = !res;
        })).subscribe(() => {});
      }));
    }));
  }

  setConfigurationToStorage(configEntry: ConfigEntry): void {
    localStorage.setItem(configEntry.name, JSON.stringify(configEntry.config));
  }

}
