import {Component, Input, OnInit} from '@angular/core';
import {MessageStackService} from '../../../services/message-stack.service';
import {Message, MessageTypeEnum} from '../../../../pit/structure';

@Component({
  selector: 'app-single-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent implements OnInit {

  constructor(private messageStackService: MessageStackService) { }

  @Input() message: Message;

  @Input() timeout: number;

  messageTypeEnum = MessageTypeEnum;

  ngOnInit(): void {
    setTimeout(() => {
      this.messageStackService.moveUserMessageToHistory();
    }, this.timeout);
  }

}
