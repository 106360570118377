import {Injectable} from '@angular/core';
import {EntityDescriptor} from './swagger/models/entity-descriptor';
import {FormControl, FormGroup} from '@angular/forms';
import {EntityData} from './swagger/models/entity-data';
import {EntityDataFlag} from '../Enums/EntityDataFlag';
import {ControlType} from '../Enums/ControlType';
import {EntitylistService, EntityService, SessionService, UserService} from './swagger/services';
import {EMPTY, from, Observable, of} from 'rxjs';
import {
  Action,
  Activator,
  ActivatorTypeEnum,
  ButtonBar,
  ButtonBarTitle,
  ButtonBarTitleTypeEnum,
  ButtonFunction,
  CallFunctionParameter,
  Children,
  Column,
  Configuration,
  ControlTypeEnum,
  DefaultValues,
  EntityDescriptorFlagsEnum,
  FilterFile,
  Handover,
  HandoverTypeEnum,
  Menu,
  Message,
  MessageTypeEnum,
  PinDialog,
  PitException,
  Section,
  SortingEnum,
  Timeline,
  TimelineData,
  TimelineResource,
  TimeLineResourcesData,
  TimelineResult,
  View,
  ViewEnum,
  ViewList,
  Workflow,
  WorkflowView,
} from '../../pit/structure';

import {
  CallFunctionParameter as CallFunctionParameterSwagger,
  CreateEntityDataRequest,
  DeleteEntityRequest,
  EntityCallFunctionContextResponse,
  EntityCallFunctionRequest,
  EntityDataFromHandleRequest,
  EntityDataRequest,
  EntityListRequest,
  FileResponse,
  HandleRequest,
  NamedValue, SetEntityDataRequest,
  Sortings
} from './swagger/models';

import {AttributeClassField} from './swagger/models/attribute-class-field';
import {ConfigService} from './config.service';
import {catchError, concatMap, finalize, map, mergeMap} from 'rxjs/operators';
import {AttributeDescriptor} from './swagger/models/attribute-descriptor';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import {
  BarTypeEnum,
  InputDashboardData,
  InputData,
  InputFormData,
  InputNavigationData,
  KeyValue,
  TimelineFilter
} from '../../pit/input-data';
import {NavigationElement, NavigationService} from './navigation.service';
import {DataService} from './data.service';
import {RoutingProviderService} from '../../pit/services/routing-provider.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PermissionEnum, PermissionService} from './permission.service';
import {CallbackService} from './callback.service';
import {EntityCallFunctionByHandleResponse} from './swagger/models/entity-call-function-by-handle-response';
import {MessageStackService} from 'src/app/shared/services/message-stack.service';
import {AssignEntityRequest} from './swagger/models/assign-entity-request';
import {DownloadFileRequest} from './swagger/models/download-file-request';
import {TopBarElements} from '../../pit/resolver/title-entity-descriptor-resolver.service';
import {ViewBarElements} from '../../pit/resolver/view-bar-resolver.service';
import {MenuItemModel} from '@syncfusion/ej2-angular-navigations';
import {MatDialog} from '@angular/material/dialog';
import {HelpTextComponent} from '../components/dialogs/help-text/help-text.component';
import {ListService} from './list.service';
import {EntitySmartService} from './entity-smart.service';
import {EventSettingsModel} from '@syncfusion/ej2-angular-schedule';
import {extend} from '@syncfusion/ej2-base';
import {AuthService} from './auth.service';
import {TranslationService} from './translation.service';


@Injectable({
  providedIn: 'root'
})
export class FormDataService {
  configuration: Configuration;

  entityHandleId: number;

  entityDataEnum = EntityDataFlag;

  controlTypeEnum = ControlType;

  constructor(private entityService: EntityService,
              public configService: ConfigService,
              private sessionService: SessionService,
              private permissionService: PermissionService,
              private routingProviderService: RoutingProviderService,
              private snackbar: MatSnackBar,
              public messageStackService: MessageStackService,
              public dialog: MatDialog,
              private router: Router,
              private userService: UserService,
              private entityListService: EntitylistService,
              private listService: ListService,
              private entitySmartService: EntitySmartService,
              private callbackService: CallbackService,
              private translationService: TranslationService
  ) {
  }

  updateForm(entityData: EntityData, entityDescriptors: EntityDescriptor[], formGroup: FormGroup, locale: string): void {


    entityData.Attributes.Attribute.forEach((entity: EntityData) => {

      let date: string = '';
      if (formGroup.controls[entity.Name]) {
        const entityDescriptor: EntityDescriptor = entityDescriptors.find(r => r.Name === entity.Name);

        if (entityDescriptor && entity.Value && (entityDescriptor.DataType === 'Time' || entityDescriptor.DataType === 'Date')) {
          if (entity.Value['timestamp'] >= 0) {
            const dateTime = entity.Value;

            if (entityDescriptor.DataType === 'Date') {
              date = new Date(dateTime['timestamp'] * 1000).toLocaleDateString(locale, {timeZone: dateTime['timezone'].name});
            }

            if (entityDescriptor.DataType === 'Time') {
              const dateObj: Date = new Date((dateTime['timestamp']) * 1000);
              const timeAmPm: string[] = dateObj.toLocaleTimeString(locale, {timeZone: dateTime['timezone'].name}).split(' ');
              const time: string[] = dateObj.toLocaleTimeString(locale, {timeZone: dateTime['timezone'].name}).split(':');

              if (timeAmPm[1]) {
                date = dateObj.toLocaleDateString(locale, {timeZone: dateTime['timezone'].name}) + ' ' + time[0] + ':' + time[1] + ' ' + timeAmPm[1];
              } else {
                date = dateObj.toLocaleDateString(locale, {timeZone: dateTime['timezone'].name}) + ' ' + time[0] + ':' + time[1];
              }
            }

            formGroup.controls[entity.Name].setValue(date);
          } else {
            formGroup.controls[entity.Name].setValue(null);
          }
        } else {
          formGroup.controls[entity.Name].setValue(entity.Value);
        }
      } else {
        const nameArr = entity.Name.split('.');
        if (nameArr[1] === 'id') {
          formGroup.controls[nameArr[0]].setValue(entity.Value);
        }
      }
    });
  }

  setDateTimeLocale(dataType: string, locale: string, dateTime: any): string {
    let date: string = '';

    if (dataType === 'Date') {
      date = new Date(dateTime['timestamp'] * 1000).toLocaleDateString(locale, {timeZone: dateTime['timezone'].name});
    }

    if (dataType === 'Time') {
      const dateObj: Date = new Date((dateTime['timestamp']) * 1000);
      const timeAmPm: string[] = dateObj.toLocaleTimeString(locale, {timeZone: dateTime['timezone'].name}).split(' ');
      const time: string[] = dateObj.toLocaleTimeString(locale, {timeZone: dateTime['timezone'].name}).split(':');

      if (timeAmPm[1]) {
        date = dateObj.toLocaleDateString(locale, {timeZone: dateTime['timezone'].name}) + ' ' + time[0] + ':' + time[1] + ' ' + timeAmPm[1];
      } else {
        date = dateObj.toLocaleDateString(locale, {timeZone: dateTime['timezone'].name}) + ' ' + time[0] + ':' + time[1];
      }
    }

    return date;
  }

  createForm(columns: Column[]): FormGroup {
    const form = {};

    columns.forEach((column: Column) => {
      if (column.refEntity) {
        let attributeArr = column.attribute.split('.');
        attributeArr = attributeArr.splice(0, attributeArr.length - 1);
        const refAttributeName: string = attributeArr.join('.');
        form[refAttributeName] = new FormControl('');
      }
      form[column.attribute] = new FormControl('');
    });

    return (new FormGroup(form));
  }


  getAllFieldsFromForm(columns: Column[]): string[] {
    const attributeNames: string[] = [];

    columns.forEach(column => {
      if (column.attribute) {
        attributeNames.push(column.attribute);

        if (column.useDisplayColor && column.colorAttribute) {
          attributeNames.push(column.colorAttribute);
        }
      }

    });
    return attributeNames;
  }

  rollback(): Observable<any> {
    return this.sessionService.rollback();
  }

  getViewList(workFlowName: string): ViewList[] {
    this.configuration = this.configService.getConfiguration();
    const workflow: Workflow = this.configuration.workflows.find(r => r.name === workFlowName);
    return workflow.views;
  }


  getAttributeFields(className: string, view: View): AttributeClassField[] {
    let attributeClassFields: AttributeClassField[] = [];
    const refEntities: string[] = [];

    // Detail View
    if (view.sections) {
      const columns: Column[] = [];
      const sections = view.sections;
      sections.forEach((section: Section) => {
        section.rows.forEach((column: Column) => {
          columns.push(column);
        });
      });

      attributeClassFields = this.getAttributeFieldResult(view.restrictionClassName ? view.restrictionClassName : className, columns);
    }

    // List View
    if (view.columns && view.columns.length > 0) {
      attributeClassFields = this.getAttributeFieldResult(view.restrictionClassName ? view.restrictionClassName : className, view.columns);
    }

    if (attributeClassFields.length > 0 && view.topbar && view.topbar.title && view.topbar.title.length > 0) {
      view.topbar.title.forEach((title: ButtonBarTitle) => {
        if (title.type === ButtonBarTitleTypeEnum.ATTRIBUTE) {
          attributeClassFields.push({
            Attributename: title.text,
            Classname: view.restrictionClassName ? view.restrictionClassName : className
          });
        }
      });
    }

    return attributeClassFields;
  }

  getAttributeFieldResult(className: string, columns: Column[]): AttributeClassField[] {
    const attributeClassFields: AttributeClassField[] = [];
    const refEntities: string[] = [];

    columns.forEach((column: Column) => {
      if (column.attribute) {
        attributeClassFields.push({Attributename: column.attribute, Classname: className});
        const splitArr: string[] = column.attribute.split('.');

        if (splitArr.length > 1 && refEntities.indexOf(splitArr[0]) === -1) {
          // section.rows.push({attribute: splitArr[0],});
          refEntities.push(splitArr[0]);
          attributeClassFields.push({Attributename: splitArr[0], Classname: className});
        }

        if (splitArr.length > 2) {
          attributeClassFields[attributeClassFields.length - 1].refLevels = splitArr;
        }
      }
    });

    return attributeClassFields;
  }


  getColumns(className: string, view: View): Column[] {
    const columns: Column[] = [];


    if (view.sections) {
      let sections: Section[] = view.sections;

      const hiddenAttributes: Section[] = this.addSectionsForControlsWithAttributes(view);
      if (hiddenAttributes.length > 0) {
        sections = [...view.sections, ...hiddenAttributes];
      }


      sections.forEach((section: Section) => {
        section.rows.forEach((row: Column) => {
          columns.push(row);
        });
      });
    } else if (view.columns) {
      view.columns.forEach((column: Column) => {
        columns.push(column);
      });
    }

    return columns;

  }

  getAttributeNames(view: View | PinDialog): string[] {
    const result: string[] = [];

    view.columns.forEach((column: Column) => {
      result.push(column.attribute);
    });

    return result;
  }

  // getFirstViewTab(viewList: ViewList[]): View {
  //   // const children: Children[] = viewList[literal].children;
  //   // const viewName: string = children[0].path.split(':')[1];
  //   // return viewList[viewName];
  // }

  getFirstListViewTab(viewList: View[]): View {
    for (const key in viewList) {
      if (viewList[key].type === 'list' && viewList[key].name !== 'view_list') {
        return viewList[key];
      }
    }
  }

  static getFirstDetailViewTab(viewList: View[], className: string): View {
    for (const key in viewList) {
      if (viewList[key].type === 'detail' && viewList[key].binding === className) {
        return viewList[key];
      }
    }
  }

  getEntityDescriptors(attributeClassFields: AttributeClassField[]): Observable<AttributeDescriptor> {
    return this.entityService.getAttributeDescriptorArray(attributeClassFields).pipe(
      catchError(error => {
        return error;
      }), map((attributeDescriptor) => {
          return attributeDescriptor ? attributeDescriptor : EMPTY;
        },
      ));
  }

  getViewTab(workflow: Workflow, viewTabName: string): View {
    viewTabName = viewTabName.split('?')[0];
    return workflow.views[viewTabName];
  }

  resolveRefEntities(entityDescriptors: EntityDescriptor[]): EntityDescriptor[] {
    const refEntities: EntityDescriptor[] = [];

    for (let i = 0; i < entityDescriptors.length; i++) {
      if (entityDescriptors[i].DataType === 'Entity' && entityDescriptors[i].FkForeignEntityName !== '') {
        // entityDescriptors[i].sections = entityDescriptors[i].attribute.split('.');
        refEntities.push(entityDescriptors[i]);
      }
    }

    return refEntities;
  }


  getWorkFlowAndViewFromUrl(url: string): WorkflowView {

    const urlWithQueryParamsArr: string[] = url.split('?');
    const urlArr: string[] = urlWithQueryParamsArr[0].split('/');
    let endpointArr: string[] = [];
    endpointArr.push(urlArr[1]);


    endpointArr.push(urlArr[3]);
    if (urlArr[4] === 'handle') {


      const tabEndpointArr: string[] = urlArr[7].split(':');

      if (urlArr[2] === 'wizard') {
        endpointArr.push(tabEndpointArr[0]);
      } else {
        endpointArr.push(tabEndpointArr[1]);
      }


    }


    return this.getWorkFlowAndViewFromEndpoint(endpointArr.join(':'));


  }

  getWorkFlowAndViewFromEndpoint(endpoint: string): WorkflowView {
    endpoint = endpoint.split('?')[0];
    let view: View;
    let tabWorkflowView: WorkflowView = null;
    const endpointNameArr: string[] = endpoint.split(':');
    const workflow: Workflow = this.configService.getConfiguration().workflows.find(r => r.name === endpointNameArr[0]);

    if (workflow) {
      let tabData: View = null;
      view = workflow.views[endpointNameArr[1]] ? workflow.views[endpointNameArr[1]] : null;

      if (endpointNameArr.length === 3) {
        const tabView: View = workflow.views[endpointNameArr[2]] ? workflow.views[endpointNameArr[2]] : null;
        view.name = endpointNameArr[1];

        tabData = workflow.views[endpointNameArr[1]];
        tabWorkflowView = this.getWorkFlowAndViewFromEndpoint(workflow.name + ':' + tabView.name);
      } else {
        if (view) {
          if (view.children && view.children.length > 0) {
            view.name = endpointNameArr[1];
            const firstTabChild: Children = view.children[0];
            tabWorkflowView = this.getWorkFlowAndViewFromEndpoint(firstTabChild.path);
          }
        }
      }
    }

    return {workflow, view, tabWorkflowView};
  }

  static getWorkFlowAndViewFromEndpoint(endpoint: string, configuration: Configuration): WorkflowView {
    let view: View;
    let tabWorkflowView: WorkflowView = null;
    const endpointNameArr: string[] = endpoint.split(':');
    const workflow: Workflow = configuration.workflows.find(r => r.name === endpointNameArr[0]);

    if (workflow) {
      let tabData: View = null;
      view = workflow.views[endpointNameArr[1]] ? workflow.views[endpointNameArr[1]] : null;

      if (endpointNameArr.length === 3) {
        const tabView: View = workflow.views[endpointNameArr[2]] ? workflow.views[endpointNameArr[2]] : null;
        view.name = endpointNameArr[1];

        tabData = workflow.views[endpointNameArr[1]];
        tabWorkflowView = FormDataService.getWorkFlowAndViewFromEndpoint(workflow.name + ':' + tabView.name, configuration);
      } else {
        if (view) {
          if (view.children && view.children.length > 0) {
            view.name = endpointNameArr[1];
            const firstTabChild: Children = view.children[0];
            tabWorkflowView = FormDataService.getWorkFlowAndViewFromEndpoint(firstTabChild.path, configuration);
          }
        }
      }
    }

    return {workflow, view, tabWorkflowView};
  }

  getButtonFunctions(): ButtonFunction[] {
    return [
      {
        name: 'dashboard',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null): Observable<NavigationElement> => {


          this.routingProviderService.setActiveSidebarMenuName(action.endpoint, configService.getConfiguration());

          const navigationElement: NavigationElement = {
            urlArr: ['dashboard', action.endpoint],
            dashboardFilter: {dashboardTime: new Date().toTimeString()}
          };
          document.body.classList.remove('quick-menu-open');
          return of(navigationElement);

        }
      },
      {
        name: 'route',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null): Observable<NavigationElement> => {

          const endpoint: string = action.endpoint;
          let filterFile: FilterFile;

          if (action.defaultFilter) {
            filterFile = action.filters.find(r => r.name === action.defaultFilter);
          }

          const workflowView: WorkflowView = formDataService.getWorkFlowAndViewFromEndpoint(endpoint);
          routingProviderService.lastUrl = router.url;

          if (workflowView.view.type === ViewEnum.LIST || workflowView.view.type === ViewEnum.TIMELINE) {
            inputData.entityId = null;
            action.closeHandleMode = true;
          }


          const navigationElements: NavigationElement = NavigationService.getRouteFromEndpoint(
            endpoint,
            this.configService.getConfiguration(),
            inputData.selectedEntityId ? inputData.selectedEntityId : inputData.entityId,
            workflowView.tabWorkflowView ? workflowView.tabWorkflowView.view : null,
            filterFile,
            !action.closeHandleMode ? inputData.entityHandle : null,
            workflowView.workflow, null, action.target === 'quickMenu');
          return of([1]).pipe(mergeMap((res: any) => {


            if (inputDashboardData && inputDashboardData.dashboardFilter) {
              navigationElements.dashboardFilter = inputDashboardData.dashboardFilter;
            }
            if (!navigationElements.dashboardFilter) {
              navigationElements.dashboardFilter = {};
            }
            navigationElements.dashboardFilter = Object.assign(navigationElements.dashboardFilter, {time: new Date().getTime().toString()});


            return of(navigationElements);
          }));
        }
      },
      {
        name: 'routeGetIdFromEntityHandle',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null): Observable<NavigationElement> => {

          const endpoint: string = action.endpoint;


          const workflowView: WorkflowView = formDataService.getWorkFlowAndViewFromEndpoint(endpoint);


          const navigationElements: NavigationElement = NavigationService.getRouteFromEndpoint(
            endpoint,
            this.configService.getConfiguration(),
            routingProviderService.lastEntityId,
            workflowView.tabWorkflowView ? workflowView.tabWorkflowView.view : null,
            null,
            null,
            workflowView.workflow);
          return of([1]).pipe(mergeMap((res: any) => {

            return of(navigationElements);
          }));


        }
      },
      {
        name: 'createentity',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {

          return formDataService.createEntityObs(action, inputData, dataService, callBackService, navigationService, router);
        }
      },
      {
        name: 'createEntityAndCommit',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {

          return formDataService.createEntityObs(action, inputData, dataService, callBackService, navigationService, router).pipe(mergeMap((res: any) => {
            return sessionService.commit().pipe(mergeMap((commitRes: any) => {
              return of(res);
            }));
          }));
        }
      },
      {
        name: 'nextStep',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {

          const obs = of([1]).pipe(map(() => {
            const targetStepName: string = inputFormData.tabData.children[inputFormData.stepNumber + 1].path;
            const workflowView: WorkflowView = formDataService.getWorkFlowAndViewFromEndpoint(targetStepName);
            if (inputFormData.view.type !== ViewEnum.LIST && inputFormData.view.type !== ViewEnum.RECURRENCE_EDITOR) {
              if (dataService.checkRequiredFields(inputFormData, inputData).length === 0) {
                let stepperEle = document.getElementsByClassName('bs-stepper');
                let aList = stepperEle[0].querySelectorAll('a');
                for (let aEle = 0; aEle < aList.length; aEle++) {
                  if (aList[aEle].classList.contains('active')) {
                    aList[aEle + 1].style.borderBottomColor = '#4a89dc';
                    aList[aEle].style.borderBottomColor = '#4a89dc';
                  }
                }

                this.navigateToStep(inputFormData, inputData, workflowView);
              } else {

                const message: Message = {
                  title: this.translationService.getTranslation('content_from_config_json', 'please_check_required_fields_title'),
                  text: this.translationService.getTranslation('content_from_config_json', 'please_check_required_fields'),
                  type: MessageTypeEnum.NOTICE
                };
                this.messageStackService.addMessage(message);
              }
            } else {
              this.navigateToStep(inputFormData, inputData, workflowView);
            }
          }));
          return obs;
        }
      },
      {
        name: 'previousStep',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {


          const obs: Observable<any> = of([1]).pipe(map(() => {
            inputFormData.showSearchBar = false;
            const targetStepName: string = inputFormData.tabData.children[inputFormData.stepNumber - 1].path;
            const workflowView: WorkflowView = formDataService.getWorkFlowAndViewFromEndpoint(targetStepName);

            let stepperEle = document.getElementsByClassName('bs-stepper');
            let aList = stepperEle[0].querySelectorAll('a');
            for (let aEle = 0; aEle < aList.length; aEle++) {
              if (aList[aEle].classList.contains('active')) {
                aList[aEle].style.borderBottomColor = 'transparent';
              }
            }

            this.navigateToStep(inputFormData, inputData, workflowView);
          }));

          return obs;
        }
      },
      {
        name: 'createEntityAndNavigate',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {

          const endpoint: string = action.endpoint;
          const workflowView: WorkflowView = this.getWorkFlowAndViewFromEndpoint(endpoint);
          const createEntityDataRequest: CreateEntityDataRequest = {AttributeValues: [], Classname: workflowView.view.binding};
          const obs: Observable<any> = this.entityService.createEntityData(createEntityDataRequest).pipe(mergeMap((entityHandle: any) => {
            const attributeClassFields: AttributeClassField[] = formDataService.getAttributeFields(workflowView.view.binding, workflowView.tabWorkflowView.view);

            return formDataService.getEntityDescriptorsRequest(attributeClassFields).pipe(mergeMap((entityDescriptors: any) => {
              return this.setAttributeHandoversFromCache(entityHandle, entityDescriptors).pipe(mergeMap((success: boolean) => {
                return of(true);
              }));
            }), finalize(() => {
              const navigationElement: NavigationElement = this.navigateToWizard(entityHandle, action);
              router.navigate(navigationElement.urlArr, {queryParams: navigationElement.dashboardFilter});
            }));

          }));

          callBackService.setInitialRequest(obs);
          return obs;

        }
      },
      {
        name: 'createEntityCommitAndNavigate',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {

          const endpoint: string = action.endpoint;
          const workflowView: WorkflowView = this.getWorkFlowAndViewFromEndpoint(endpoint);
          const createEntityDataRequest: CreateEntityDataRequest = {AttributeValues: [], Classname: workflowView.view.binding};
          const obs: Observable<any> = this.entityService.createEntityData(createEntityDataRequest).pipe(mergeMap((entityHandle: any) => {
            return sessionService.commit().pipe(mergeMap((resCommit: any) => {
                return dataService.getEntityDataFromHandleRequest(['id'], entityHandle).pipe(mergeMap((resEntityData: any) => {
                  const entityData: EntityData = resEntityData;
                  const entityId: string = entityData.Attributes.Attribute.find(r => r.Name === 'id').Value;
                  const workflowView: WorkflowView = formDataService.getWorkFlowAndViewFromEndpoint(action.endpoint);

                  const navigationElements: NavigationElement = NavigationService.getRouteFromEndpoint(
                    action.endpoint,
                    this.configService.getConfiguration(),
                    entityId,
                    workflowView.tabWorkflowView ? workflowView.tabWorkflowView.view : null,
                    null,
                    entityHandle,
                    workflowView.workflow, null, action.target === 'quickMenu');

                  return of(navigationElements);

                }));
              }
            ));
          }));
          callBackService.setInitialRequest(obs);
          return obs;
        }
      },
      {
        name: 'createandassignentity',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {


          const endpoint: string = action.endpoint;
          const workflowView: WorkflowView = formDataService.getWorkFlowAndViewFromEndpoint(endpoint);
          const form = {};

          routingProviderService.activeReferenceInfos = workflowView.view.referenceInfos ? workflowView.view.referenceInfos : null;

          if (routingProviderService.activeReferenceInfos) {
            routingProviderService.activeReferenceHandle = inputData.entityHandle;
          }


          const attributeClassFields: AttributeClassField[] = formDataService.getAttributeFields(workflowView.view.binding, workflowView.tabWorkflowView ? workflowView.tabWorkflowView.view : workflowView.view);

          const getEntityDescriptorsObs: Observable<any> = formDataService.getEntityDescriptorsRequest(attributeClassFields).pipe(mergeMap((attributeDescriptors: any) => {
            const entityDescriptors: EntityDescriptor[] = attributeDescriptors;
            const createEntityObs: Observable<any> = dataService.createEntity(new FormGroup(form), attributeDescriptors.value, workflowView.view.binding).pipe(mergeMap((createEntityRes: number) => {
              inputData.entityHandle = createEntityRes;
              const navigationElements: NavigationElement = NavigationService.getRouteFromEndpoint(endpoint,
                this.configService.getConfiguration(), 'new-entity', workflowView.tabWorkflowView ? workflowView.tabWorkflowView.view : workflowView.view, null, inputData.entityHandle);
              routingProviderService.lastUrl = router.url;

              return this.setAttributeHandoversFromCache(createEntityRes, entityDescriptors).pipe(mergeMap((success: boolean) => {
                return of(navigationElements);
              }));
            }));
            callBackService.setInitialRequest(createEntityObs);
            return createEntityObs;
          }), catchError(error => {
            console.log(error);
            return error;
          }));

          callBackService.setInitialRequest(getEntityDescriptorsObs);
          return getEntityDescriptorsObs;


        }
      },
      {
        name: 'upload',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {

          document.getElementById('btn-upload').click();
          return of(true);
        }
      },
      {
        name: 'save_entity',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null): Observable<any> => {

          const requiredEndpoints: string[] = [];

          let isRequiredField = false;
          let requiredWorkflowView: WorkflowView;
          let tabs: Children[] = [];


          if (!inputFormData.tabData || inputFormData.tabData.children.length === 0) {

            tabs = [{
              type: 'detail',
              path: inputFormData.workflow.name + ':' + inputFormData.view.name,
              icon: '',
              name: ''
            }];
          } else {
            tabs = inputFormData.tabData.children;
          }


          return from(tabs).pipe(mergeMap((child: Children, index: number) => {
            const workflowView: WorkflowView = this.getWorkFlowAndViewFromEndpoint(child.path);
            if (workflowView.view.type && workflowView.view.type === ViewEnum.DETAIL) {
              return this.checkTabForRequiredFields(inputFormData, inputData, child, dataService).pipe(map((targetWorkflowView: WorkflowView) => {
                if (targetWorkflowView) {
                  isRequiredField = true;
                  requiredWorkflowView = targetWorkflowView;
                }

              }));
            } else {
              return of(null);
            }
          }), finalize(() => {
            if (isRequiredField) {
              const navigationElement: NavigationElement =
                NavigationService.getRouteFromEndpoint(
                  inputFormData.workflow.name + ':' + inputFormData.tabData.name,
                  configService.getConfiguration(),
                  inputData.entityId,
                  requiredWorkflowView.view, null, null, requiredWorkflowView.workflow);


              const message: Message = {
                title: dataService.translationService.getTranslation('content_from_config_json', 'please_check_required_fields_title'),
                text: dataService.translationService.getTranslation('content_from_config_json', 'please_check_required_fields'),
                type: MessageTypeEnum.NOTICE
              };

              this.messageStackService.addMessage(message);
              // @Todo Virtual Tabs

              router.navigate(navigationElement.urlArr, {queryParams: null});

            } else {

              if (!inputData.entityHandle) {
                // @Todo Wozu? 11.08.2022
                // return dataService.createEntity(inputData.formGroup, inputFormData.entityDescriptors,
                //   inputFormData.view.binding).pipe(mergeMap((res: any) => {
                //   inputData.formGroup.markAsPristine();
                //   routingProviderService.formDirty = false;
                //   return sessionService.commit().pipe(map(res => {
                //     inputData.entityData.Flags = null;
                //   }));
                // }));
              } else {
                inputData.formGroup.markAsPristine();
                routingProviderService.formDirty = false;
                sessionService.commit().subscribe((res: any) => {
                  inputData.entityData.Flags = null;
                  const handleRequest: HandleRequest = {Handle: inputData.entityHandle};
                  this.entityService.beginEdit(handleRequest).subscribe(() => {
                    dataService.getEntityDataAndUpdateForm(inputFormData, inputData).subscribe((resUpdateForm: any) => {
                      formDataService.refreshSvgComponents(inputFormData);
                    });

                  });
                });
              }
            }
          }));
        }
      },
      {
        name: ActivatorTypeEnum.ON_BEGIN_EDIT,
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {

          const handleRequest: HandleRequest = {Handle: inputData.entityHandle};

          return this.entityService.beginEdit(handleRequest).pipe(mergeMap((res: boolean) => {
            const tabView: View = inputFormData.tabData;
            return this.getTabView(tabView, inputData.entityHandle);
          }));

        }
      },
      {
        name: 'rollback_entity',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {
          inputData.entityHandle = null;
          return formDataService.rollback().pipe(
            catchError(error => {
              return error;
            }), map(() => {
                inputData.formGroup.markAsPristine();
                routingProviderService.formDirty = false;

                return true;
              },
            ));
        }
      },
      {
        name: 'rollback_and_get_data_entity',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null,
          inputNavigationData: InputNavigationData = null) => {


          const buttonFunction: ButtonFunction = this.getButtonFunctions().find(r => r.name === 'rollback_entity');

          inputData.isRollBack = true;
          return buttonFunction.func(
            authService,
            router,
            configService,
            navigationService,
            formDataService,
            dataService,
            sessionService,
            inputData,
            inputFormData,
            routingProviderService,
            action,
            callBackService,
            document).pipe(mergeMap((res: any) => {


            if (inputData.entityId !== 'new-entity') {

              if (inputFormData.view.type === ViewEnum.LIST) {
                return this.entitySmartService.getEntityHandle(inputFormData.tabData.binding, inputData.entityId).pipe(mergeMap((handle: any) => {
                  inputData.entityHandle = handle;
                  return this.listService.getListDataByAttribute(inputFormData.tabChild.attribute, handle,
                    inputFormData.view, inputDashboardData.dashboardFilter, inputFormData.tabChild.filterFile).pipe(mergeMap((res: any) => {
                    const dashboardColumns: Column[] = [];

                    dataService.setListEntityData(res, inputFormData, inputDashboardData, inputNavigationData, inputData, dashboardColumns, inputData.locale);
                    inputData.isRollBack = false;
                    return of(res);
                  }));
                }));
              } else {
                const navigationElements: NavigationElement = NavigationService.getRouteFromEndpoint(inputFormData.workflow.name +
                  ':' + inputFormData.tabData.name, configService.getConfiguration(), inputData.entityId, inputFormData.view, null, inputData.entityHandle, inputFormData.workflow);

                inputData.isRollBack = false;
                navigationElements.dashboardFilter = {time: new Date().toTimeString()};

                return of(navigationElements);
              }
            } else {
              return of({urlPath: routingProviderService.lastListUrlParams[routingProviderService.lastListUrlParams.length - 1]});
            }


          }));


        }
      },
      {
        name: 'assignEntity',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {


          document.getElementById('btn-ref-dialog').click();
          return of(true);
        }
      },
      {
        name: 'callfunction',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {

          const callFunctionRequest: Observable<any> = dataService.getCallFunctionRequest(action.function,
            inputData.entityHandle).pipe(catchError((error: PitException) => {
            // if (error.error && error.error.errorDetail && error.error.errorDetail.buttonsAndIconsType) {
            //   callBackService.setMessageBox(error.error.errorDetail.message, error.error.errorDetail.buttonsAndIconsType, error.error.type);
            //   callBackService.openCallBackDialog();
            // }

            return of(null);
          }), mergeMap((res: any) => {
            if (inputFormData.view.type !== ViewEnum.LIST && inputFormData.view.type !== ViewEnum.VIEW_LIST) {
              return dataService.getEntityDataAndUpdateForm(inputFormData, inputData);
            } else {
              return of(true);
            }
          }));

          callBackService.setInitialRequest(callFunctionRequest);
          return callFunctionRequest;

        }
      },
      {
        name: 'callfunctionwithhandler',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {


          const callFunctionRequest: Observable<any> = dataService.getCallFunctionWithHandlerRequest(action.function,
            inputData.entityHandle).pipe(mergeMap((res: any) => {
            const handle: number = res;

            const getCallFunctionByHandle: Observable<any> = dataService.getCallFunctionByHandleRequest(handle).pipe(catchError((error) => {
              return error;
            }), mergeMap((res: EntityCallFunctionByHandleResponse) => {
              if (res.ShowEntityView) {
                if (res.FunctionReturnCode === 'Download') {
                  // @Todo FunctionReturnCode integrieren

                  const downloadFileRequest: DownloadFileRequest = {
                    Filepath: res.ShowEntityView
                  };
                  return dataService.fileService.downloadFile(downloadFileRequest).pipe(map((res: any) => {
                    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
                    a.target = '_blank';
                    a.href = this.configService.getConnectionSettings().backend_url + '/' + res.fileDetail;


                    a.download = res.fileInfos.FullFileName;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                  }));
                } else {
                  const endpoint: string = res.ShowEntityView;
                  const splitArr: string[] = res.ShowEntityView.split(':');
                  const workflowView: WorkflowView = formDataService.getWorkFlowAndViewFromEndpoint(res.ShowEntityView);
                  routingProviderService.inputDashboardData = [];
                  routingProviderService.lastEntityId = inputData.entityId;

                  let navigationElement: NavigationElement = {urlArr: [], dashboardFilter: {}};
                  // if (this.routingProviderService.lastUrl) {
                  //   navigationElement.urlArr = this.routingProviderService.lastUrl.split('/');
                  //   console.log(this.routingProviderService.lastUrl);
                  //   return of(navigationElement);
                  // } else {
                  // inputData.formGroup.markAsPristine();
                  navigationElement = NavigationService.getRouteFromEndpoint(
                    workflowView.workflow.name + ':' + workflowView.view.name,
                    configService.getConfiguration(),
                    null,
                    workflowView.tabWorkflowView.view, null,
                    res.ref_ReturnObject, workflowView.tabWorkflowView.workflow, null, null);

                  if (res.FunctionReturnMessage) {

                    const message: Message = {title: '', type: MessageTypeEnum.SUCCESS, text: res.FunctionReturnMessage};
                    this.messageStackService.addMessage(message);
                  }
                  return of(navigationElement);
                  // }


                }
              } else {

                if (inputFormData && inputFormData.view.type !== ViewEnum.LIST) {
                  return dataService.getEntityDataAndUpdateForm(inputFormData, inputData);
                } else {
                  return of(true);
                }


              }

            }), catchError(error => {
              console.log(error);
              return error;
            }));
            callBackService.setInitialRequest(getCallFunctionByHandle);
            return getCallFunctionByHandle;
          }));

          callBackService.setInitialRequest(callFunctionRequest);
          return callFunctionRequest.pipe(map((res: any) => {
            return res;
          }));

        }
      },
      {
        name: 'send_mail',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {

          document.getElementById('btn-rtf-modal').click();
          return of(null);
        },
      },
      {
        name: 'assignToEntityList',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {

          // Get Entity EL Handle

          const entityDataX: EntityData = inputData.entityData.Attributes.Attribute.find(r => r.Name === inputFormData.view.referenceInfos.ReferenceAttribute);

          return this.listService.getListDataByAttribute(action.attribute, parseInt(entityDataX.Value, 10), inputFormData.view, null).pipe(mergeMap((entityData: any) => {

            const assignEntityRequest: AssignEntityRequest = {
              Handle: parseInt(entityDataX.Value, 10),
              AssignedHandle: inputData.entityHandle,
              ReferenceAttribute: inputFormData.view.referenceInfos.ReferenceAttribute,
            };
            return this.entityService.assignEntity(assignEntityRequest).pipe(mergeMap((res: any) => {
              return this.sessionService.commit().pipe(map((res: any) => {
                return res;
              }));
            }));
          }));


        },
      },
      {
        name: 'download',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {


          const callFunctionParameters: CallFunctionParameterSwagger[] = [];
          if (action.callFunctionParameters) {
            action.callFunctionParameters.forEach((callFunctionParameter: CallFunctionParameter) => {
              if (callFunctionParameter.parameterType) {
                callFunctionParameters.push({Value: inputData.entityHandle.toString(), Type: 'integer'});
              } else {
                callFunctionParameters.push({Value: callFunctionParameter.value, Type: callFunctionParameter.type});
              }
            });
          }


          const downloadFileRequest: DownloadFileRequest = {
            Functionname: action.function,
            CallFunctionParameters: callFunctionParameters
          };

          return dataService.fileService.downloadFile(downloadFileRequest).pipe(map((res: any) => {
            const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
            a.target = '_blank';
            a.href = this.configService.getConnectionSettings().backend_url + '/' + res.fileDetail;


            a.download = res.fileInfos.FullFileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }));
        }
      },
      {
        name: 'exportEntityList',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {


          let sortings: Sortings[] = [];

          if (inputDashboardData.dashboardFilter.sortField) {
            sortings = [{
              ComplexAttributeName: inputDashboardData.dashboardFilter.sortField,
              SortingDirection: inputDashboardData.dashboardFilter.sortDirection ? inputDashboardData.dashboardFilter.sortDirection : SortingEnum.E_ASCENDING
            }];
          }

          const attributeNames: string[] = formDataService.getAttributeNames(inputFormData.view);
          const entityListRequest: EntityListRequest = {
            AttributeNames: attributeNames,
            BaseFilter: inputDashboardData.dashboardFilter.baseFilter ?
              JSON.parse(inputDashboardData.dashboardFilter.baseFilter) :
              [{ClassName: inputFormData.view.binding, FilterConditions: []}],
            Sortings: sortings,
            Start: 0,
            Limit: 0,
            filterFile: inputDashboardData.dashboardFilter.filterFile
          };


          return dataService.entityListService.exportEntityList(entityListRequest).pipe(map((fileResponse: FileResponse) => {
            const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
            a.target = '_blank';
            a.href = this.configService.getConnectionSettings().backend_url + '/' + fileResponse.fileDetail;
            a.download = fileResponse.fileDetail;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }));
        }
      },
      {
        name: 'setMarkerAndRoute',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {

          return this.getAttributeHandoversSvg(action, inputData);
        }
      },
      {
        name: 'setFormPristine',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {
          inputData.formGroup.markAsPristine();
          routingProviderService.formDirty = false;
          return of(true);
        }
      },
      {
        name: 'onBeginEdit',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {

          const handleRequest: HandleRequest = {Handle: inputData.entityHandle};
          return this.entityService.beginEdit(handleRequest);
        }
      },
      {
        name: 'delete_entity',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {

          const deleteEntityRequest: DeleteEntityRequest = {Handle: inputData.entityHandle};
          return this.entityService.deleteEntity(deleteEntityRequest).pipe(mergeMap((res: any) => {
            return of({refreshListVIew: true});
          }));
        }
      },
      {
        name: 'link',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {


        }
      },
      {
        name: 'eval_click',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {
          document.getElementById(action.elementId).click();
          return of(null);
        }
      },
      {
        name: 'helpText',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {


          const helpText: string = inputFormData.view.helpText;
          const dialogRef = this.dialog.open(HelpTextComponent, {
            width: '600px',
            data: {helpText: helpText}
          });

          return of(true);
        }
      },
      {
        name: 'browserPrintDialog',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {


          window.print();
          return of(true);
        }
      },
      {
        name: 'logout',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {


          return this.userService.logoutUser().pipe(mergeMap((res: any) => {
            const navigationElement: NavigationElement = {urlArr: ['login'], dashboardFilter: null};
            return of(navigationElement);
          }));


        }
      },
      {
        name: 'commit',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {

          return this.sessionService.commit();


        }
      },
      {
        name: 'set_entity_data',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {

          return dataService.updateSingleFormField(
            inputData.formGroup,
            action.controlName,
            action.value,
            inputFormData.entityDescriptors,
            inputData.entityHandle,
            false,
            inputFormData,
            inputData).pipe(mergeMap((res: any) => {
            return this.sessionService.commit();
          })).pipe(mergeMap((res: any) => {
            inputFormData.isControlFocused = false;
            inputFormData.controlFocusedName = null;
            return dataService.getEntityDataAndUpdateForm(inputFormData, inputData);
          }));
        }
      },
      {
        name: 'refreshList',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {

          return of({refreshListVIew: true});

        }
      },
      {
        name: 'url',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {


          let url: string = action.endpoint;
          if (action.sessionHandover) {
            url = url + '?session_id=' + localStorage.getItem('sessionId');
          }

          if (action.presentationMode) {
            url = url + '&presentation_mode=1';
          }

          window.open(url, '_blank');
          return of(null);
        }
      },
      {
        name: 'set_event_entity_data',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {


          const attributeValues: NamedValue[] = [{DataType: 'Entity', Value: inputData.eventObj.AppointmentHandle, Name: action.attribute}];
          const setEntityDataRequest: SetEntityDataRequest = {EntityHandleId: inputData.entityHandle, AttributeValues: attributeValues};
          return this.entityService.setEntityData(setEntityDataRequest);


        }
      },
      {
        name: 'next_tab',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {

          const children: Children[] = inputFormData.tabData.children;
          const tabIndex: number = children.findIndex(r => r.path === inputFormData.tabChild.path);
          const nextTabIndex: number = tabIndex + 1;


          if (children[nextTabIndex]) {
            const targetTab: Children = children[nextTabIndex];
            const workflowView: WorkflowView = this.getWorkFlowAndViewFromEndpoint(targetTab.path);
            const endpoint: string = workflowView.workflow.name + ':' + inputFormData.tabData.name;


            const navigationElement: NavigationElement = NavigationService.getRouteFromEndpoint(
              endpoint,
              configService.getConfiguration(),
              inputData.entityId,
              workflowView.view,
              null,
              inputData.entityId ? null : inputData.entityHandle,
              workflowView.workflow,
              null,
              null,
              targetTab.isVirtual ? targetTab.entityId : null);

            return of(navigationElement);
          }


          return of(true);
        }
      },
      {
        name: 'previous_tab',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {

          const children: Children[] = inputFormData.tabData.children;
          const tabIndex: number = children.findIndex(r => r.path === inputFormData.tabChild.path);
          const nextTabIndex: number = tabIndex - 1;


          if (children[nextTabIndex]) {
            const targetTab: Children = children[nextTabIndex];
            const workflowView: WorkflowView = this.getWorkFlowAndViewFromEndpoint(targetTab.path);
            const endpoint: string = workflowView.workflow.name + ':' + inputFormData.tabData.name;


            const navigationElement: NavigationElement = NavigationService.getRouteFromEndpoint(
              endpoint,
              configService.getConfiguration(),
              inputData.entityId,
              workflowView.view,
              null,
              inputData.entityId ? null : inputData.entityHandle,
              workflowView.workflow,
              null,
              null,
              targetTab.isVirtual ? targetTab.entityId : null);

            return of(navigationElement);
          }


          return of(true);
        }
      },
      {
        name: 'add_url_to_history',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {

          routingProviderService.addLastListUrlParams(router.url);
          return of(true);
        }
      },
      {
        name: 'route_to_static_page',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {

          const endpoint: string[] = action.endpoint.split(':');
          const navigationElement: NavigationElement = {urlArr: endpoint, dashboardFilter: null};
          return of(navigationElement);

        }
      },
      {
        name: 'route_to_handle',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {


          const workflowView: WorkflowView = formDataService.getWorkFlowAndViewFromEndpoint(action.endpoint);

          const navigationElement: NavigationElement = NavigationService.getRouteFromEndpoint(
            action.endpoint,
            configService.getConfiguration(),
            null, workflowView.tabWorkflowView.view, null, inputData.entityHandle, workflowView.tabWorkflowView.workflow, null, null, null);

          return of(navigationElement);

        }
      },
      {
        name: 'route_to_list_view',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {

          let filterFile: FilterFile;

          if (action.defaultFilter) {
            filterFile = {file: action.defaultFilter};
          }

          const navigationElement: NavigationElement = NavigationService.getRouteFromEndpoint(
            action.endpoint,
            configService.getConfiguration(),
            null, null, filterFile, null, null, null, null, null);

          return of(navigationElement);

        }

      },
      {
        name: 'route_to_user_entity',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {


          const userEntityData: EntityData = localStorage.getItem('currentUserEntityData') !== 'undefined'
            ? JSON.parse(localStorage.getItem('currentUserEntityData')) : null;

          const id: string = userEntityData.Attributes.Attribute.find(r => r.Name === 'id').Value;

          const workflowView: WorkflowView = formDataService.getWorkFlowAndViewFromEndpoint(action.endpoint);


          if (userEntityData) {

            const navigationElement: NavigationElement = NavigationService.getRouteFromEndpoint(
              workflowView.workflow.name + ':' + workflowView.view.name,
              configService.getConfiguration(),
              id, workflowView.tabWorkflowView.view, null, null, workflowView.tabWorkflowView.workflow, null, null, null);
            return of(navigationElement);
          }


        }

      },
      {
        name: 'openAssetFile',
        func: (
          authService: AuthService,
          router: Router,
          configService: ConfigService,
          navigationService: NavigationService,
          formDataService: FormDataService,
          dataService: DataService,
          sessionService: SessionService,
          inputData: InputData,
          inputFormData: InputFormData,
          inputDashboardData: InputDashboardData,
          routingProviderService: RoutingProviderService,
          action: Action,
          callBackService: CallbackService,
          document: Document = null) => {
          const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
          a.target = '_blank';
          a.href = configService.getConnectionSettings().backend_url + '/' + action.endpoint;
          document.body.appendChild(a);
          a.click();

        }

      },
      {
        name: 'reload_user_role_data',
        func: (authService: AuthService,
               router: Router,
               configService: ConfigService,
               navigationService: NavigationService,
               formDataService: FormDataService,
               dataService: DataService,
               sessionService: SessionService,
               inputData: InputData,
               inputFormData: InputFormData,
               inputDashboardData: InputDashboardData,
               routingProviderService: RoutingProviderService,
               action: Action,
               callBackService: CallbackService,
               document: Document = null) => {

          const originalConfig: Configuration = configService.getOriginalConfig();
          configService.setConfiguration(originalConfig);
          return authService.getUserRoleAndCheckPermissionsObs();
        }

      }

    ];
  }


  createEntityObs(action: Action,
                  inputData: InputData,
                  dataService: DataService,
                  callBackService: CallbackService,
                  navigationService: NavigationService,
                  router: Router,
                  navigateToEntity: boolean = true): Observable<any> {

    if (!inputData) {
      inputData = {};
    }
    const endpoint: string = action.endpoint;
    const workflowView: WorkflowView = this.getWorkFlowAndViewFromEndpoint(endpoint);
    const form = {};

    const attributeClassFields: AttributeClassField[] = this.getAttributeFields(workflowView.view.binding, workflowView.tabWorkflowView.view);

    const getEntityDescriptorsObs: Observable<any> = this.getEntityDescriptorsRequest(attributeClassFields).pipe(mergeMap((attributeDescriptorsRes: any) => {

      const entityDescriptors: EntityDescriptor[] = attributeDescriptorsRes;

      if (this.routingProviderService.attributeHandOvers) {
        this.routingProviderService.attributeHandOvers.forEach((attributeHandover: Handover) => {
          form[attributeHandover.target] = new FormControl(attributeHandover.data);
        });
        this.routingProviderService.attributeHandOvers = null;
      }

      const createEntityObs: Observable<any> = dataService.createEntity(new FormGroup(form), entityDescriptors,
        workflowView.view.binding).pipe(mergeMap((createEntityRes: number) => {
        inputData.entityHandle = createEntityRes;
        const handleRequest: HandleRequest = {Handle: createEntityRes};
        return this.entityService.beginEdit(handleRequest).pipe(mergeMap((res: boolean) => {


          // routingProviderService.fallBackEntityHandle = createEntityRes;
          const navigationElements: NavigationElement = NavigationService.getRouteFromEndpoint(endpoint,
            this.configService.getConfiguration(), 'new-entity', workflowView.tabWorkflowView.view, null, inputData.entityHandle);
          this.routingProviderService.lastUrl = router.url;

          if (inputData.handovers) {
            this.routingProviderService.setAttributeHandover(inputData.handovers);
          }

          if (navigateToEntity) {
            return of(navigationElements);
          } else {
            return of(createEntityRes);
          }
        }));

      }));
      callBackService.setInitialRequest(createEntityObs);
      return createEntityObs;
    }), catchError(error => {
      console.log(error);
      return error;
    }));

    callBackService.setInitialRequest(getEntityDescriptorsObs);
    return getEntityDescriptorsObs;
  }


  getAllButtonBarButtons(view: View, barType: BarTypeEnum, result: Activator[] = []): Activator[] {

    if (view.type === ViewEnum.VIEW_CONTAINER_TABBED || view.type === ViewEnum.WIZARD) {
      if (view.children && view.children.length > 0) {
        const workflowView: WorkflowView = this.getWorkFlowAndViewFromEndpoint(view.children[0].path);
        result = this.getAllButtonBarButtons(workflowView.view, barType, result);
      }
    } else {
      if (view[barType] && view[barType].activators) {
        // view[barType].activators.forEach((activator: Activator) => {
        this.checkActivatorPermissions(view[barType]).subscribe(() => {
        });
        result = (view[barType].activators);
        // });
      }
    }
    return result;


  }

  getAllTopBarTitles(view: View, result: ButtonBarTitle[] = []): ButtonBarTitle[] {
    if (view.type === ViewEnum.VIEW_CONTAINER_TABBED || view.type === ViewEnum.WIZARD) {

      if (view.children && view.children.length > 0) {
        const workflowView: WorkflowView = this.getWorkFlowAndViewFromEndpoint(view.children[0].path);
        result = this.getAllTopBarTitles(workflowView.view, result);
      }

    } else {
      if (view.topbar && view.topbar.title) {
        view.topbar.title.forEach((buttonBarTitle: ButtonBarTitle) => {
          result.push(buttonBarTitle);
        });
      }
    }

    return result;
  }


  getRefInformations(columns: Column[], entityDescriptors: EntityDescriptor[]) {
    let entityDescriptor: EntityDescriptor;

    columns.forEach((column: Column) => {
      entityDescriptor = entityDescriptors.find(r => r.attribute === column.attribute);

      if (!entityDescriptor && column.attribute) {
        let refNameArr: string[] = column.attribute.split('.');

        column.name = refNameArr[refNameArr.length - 1];
        refNameArr = refNameArr.slice(0, refNameArr.length - 1);
        column.refName = refNameArr.join('.');
      } else {


        entityDescriptor.label = column.label && column.label !== '' ? column.label : null;
        let refNameArr: string[] = column.attribute ? column.attribute.split('.') : [];

        if (refNameArr.length > 1) {
          column.name = refNameArr[refNameArr.length - 1];
          refNameArr = refNameArr.slice(0, refNameArr.length - 1);
          column.refName = refNameArr.join('.');
        } else {
          entityDescriptor.label = column.label && column.label !== '' ? column.label : null;
          if (entityDescriptor.DataType !== 'Entity' && entityDescriptor.FkForeignEntityName === '') {
            column.name = column.attribute;
          }
        }
      }

    });

  }

  // setControlType(columns: Column[]) {
  //
  //   columns.forEach((column: Column) => {
  //     if (!column.attribute || (column.attribute && column.attribute !== '')) {
  //       column. this.castControlType(entityDescriptor.Columnname);
  //
  //     }
  //   });
  // }

  setControlTypeByEntityDescriptor(entityDescriptor: EntityDescriptor, column: Column): void {
    let result: string;

    if (entityDescriptor.DefaultValues &&
      entityDescriptor.DefaultValues.NamedValueEx &&
      entityDescriptor.DefaultValues.NamedValueEx.length > 0) {
      result = 'combobox';
    }


    if (entityDescriptor.DefaultValues &&
      entityDescriptor.DefaultValues.NamedValueEx &&
      entityDescriptor.DefaultValues.NamedValueEx.length > 0) {
      result = 'combobox';
    }

    if (entityDescriptor.DataSemantic === ControlTypeEnum.FILE) {
      result = ControlTypeEnum.FILE;

    }

    if (!result) {
      switch (entityDescriptor.DataType) {
        case 'Float':
          result = 'input-text';
          break;

        case 'Entity':
          result = 'input-ref';
          break;

        case 'WeakReference':
          result = 'input-ref';
          break;

        case 'Time':
          result = 'input-time';
          break;

        case 'Date':
          result = 'input-time';
          break;

        case 'String':
          if (column.controlType === ControlTypeEnum.HTML) {
            result = ControlTypeEnum.HTML;
            break;
          }

          if (column.controlType === ControlTypeEnum.TEXTAREA) {
            result = 'textarea';
          } else {
            result = 'input-text';
          }

          if (column.controlType === ControlTypeEnum.MERMAID) {
            result = 'mermaid';
          }

          break;

        case 'Integer':
          if (entityDescriptor.DataSemantic === ControlTypeEnum.BOOLEAN) {
            result = 'boolean';
          }

          if (entityDescriptor.DataSemantic === ControlTypeEnum.Integer) {
            result = 'input-integer';
          }
          break;

      }
    }
    const resultFixedControlType: string = this.castControlType(column.controlType);

    if (resultFixedControlType) {
      result = resultFixedControlType;
    }

    entityDescriptor.controlType = result;
  }

  castControlType(controlType: string): string {
    let result: string;

    if (controlType === ControlTypeEnum.RADIO) {
      result = ControlTypeEnum.RADIO;
    }

    if (controlType === ControlTypeEnum.TEXT) {
      result = ControlTypeEnum.TEXT;
    }

    if (controlType === ControlTypeEnum.TEXT_STATIC) {
      result = ControlTypeEnum.TEXT_STATIC;
    }

    if (controlType === ControlTypeEnum.SVG) {
      result = ControlTypeEnum.SVG;
    }

    if (controlType === ControlTypeEnum.BUTTON) {
      result = ControlTypeEnum.BUTTON;
    }


    return result;
  }

  setEntityDescriptorForRefs(column: Column, inputFormData: InputFormData): EntityDescriptor {
    const name = column.attribute;
    const entityDescriptor: EntityDescriptor = inputFormData.entityDescriptors.find(r => r.attribute === name);
    inputFormData.refEntities.forEach((refEntity: EntityDescriptor) => {

      if (!entityDescriptor) {

      }

      if (column.refName) {
        const refNameArr: string[] = column.refName.split('.');
        if (entityDescriptor && refNameArr[refNameArr.length - 1] === refEntity.Name) {
          entityDescriptor.DataType = refEntity.DataType;
          entityDescriptor.FkForeignEntityName = refEntity.FkForeignEntityName;
          column.refEntity = refEntity.FkForeignEntityName;


        }
      }
    });

    return entityDescriptor;
  }

  addColumnsForControlsWithAttributes(view: View): Column[] {

    const columns: Column[] = [];

    if (view.columns) {
      view.columns.forEach((column: Column) => {
        if (column.attributes && column.attributes.length > 0) {
          column.attributes.forEach((attribute: string) => {
            columns.push({attribute, controlType: column.controlType ? column.controlType : null, visible: false, isConcatAttribute: true});
          });
        }
      });
    }

    return columns;
  }

  addSectionsForControlsWithAttributes(view: View): Section[] {
    const resultSections: Section[] = [];
    const columns: Column[] = [];

    if (view.sections) {
      view.sections.forEach((section: Section) => {
        section.rows.forEach((row: Column) => {

          if (row.attributes && row.attributes.length > 0) {
            row.attributes.forEach((attribute: string) => {
              columns.push({attribute, controlType: row.controlType ? row.controlType : null, visible: false});
            });

          }


        });
      });

      resultSections.push({visible: false, name: 'hiddenAttributes', rows: columns});
    }
    return resultSections;
  }

  getColumnsInView(view: View, inputFormData: InputFormData): void {
    let entityDescriptor: EntityDescriptor;


    if (view.sections) {
      let sections: Section[] = view.sections;

      sections.forEach((section: Section) => {
        section.rows.forEach((row: Column) => {

          if (row.attribute && row.attribute !== '') {
            entityDescriptor = inputFormData.entityDescriptors.find(r => r.attribute === row.attribute);

            if (!entityDescriptor) {
              entityDescriptor = inputFormData.entityDescriptors.find(r => r.Name === row.attribute);
              if (!entityDescriptor) {
                entityDescriptor = {attribute: row.attribute, controlType: row.controlType, label: row.label};
              }
            }


            entityDescriptor.label = row.label && row.label !== '' ? row.label : null;
            let refNameArr: string[] = row.attribute.split('.');
            row.isTemporary = this.permissionService.isPermittedAttribute(row, inputFormData.entityDescriptors, EntityDescriptorFlagsEnum.FLAG_IS_TEMPORARY);
            row.isSortable = this.permissionService.isPermittedAttribute(row, inputFormData.entityDescriptors, EntityDescriptorFlagsEnum.FLAG_IS_SORTABLE);
            if (refNameArr.length > 1) {
              row.name = refNameArr[refNameArr.length - 1];
              refNameArr = refNameArr.slice(0, refNameArr.length - 1);

              row.refName = refNameArr.join('.');
              entityDescriptor = this.setEntityDescriptorForRefs(row, inputFormData);
            } else {
              if (entityDescriptor.DataType === 'Entity') {
                row.refName = row.attribute;
              }

              if (entityDescriptor.DataType !== 'Entity' && entityDescriptor.FkForeignEntityName === '') {
                row.name = row.attribute;
              }
            }
            this.setControlTypeByEntityDescriptor(entityDescriptor, row);
          } else {
            entityDescriptor = {
              Columnname: row.name,
              attribute: null,
              DataType: 'string',
              Name: row.name,
              controlType: row.controlType,
              DataSemantic: null

            };
            inputFormData.entityDescriptors.push(entityDescriptor);
            row.permissionType = PermissionEnum.VISIBLE;
            row.permissions = {visible: true, required: false, writeable: false};

          }

        });
      });
    }

    if (inputFormData.view.columns && inputFormData.view.columns.length > 0) {
      inputFormData.view.columns.forEach((column: Column) => {


        column.isTemporary = this.permissionService.isPermittedAttribute(
          column,
          inputFormData.entityDescriptors,
          EntityDescriptorFlagsEnum.FLAG_IS_TEMPORARY);

        column.isSortable = this.permissionService.isPermittedAttribute(
          column,
          inputFormData.entityDescriptors,
          EntityDescriptorFlagsEnum.FLAG_IS_SORTABLE);

        if (column.attribute.indexOf('$') > -1) {
          column.isSortable = false;
          column.isTemporary = true;
        }
      });
    }
  }

  setFilterPermissions(columns: Column[], formGroup: FormGroup): void {
    columns.forEach((column: Column) => {
      this.permissionService.setColumnPermissions(true, true, false, column, formGroup, PermissionEnum.VISIBLE);
    });
  }

  setFilters(inputDashboardData: InputDashboardData, inputFormData: InputFormData, inputData: InputData): void {

    const columns: Column[] = [];
    const oldForm: FormGroup = inputData.formGroup;
    const form = {};


    for (let key in inputDashboardData.listExtendedFilterFormGroup.controls) {

      if (inputDashboardData.listExtendedFilterFormGroup.controls[key].value) {
        const column: Column = inputFormData.view.columns.find(r => r.attribute === key);

        if (column) {
          columns.push(column);
        }

        if (inputData.formGroup) {


          form[column.attribute] = new FormControl('');

          if (column.attribute.indexOf('.') > -1) {

            const stringArr: string[] = column.attribute.split('.');
            const refFilterHandleKey: KeyValue = inputDashboardData.refFilterHandles.find(r => r.key === stringArr[0]);

            if (refFilterHandleKey) {

              refFilterHandleKey.value = '';
            }
          }
        }

      }
    }

    inputDashboardData.extendedFilterColumns = columns;
    inputData.formGroup = new FormGroup(form);

    if (!inputData.formGroup) {
      inputData.formGroup = this.createForm(columns);

    }

    this.setFilterPermissions(columns, inputData.formGroup);

  }


  getFormData(route: ActivatedRouteSnapshot): Observable<InputFormData> {
    let viewName: string;
    let view: View;
    let tab: View;
    let menu: Menu;

    const configuration: Configuration = this.configService.getConfiguration();
    const workflow: Workflow = route.parent.data.inputFormData ?
      route.parent.data.inputFormData.workflow : configuration.workflows.find(r => r.name === route.url[0].path);


    if (route.url[2]) {
      view = this.getViewTab(workflow, route.url[2].path);
      tab = this.getViewTab(workflow, route.url[2].path);
      viewName = view.name;

      // Get SideBarMenu Data
      menu = configuration.menus.main.find(r => r.action && r.action.endpoint === route.url[0].path + ':' + route.url[2].path);

      if (!menu) {
        configuration.menus.main.forEach((menuX: Menu) => {
          if (menuX.children && menuX.children.length > 0) {
            const child: Children = menuX.children.find(r => r.action.endpoint === route.url[0].path + ':' + route.url[2].path);

            if (child) {
              menu = child;
            }
          }
        });
      }

    }

    this.routingProviderService.setActiveSidebarMenuName(route.url[0].path + ':' + viewName, configuration);
    return of(
      {
        workflow,
        tab,
        tabChild: null,
        tabView: null,
        tabData: null,
        view,
        entityDescriptors: null,
        refEntities: null,
        sideBarData: menu ? menu.action : null, kanbanData: {},
        quickMenu: route.url[3] && route.url[3].path === 'quick'
      });
  }

  getEntityDescriptorsRequest(attributeClassFields: AttributeClassField[]): Observable<AttributeDescriptor> {
    const attributeClassFieldsNextLevel: AttributeClassField[] = [];
    const refResult: KeyValue[] = [];

    return this.getEntityDescriptors(attributeClassFields).pipe(mergeMap((attributeDescriptor: any) => {
      const entityDescriptors: EntityDescriptor[] = attributeDescriptor;

      let i = 0;
      entityDescriptors.forEach((entityDescriptor: EntityDescriptor) => {
        entityDescriptors[i].attribute = attributeClassFields[i].Attributename;
        if (entityDescriptor.DataType === 'Entity') {
          refResult.push({key: entityDescriptor.Columnname, value: entityDescriptor.FkForeignEntityName});
        }
        i++;
      });

      attributeClassFields.forEach((attributeClassField: AttributeClassField) => {
        if (attributeClassField.refLevels && attributeClassField.refLevels.length > 1) {
          const keyValue: KeyValue = refResult.find(r => r.key === attributeClassField.refLevels[0]);

          if (keyValue) {
            attributeClassFieldsNextLevel.push({
              Attributename: attributeClassField.refLevels.slice(1, attributeClassField.refLevels.length).join('.'),
              Classname: keyValue.value
            });

            attributeClassFieldsNextLevel.push({
              Attributename: attributeClassField.refLevels.slice(1, attributeClassField.refLevels.length - 1).join('.'),
              Classname: keyValue.value
            });
          }
        }
      });

      if (attributeClassFieldsNextLevel.length > 0) {

        return this.getEntityDescriptorsRequest(attributeClassFieldsNextLevel).pipe(mergeMap((attributeDescriptorNextLevel: any) => {
          const entityDescriptorsNextLevel: EntityDescriptor[] = attributeDescriptorNextLevel;

          entityDescriptorsNextLevel.forEach((entityDescriptor: EntityDescriptor) => {
            attributeDescriptor.push(entityDescriptor);
          });

          return of(attributeDescriptor);
        }));

      }
      return of(attributeDescriptor);
    }));


  }

  refreshSvgComponents(inputFormData: InputFormData): void {
    let entityDescriptor: EntityDescriptor = inputFormData.entityDescriptors.find(r => r.controlType === 'SVG');
    if (entityDescriptor) {
      entityDescriptor.controlType = 'SVG_';
      setTimeout(() => {
        let entityDescriptor: EntityDescriptor = inputFormData.entityDescriptors.find(r => r.controlType === 'SVG_');
        entityDescriptor.controlType = 'SVG';
      }, 0);
    }

    entityDescriptor = inputFormData.entityDescriptors.find(r => r.controlType === ControlTypeEnum.MERMAID);
    if (entityDescriptor) {
      entityDescriptor.controlType = 'mermaid_';
      setTimeout(() => {
        entityDescriptor.controlType = 'mermaid';
      }, 0);
    }
  }

  getCallFunctionActivators(activators: Activator[], inputData: InputData): Observable<any> {
    const result: Observable<any>[] = [];

    return from(activators).pipe(mergeMap((activator: Activator) => {
      activator.callFunctionContexts = [];
      if (activator.children) {
        return this.getCallFunctionActivators(activator.children, inputData);
      } else {
        const actions: Action[] = activator.actions ? activator.actions : [activator.action];
        const contextActions: Action[] = actions.filter(r => r.mode === ActivatorTypeEnum.CALL_FUNCTION_WITH_HANDLER
          || r.mode === ActivatorTypeEnum.CALL_FUNCTION);


        if (contextActions.length > 0) {

          return from(contextActions).pipe(mergeMap((contextAction: Action) => {
            const body: EntityCallFunctionRequest = {
              Handle: inputData.entityHandle,
              Functionname: contextAction.function,
              ContextType: contextAction.contextType
            };
            return this.entityService.entityCallFunctionContext(body).pipe(mergeMap((entityCallFunctionContextResponse: EntityCallFunctionContextResponse) => {
              activator.callFunctionContexts.push(entityCallFunctionContextResponse);
              activator.isCallFunctionContextChecked = true;
              return of(activator);
            }));


          }));

        } else {
          activator.isCallFunctionContextChecked = true;
          return of(activator);
        }


      }

    }), catchError((err) => {
      return err;
    }));
  }

  getBarElementObs(view: View, handleId: number): Observable<any> {
    const attributes: string[] = [];
    const buttonBarTitle: ButtonBarTitle[] = this.getAllTopBarTitles(view);
    buttonBarTitle.forEach((buttonBarTitle: ButtonBarTitle) => {
      if (buttonBarTitle.type === ButtonBarTitleTypeEnum.ATTRIBUTE) {
        attributes.push(buttonBarTitle.text);
      }
    });

    const getEntityDataFromHandleRequest: EntityDataFromHandleRequest = {Attributename: attributes, Handle: handleId};
    return this.entityService.getEntityDataFromHandle(getEntityDataFromHandleRequest).pipe(map((res: any) => {
      return {
        buttonActivators: this.getAllButtonBarButtons(view, BarTypeEnum.TOPBAR),
        buttonBarTitle: this.getAllTopBarTitles(view),
        titleEntityData: res
      };
    }));
  }

  initializeTopBar(inputFormData: InputFormData, inputData: InputData, topBarElementData: TopBarElements): Observable<any> {
    inputFormData.topBarElements = topBarElementData;
    inputData.titleEntityData = topBarElementData.titleEntityData;
    return this.getCallFunctionActivators(inputFormData.topBarElements.buttonActivators, inputData);

  }

  initializeViewBar(inputFormData: InputFormData, inputData: InputData, viewBarElements: ViewBarElements): Observable<any> {
    inputFormData.viewBarElements = viewBarElements;
    return this.getCallFunctionActivators(inputFormData.viewBarElements.buttonActivators, inputData);
  }


  navigateToEntity(entityHandle: number, action: Action): Observable<NavigationElement> {
    const entityDataRequest: EntityDataFromHandleRequest = {
      loadAnimation: false,
      Attributename: ['id'],
      Handle: entityHandle
    };

    return this.entityService.getEntityDataFromHandle(entityDataRequest).pipe(
      catchError(error => {
        return error;
      }), mergeMap((resEntityData: any) => {
          const entityData: EntityData = resEntityData;
          const entityId: string = entityData.Attributes.Attribute.find(r => r.Name === 'id').Value;
          const workflowView: WorkflowView = this.getWorkFlowAndViewFromEndpoint(action.endpoint);

          const navigationElements: NavigationElement = NavigationService.getRouteFromEndpoint(
            action.endpoint,
            this.configService.getConfiguration(),
            entityId,
            workflowView.tabWorkflowView ? workflowView.tabWorkflowView.view : null,
            null,
            null,
            workflowView.workflow, null, action.target === 'quickMenu');

          return of(navigationElements);
        },
      ));

  }

  convertTimeLineData(entityData: EntityData[],
                      timeLineConfig: Timeline,
                      timelineFilter: TimelineFilter,
                      timelineResourceData: TimeLineResourcesData): TimelineResult {
    const timelineData: TimelineData[] = [];
    let timelineResources: TimelineResource[] = timelineResourceData.timelineResources;
    const contextMenuResult: MenuItemModel[] = [];

    let i = 1;
    if (entityData) {
      entityData.forEach((entityDataX: EntityData) => {
        const subject: string[] = [];
        let roomId: number;

        if (timeLineConfig.yAxis) {
          const handle: any = entityDataX.Attributes.Attribute.find(r => r.Name === timeLineConfig.appointment.groupAttribute).Value;

          if (handle) {
            const resourceId: TimelineResource = timelineResourceData.timelineResources.find(r => r.handle === parseInt(handle, 10));
            if (resourceId) {
              roomId = handle;
            }
          }
        }

        if (roomId) {
          timeLineConfig.appointment.Subject.forEach((attribute: string) => {
            const subjectData: EntityData = entityDataX.Attributes.Attribute.find(r => r.Name === attribute);
            subject.push(subjectData.Value);
          });

          // const location: string = entityDataX.Attributes.Attribute.find(r => r.Name === timeLineConfig.yAxis).Value;
          const location: string = '';
          const startTimeEntityData: EntityData = entityDataX.Attributes.Attribute.find(r => r.Name === timeLineConfig.appointment.StartTime);
          const startTime: number = startTimeEntityData.Value['timestamp'] * 1000;
          const startTimeDate: Date = new Date(this.setDateTimeLocale('Time', 'en-US', startTimeEntityData.Value));

          let endTimeEntityData: EntityData = entityDataX.Attributes.Attribute.find(r => r.Name === timeLineConfig.appointment.StartTime);
          endTimeEntityData.Value['timestamp'] = endTimeEntityData.Value['timestamp'] + ((30 * 60)) + 1000;
          let endTimeDate: Date = new Date(this.setDateTimeLocale('Time', 'en-US', endTimeEntityData.Value));


          if (timeLineConfig.appointment.EndTime) {
            endTimeEntityData = entityDataX.Attributes.Attribute.find(r => r.Name === timeLineConfig.appointment.EndTime);
            endTimeDate = new Date(this.setDateTimeLocale('Time', 'en-US', endTimeEntityData.Value));
          }

          const id: string = entityDataX.Attributes.Attribute.find(r => r.Name.toLowerCase() === 'id').Value;

          timelineData.push({
            Id: id,

            text: subject.join(),
            Description: subject.join(),
            Subject: subject.join(),
            Location: location,
            StartTime: startTimeDate,
            EndTime: endTimeDate,
            CategoryColor: '#CCCCCC',
            RoomId: roomId,
            ProjectId: roomId,
            TaskId: roomId,
            AppointmentHandle: entityDataX.Handle
          });
        }
        i = i + 1;
      });
    } else {
      timelineResources = timelineResourceData.timelineResources;
    }
    const eventSettings: EventSettingsModel = {
      allowEditing: false,
      allowAdding: false,
      dataSource: extend([], timelineData, null, true) as Record<string, any>[],
      fields: {
        id: 'Id',
        subject: {title: 'Summary', name: 'Subject'},
        location: {title: 'Location', name: 'Location'},
        description: {title: 'Comments', name: 'Description'},
        startTime: {title: 'From', name: 'StartTime'},
        endTime: {title: 'To', name: 'EndTime'}
      }
    };


    if (timeLineConfig.contextMenu) {
      let i = 1;
      timeLineConfig.contextMenu.forEach((activator: Activator) => {
        contextMenuResult.push({iconCss: activator.icon, text: activator.name, id: activator.commandId});
        i++;
      });

    }

    return {
      timelineData,
      timelineResources,
      timelineRawData: entityData,
      eventSettings,
      contextMenu: contextMenuResult,
      timelineFilter,
      timelineCategories: timelineResourceData.timelineCategory
    };
  }


  navigateToWizard(entityHandle: number, action: Action): NavigationElement {
    const workflowView: WorkflowView = this.getWorkFlowAndViewFromEndpoint(action.endpoint);

    return NavigationService.getRouteFromEndpoint(
      action.endpoint,
      this.configService.getConfiguration(),
      null,
      workflowView.tabWorkflowView ? workflowView.tabWorkflowView.view : null,
      null,
      entityHandle,
      workflowView.workflow, null, action.target === 'quickMenu');
  }

  checkTabForRequiredFields(inputFormData: InputFormData, inputData: InputData, child: Children, dataService: DataService): Observable<any> {

    let entityId: string;
    let binding: string;
    let workflowView: WorkflowView;
    const childWorkflowView: WorkflowView = this.getWorkFlowAndViewFromEndpoint(child.path);


    if (inputFormData.tabData.tabsAttribute) {
      // Virtual Tabs Mode

      if (child.isVirtual) {
        workflowView = this.getWorkFlowAndViewFromEndpoint(inputFormData.tabData.tabsViewTemplate);
        entityId = child.entityId;
      } else {
        workflowView = this.getWorkFlowAndViewFromEndpoint(
          inputFormData.workflow.name + ':' + inputFormData.tabData.name + ':' + childWorkflowView.view.name);
        entityId = inputData.entityIdData ? inputData.entityIdData[0] : inputData.entityId;
      }

      return this.entitySmartService.getEntityHandle(childWorkflowView.view.binding, entityId).pipe(mergeMap((entityHandle: number) => {
        return this.checkViewForRequiredField(workflowView, entityHandle, inputData, dataService);
      }));

    } else {
      workflowView = this.getWorkFlowAndViewFromEndpoint(
        inputFormData.workflow.name + ':' + childWorkflowView.view.name);

      return this.checkViewForRequiredField(workflowView, inputData.entityHandle, inputData, dataService);
    }
  }

  checkViewForRequiredField(workflowView: WorkflowView,
                            entityHandle: number,
                            inputData: InputData,
                            dataService: DataService): Observable<WorkflowView> {
    const columns: Column[] = this.getColumns(workflowView.view.binding, workflowView.view);

    const attributeClassFields: AttributeClassField[] = this.getAttributeFields(workflowView.view.binding, workflowView.view);
    return this.entityService.getAttributeDescriptorArray(attributeClassFields).pipe(mergeMap((attributeDescriptorsRes: any) => {

      const entityDescriptors: EntityDescriptor[] = attributeDescriptorsRes;
      this.getRefInformations(columns, attributeDescriptorsRes);
      return dataService.getEntityDataFromHandle(workflowView.workflow, workflowView.view,
        entityHandle).pipe(mergeMap((entityData: EntityData) => {
        if (dataService.getRequiredFields(entityData, entityDescriptors, columns, inputData.formGroup).length > 0) {
          return of(workflowView);
        } else {
          return of(null);
        }
      }));
    }));
  }

  getAttributeNamesAndRefs(attributeNames) {
    let result: string[] = [];
    const refResult: string [] = [];
    attributeNames.forEach((attributeName: string) => {
      if (attributeName.indexOf('.') > -1) {
        const attributeNameArr: string[] = attributeName.split('.');
        if (refResult.indexOf(attributeNameArr[0]) === -1) {
          refResult.push(attributeNameArr[0]);
        }
      }
    });

    result = [...attributeNames, ...refResult];
    return result;
  }

  getAttributeHandoversSvg(action: Action, inputData: InputData): Observable<any> {
    const handovers: Handover[] = [];
    return from(action.attributeHandovers).pipe(mergeMap((handover: Handover) => {
      if (handover.data === 'idx') {
        const entityDataRequest: EntityDataRequest = {
          Classname: handover.binding,
          EntityId: inputData.svgData[handover.data],
          Attributename: ['id']
        };
        return this.entityService.getEntityData(entityDataRequest).pipe(map((entityData: EntityData) => {
          handovers.push({data: entityData.Handle, target: handover.target, binding: handover.binding});
          this.routingProviderService.setAttributeHandover(handovers);
          return true;
        }));
      } else {
        handovers.push({
          data: inputData.svgData[handover.data],
          target: handover.target,
          binding: handover.binding
        });
        this.routingProviderService.setAttributeHandover(handovers);
        return of(true);
      }
    }));
  }

  getDefaultValues(action: Action, inputData: InputData, inputFormData: InputFormData): Observable<any> {
    const resultHandovers: Handover[] = [];
    const attributes: string[] = [];
    const columns: Column[] = [];

    const endpoint: string = action.endpoint;
    const workflowView: WorkflowView = this.getWorkFlowAndViewFromEndpoint(endpoint);


    if (action.defaultValues && action.defaultValues.length > 0) {
      action.defaultValues.forEach((defaultValue: DefaultValues) => {
        attributes.push(defaultValue.attribute);
        columns.push({name: defaultValue.attribute, attribute: defaultValue.attribute});
      });
    }

    const attributeCLassFields: AttributeClassField[] = this.getAttributeFieldResult(workflowView.view.binding, columns);

    return this.entityService.getAttributeDescriptorArray(attributeCLassFields).pipe(mergeMap((attributeDescriptorsRes: any) => {
      const entityDescriptors: EntityDescriptor[] = attributeDescriptorsRes;
      if (action.defaultValues && action.defaultValues.length > 0) {
        const form = {};

        return from(action.defaultValues).pipe(mergeMap((defaultValues: DefaultValues) => {
          if (defaultValues.binding) {
            const entityListRequest: EntityListRequest = {
              AttributeNames: [defaultValues.referenceAttribute],
              Sortings: [],
              BaseFilter: [{
                ClassName: defaultValues.binding,
                FilterConditions: [{
                  FilterJoin: 'eAnd',
                  DataType: 'string',
                  FilterOperator: 'Equal',
                  Name: defaultValues.referenceAttribute,
                  Value: defaultValues.value,

                }]
              }],
              Start: 0,
              Limit: 0
            };
            return this.entityListService.getHandleAndEntityListData(entityListRequest).pipe((mergeMap((entityDataResult: any) => {
              const entityData: EntityData = entityDataResult.EntityDataArray.EntityData;
              resultHandovers.push({data: entityData[0].Handle, target: defaultValues.attribute});
              return of(true);
            })));
          } else {
            resultHandovers.push({data: defaultValues.value, target: defaultValues.attribute});
            return of(true);
          }
        }), finalize(() => {
          this.routingProviderService.setAttributeHandover(resultHandovers);
        }));

      }
    }));
  }

  getAttributeHandovers(action: Action, inputData: InputData, inputFormData: InputFormData): Observable<any> {
    const handovers: Handover[] = [];
    const attributes: string[] = [];
    const columns: Column[] = [];
    const className: string = inputFormData.tabData ? inputFormData.tabData.binding : inputFormData.view.binding;


    if (action.attributeHandovers && action.attributeHandovers.length > 0) {
      action.attributeHandovers.forEach((handover: Handover) => {
        attributes.push(handover.data);
        columns.push({name: handover.data, attribute: handover.data});
      });
    }


    const attributeCLassFields: AttributeClassField[] = this.getAttributeFieldResult(className, columns);
    let entityDataObs: Observable<any>;
    if (inputFormData.tabData && inputFormData.tabChild) {
      const entityDataRequest: EntityDataRequest = {
        EntityId: inputData.entityId,
        Classname: inputFormData.tabData.binding,
        Attributename: attributes
      };
      entityDataObs = this.entityService.getEntityData(entityDataRequest);

    } else {
      const getEntityDataFromHandleRequest: EntityDataFromHandleRequest = {Handle: inputData.entityHandle, Attributename: attributes};
      entityDataObs = this.entityService.getEntityDataFromHandle(getEntityDataFromHandleRequest);
    }

    return this.getEntityDescriptors(attributeCLassFields).pipe(mergeMap((attributeDescriptors: AttributeDescriptor) => {
      return entityDataObs.pipe(mergeMap((entityData: EntityData) => {
        const resultHandovers: Handover[] = [];
        action.attributeHandovers.forEach((handover: Handover) => {
          let value: string;

          if (handover.data === 'id') {
            value = entityData.Handle.toString();
          } else {
            const valueEntityData: EntityData = entityData.Attributes.Attribute.find(r => r.Value === handover.target);
            if (handover.dataType === HandoverTypeEnum.REF) {
              value = valueEntityData.Handle.toString();
            } else {
              value = valueEntityData.Value;
            }
          }
          resultHandovers.push({data: value, target: handover.target});
        });

        this.routingProviderService.setAttributeHandover(resultHandovers);
        return of(resultHandovers);
      }));
    }));
  }

  setAttributeHandoversFromCache(entityHandle: number, entityDescriptors: EntityDescriptor[]): Observable<any> {

    if (this.routingProviderService.attributeHandOvers && this.routingProviderService.attributeHandOvers.length > 0) {

      return from(this.routingProviderService.attributeHandOvers).pipe(mergeMap((attributeHandover: Handover) => {
        const entityDescriptor: EntityDescriptor = entityDescriptors.find(r => r.Name === attributeHandover.target);
        const namedValue: NamedValue = {
          Name: attributeHandover.target,
          Value: attributeHandover.data,
          DataType: entityDescriptor.DataType
        };
        const setEntityObs: Observable<any> = this.entitySmartService.setAttributeHandover(namedValue, entityHandle);
        this.callbackService.setInitialRequest(setEntityObs);
        return setEntityObs;

      }), catchError((error) => {
        console.log(error);
        return error;
      }), finalize(() => {
        this.routingProviderService.clearAttributeHandovers();
      }));
    } else {
      return of(true);
    }

  }


  checkActivatorPermissions(activatorsView: View | ButtonBar): Observable<Activator[]> {
    const activatorsResult: Activator[] = [];
    let activators: Activator[] = [];
    if (activatorsView.activators && activatorsView.activators.length > 0) {
      activators = activatorsView.activators;
    }

    if (activatorsView && activatorsView.filters) {
      if (activatorsView.filters && activatorsView.filters.length > 0 && activatorsView.filters[0].activators && activatorsView.filters[0].activators.length > 0) {
        activators = activatorsView.filters[0].activators;
      }
    }

    return from(activators).pipe(concatMap((activator: Activator) => {
      if ((!activator.action || !activator.action.endpoint)) {
        activator.permission = 1024;
        activatorsResult.push(activator);
        return of(activatorsResult);
      } else {
        let childrenActivatorObs: Observable<any>;

        return this.permissionService.checkActivatorPermissions(activator).pipe(map((activatorPermission: Activator) => {
          activatorsResult.push(activatorPermission);
          return activatorsResult;
        }));


      }

    }), finalize(() => {
      activatorsView.activators = activatorsResult;
    }));
  }

  getTabView(tabView, entityHandle: number = null): Observable<any> {

    return from(tabView.children).pipe(mergeMap((children: Children) => {
      if (children.attribute && entityHandle) {
        const workflowView: WorkflowView = this.getWorkFlowAndViewFromEndpoint(children.path);

        const attributeClassFields: AttributeClassField[] = [{
          Attributename: children.attribute,
          Classname: tabView.binding
        }];

        return this.getEntityDescriptors(attributeClassFields).pipe(mergeMap((res: any) => {
          if ((res[0].Flags & EntityDescriptorFlagsEnum.FLAG_HAS_READ_PRIVILEGE) !== 0) {
            children.permission = EntityDescriptorFlagsEnum.FLAG_HAS_READ_PRIVILEGE;
          } else {
            children.permission = EntityDescriptorFlagsEnum.FLAG_HAS_NO_PRIVILEGE0;
          }
          const entityDataRequest: EntityDataFromHandleRequest = {Handle: entityHandle, Attributename: [children.attribute]};
          return this.entityService.getEntityDataFromHandle(entityDataRequest).pipe(map((entityData: EntityData) => {
            const entityDataX: EntityData = entityData.Attributes.Attribute.find(r => r.Name === children.attribute);
            if (entityDataX.Flags < 2) {
              children.permission = EntityDescriptorFlagsEnum.FLAG_HAS_NO_PRIVILEGE0;
            }
            return tabView;
          }));
        }), catchError(error => {
            console.log(error);
            return error;
          }
        ));
      } else {
        return of(tabView);
      }
    }));
  }

  navigateToStep(inputFormData: InputFormData, inputData: InputData, workflowView: WorkflowView): void {
    const navigationElement: NavigationElement = NavigationService.getRouteFromEndpoint(
      inputFormData.workflow.name + ':' + inputFormData.tabData.name,
      this.configService.getConfiguration(),
      null,
      workflowView.view,
      workflowView.view.defaultFilterFileName ? {file: workflowView.view.defaultFilterFileName} : null,
      inputData.entityHandle, workflowView.workflow
    );
    this.router.navigate(navigationElement.urlArr, {queryParams: navigationElement.dashboardFilter});
  }
}
