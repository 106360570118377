import {Inject, Injectable} from '@angular/core';
import {from, NEVER, Observable} from 'rxjs';
import {finalize, map, tap} from 'rxjs/operators';
import {HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {LoaderService} from './loader.service';
import {ApiConfiguration} from './swagger/api-configuration';
import {ConfigService} from './config.service';
import {DOCUMENT} from '@angular/common';
import {Message, MessageEnum, MessageTypeEnum, PitException, PitExceptionDetail} from '../../pit/structure';
import {RoutingProviderService} from '../../pit/services/routing-provider.service';
import {MessageStackService} from 'src/app/shared/services/message-stack.service';
import {CallbackService} from './callback.service';
import {TranslationService} from './translation.service';


interface ApiInterceptorInterface {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>;
}


@Injectable({
  providedIn: 'root'
})
export class ApiInterceptorService implements ApiInterceptorInterface {


  apiConfiguration = new ApiConfiguration();

  loaderCount = 0;


  constructor(
    @Inject(DOCUMENT) public document: Document,
    private snackbar: MatSnackBar,
    private router: Router,
    private configService: ConfigService,
    private routingProviderService: RoutingProviderService,
    private translationService: TranslationService,
    private loaderService: LoaderService,
    public messageStackService: MessageStackService,
    private callBackService: CallbackService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const that = this;

    let originUrl: string = req.url;
    const urlArr: string[] = originUrl.split('/');

    const apiUrl: string = this.apiConfiguration.rootUrl;
    const realApiUrl: string = this.configService.getConnectionSettings() ? this.configService.getConnectionSettings().backend_url : null;

    if (realApiUrl !== null) {
      originUrl = originUrl.replace(apiUrl, realApiUrl);
    }


    if (localStorage.getItem('sessionId') === null) {
      const headers = req.headers;

      req = req.clone({
        url: originUrl,
        withCredentials: true,
        headers
      });

    } else {

      req = req.clone({
        url: originUrl,
        setHeaders: {
          Authorization: localStorage.getItem('sessionId'),
        }
      });
    }
    if (req.body && req.body.loadAnimation === false || this.routingProviderService.isDisabledLoaderDarkening) {
    } else {
      if (urlArr[urlArr.length - 1] !== 'session') {
        this.loaderService.show();

      }

    }


    this.loaderCount++;
    return next.handle(req).pipe(map((res: any) => {
        return res;
      }),
      tap(x => x, (err: Error | any) => {


        that.loaderService.hide();
        if (err.error && err.error.errorDetail && err.error.errorDetail.type && err.error.errorDetail.type === 'ClientCallbackMessageBoxQuery') {
          // const encValue: any = err.error.errorDetail.CallbackQueryPendingFault.QueryObject.enc_value;
          this.callBackService.setMessageBox(err.error.errorDetail.message, err.error.errorDetail.buttonsAndIconsType, err.error.errorDetail.queryTypeId);
          this.callBackService.openCallBackDialog();

        } else {
          if (err.error && err.error.detail) {
            let errorTranslation: string = this.translationService.getTranslation('error', 'e' + err.error.code);

            if (!this.routingProviderService.isSessionTimeout) {
              if ((err.error.message && err.error.message.indexOf('Session doesn') > -1)
                || (err.error.detail && err.error.detail.indexOf('Session doesn') > -1)) {
                errorTranslation = this.translationService.getTranslation('default_messages', 'no_session'  );
                this.routingProviderService.isSessionTimeout = true;
                localStorage.removeItem('sessionId');
                localStorage.removeItem('user');
                localStorage.removeItem('userRole');
                this.router.navigate(['login']).then(() => {


                  const message: Message = {
                    title: this.translationService.getTranslation('default_messages', 'Hinweis'),
                    text: errorTranslation,
                    type: MessageTypeEnum.NOTICE
                  };
                  // this.messageStackService.addMessage(message);

                });
              } else {

                const message: Message = {
                  title: this.translationService.getTranslation('default_messages', 'Fehler'),
                  text: errorTranslation,
                  type: MessageTypeEnum.ERROR
                };

                this.messageStackService.addMessage(message);
              }
            }
          }

        }
      }),
      finalize(() => {
        this.loaderCount = this.loaderCount - 1;
        if (this.loaderCount === 0) {
          that.loaderService.hide();
        }
      })
    );


  }

  // getQueryMessageBoxType(queryBoxDetail: any) {
  //   const encValue: any = err.error.detail.CallbackQueryPendingFault.QueryObject.enc_value;
  //   this.callBackService.setMessageBox(encValue.Message, encValue.Type, err.error.QueryTypeId);
  //   this.callBackService.openCallBackDialog();
  //
  // }
}
