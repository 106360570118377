import {Inject, Injectable} from '@angular/core';
import {Params, Router} from '@angular/router';
import {
  Action,
  Activator,
  ChildPlacementEnum,
  Children, Column,
  Configuration,
  Dashboard,
  FilterFile, FilterParameterTypeEnum,
  Menu, queryParamArray,
  SortingEnum, UrlArrayQueryParams,
  View,
  ViewEnum,
  Workflow,
  WorkflowView
} from '../../pit/structure';
import {FormDataService} from './form-data.service';
import {ConfigService} from './config.service';

import {DashboardDataService} from './dashboard-data.service';
import {DOCUMENT} from '@angular/common';
import {InputDashboardData, InputData, InputFormData} from '../../pit/input-data';
import {DashboardFilter} from '../models/DashboardFilter';
import {BaseFilters} from './swagger/models/base-filters';
import {DashboardList} from '../interfaces/dashboard-list';
import {UserRole} from './swagger/models/user-role';
import {DataService} from './data.service';
import {Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {ListService} from './list.service';
import {EntitySmartService} from './entity-smart.service';
import {EntityData} from './swagger/models/entity-data';
import {FilterParameter} from './swagger/models/filter-parameter';
import {Client} from './swagger/models/client';


export interface NavigationElement {
  urlArr?: string[];
  urlPath?: string;
  dashboardFilter: DashboardFilter;
  endpoint?: string;
  entityId?: string;
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  activeComponent: string;

  className: string;

  workflowName: string;

  entityId: string;

  viewType: string;

  viewEnum = ViewEnum;

  isNavigatingBackFromListView: boolean;

  constructor(@Inject(DOCUMENT) private document: Document,
              private router: Router,
              private formDataService: FormDataService,
              private listService: ListService,
              private entitySmartService: EntitySmartService,
              private configService: ConfigService) {
  }

  clearRouteInfos(): void {
    this.activeComponent = null;
    this.className = null;
    this.entityId = null;
  }

  getClassName(): string {
    return this.className;
  }

  setWorkflowName(workflowName: string): void {
    this.workflowName = workflowName;
  }

  public static getRouteFromEndpoint(endpoint: string,
                                     configuration: Configuration,
                                     entityId: string = null,
                                     targetTab: View = null,
                                     filter: FilterFile = null,
                                     entityHandle: number = null,
                                     targetWorkflow: Workflow = null,
                                     baseFilters: BaseFilters[] = null,
                                     isQuickMenu: boolean = null,
                                     virtualEntityId: string = null,
                                     filterParameter: string = null): NavigationElement {

    const resultArr: string[] = [];
    let dashboardFilter: DashboardFilter;
    let view: View;
    let workflow: Workflow;
    const urlArr: string[] = endpoint.split(':');
    resultArr.push(urlArr[0]);


    switch (urlArr[1]) {
      default:
        workflow = configuration.workflows.find(r => r.name === urlArr[0]);
        if (urlArr[1]) {
          view = workflow.views[urlArr[1]];
          if (view.type === ViewEnum.WIZARD) {
            localStorage.setItem('isNavigatingToEntity', JSON.stringify(true));
            resultArr.push(ViewEnum.WIZARD);
            resultArr.push(urlArr[1]);
            resultArr.push('handle');

            if (entityHandle) {
              resultArr.push(entityHandle.toString());
            }


            resultArr.push('step');

            if (targetTab === null) {
              targetTab = FormDataService.getFirstDetailViewTab(workflow.views, view.binding);
            }

            resultArr.push(targetTab.name);


            if (targetWorkflow && targetTab) {
              if (targetTab.type === 'list') {
                dashboardFilter = DashboardDataService.getDefaultDashboardFilter();

                if (filter && filter.file !== '') {
                  dashboardFilter.filterFile = filter.file;
                  dashboardFilter.sortField = filter.defaultFilterSortingField;
                  dashboardFilter.sortDirection = filter.defaultFilterSortingDirection;
                } else {
                  if (targetTab.defaultFilterFileName) {
                    dashboardFilter.filterFile = targetTab.defaultFilterFileName;
                  }
                }


              } else {
                dashboardFilter = DashboardDataService.getDefaultDashboardFilter();

                if (baseFilters) {

                  dashboardFilter.baseFilter = JSON.stringify(baseFilters);
                  dashboardFilter.allFilterKeyword = false;
                  dashboardFilter.time = new Date().toTimeString();
                }
              }

            }

          } else {
            resultArr.push('view');
            resultArr.push(urlArr[1]);

            if (isQuickMenu) {
              resultArr.push('quick');
            }

            if (view) {
              if (entityId !== null) {
                localStorage.setItem('isNavigatingToEntity', JSON.stringify(true));
                resultArr.push(entityId);

                if (virtualEntityId) {
                  resultArr.push('vEntityId');
                  resultArr.push(virtualEntityId);

                }
              }

              if (entityId === null && entityHandle) {
                localStorage.setItem('isNavigatingToEntity', JSON.stringify(true));
                resultArr.push('handle');
                resultArr.push(entityHandle.toString());
              }

              if (targetTab === null) {

                if (view.type === 'list') {
                  dashboardFilter = DashboardDataService.getDefaultDashboardFilter();

                  if (baseFilters) {
                    dashboardFilter.baseFilter = JSON.stringify(baseFilters);
                    dashboardFilter.allFilterKeyword = false;
                  }

                  if (filterParameter) {
                    dashboardFilter.filterBarParams = filterParameter;
                  }

                  if (filter && filter.file !== '') {
                    dashboardFilter.filterFile = filter.file;
                    dashboardFilter.sortField = filter.defaultFilterSortingField;
                    dashboardFilter.sortDirection = filter.defaultFilterSortingDirection;
                  } else {
                    if (view.defaultFilterFileName) {
                      dashboardFilter.filterFile = view.defaultFilterFileName;
                      dashboardFilter.sortField = view.defaultFilterSortingField ? targetTab.defaultFilterSortingField : null;
                      dashboardFilter.sortDirection = view.defaultFilterSortingDirection ? targetTab.defaultFilterSortingDirection : 'eAscending';
                    }
                  }

                  if (view.sortField) {
                    dashboardFilter.sortField = view.sortField;
                    dashboardFilter.sortDirection = view.sortDirection ? view.sortDirection : SortingEnum.E_ASCENDING;
                  }

                }
              } else {

                if (targetWorkflow && targetTab) {
                  if (targetTab.type === 'list') {
                    dashboardFilter = DashboardDataService.getDefaultDashboardFilter();

                    if (filter && filter.file !== '') {
                      dashboardFilter.filterFile = filter.file;
                      dashboardFilter.sortField = filter.defaultFilterSortingField;
                      dashboardFilter.sortDirection = filter.defaultFilterSortingDirection;
                    } else {
                      if (targetTab.defaultFilterFileName) {
                        dashboardFilter.filterFile = targetTab.defaultFilterFileName;
                        dashboardFilter.sortField = targetTab.defaultFilterSortingField ? targetTab.defaultFilterSortingField : null;
                        dashboardFilter.sortDirection = targetTab.defaultFilterSortingDirection ? targetTab.defaultFilterSortingDirection : 'eAscending';
                      }
                    }


                  } else {
                    dashboardFilter = DashboardDataService.getDefaultDashboardFilter();

                    if (baseFilters) {

                      dashboardFilter.baseFilter = JSON.stringify(baseFilters);
                      dashboardFilter.allFilterKeyword = false;
                      dashboardFilter.time = new Date().toTimeString();
                    }
                  }
                  resultArr.push('tab');
                  resultArr.push(targetWorkflow.name + ':' + targetTab.name);

                }
              }

            }


            if (entityId === null && entityHandle) {
              resultArr.push('show');
              resultArr.push('entity');
            }
          }

        }
        break;
    }

    return {urlArr: resultArr, dashboardFilter, endpoint, entityId: entityId ? entityId : null};
  }

  // navigateToNewEntity(tabData: View): string[] {
  //   const tab: Children = tabData.children.find(r => r.name === 'Detail');
  //   const navigationElements: NavigationElement = this.getRouteFromEndpoint(tab.path, this.configService.getConfiguration());
  //   navigationElements.urlArr.push('new-entity');
  //   return navigationElements.urlArr;
  // }


  getFirstAction(menus: Menu[] | Children[], userRole: UserRole, activeClient: Client): Action {
    let action: Action;
    let menu: Menu;

    for (let i = 0; i < menus.length; i++) {

      menu = menus[i];
      if (this.isMenuPermitted(menu, userRole, activeClient)) {
        action = menu.action;

        break;
      }
      if ((menu.children && menu.children.length > 0)) {
        action = this.getFirstAction(menu.children, userRole, activeClient);
        break;
      }

    }

    return action;
  }


  getMenuByEndpoint(menus: Menu[] | Children[], endpoint: string): Menu {
    let menu: Menu;
    let result: Menu;

    for (let i = 0; i < menus.length; i++) {
      menu = menus[i];

      if (menu.action && menu.action.endpoint === endpoint) {
        result = menu;
        break;
      }
      if (menu.children && menu.children.length > 0) {
        result = this.getMenuByEndpoint(menu.children, endpoint);
        if (result) {
          break;
        }
      }
    }

    return result;
  }

  isMenuPermitted(menu: Menu, userRole: UserRole, activeClient: Client): boolean {
    return !!(menu.action && menu.action.type === 'route'
      && ((!menu.userRoles || menu.userRoles.length === 0 || menu.userRoles.find(r => r === userRole.Name)) &&
        (!menu.allowedClients || menu.allowedClients.length === 0 || menu.allowedClients.find(r => r === activeClient.Name))));
  }

  // checkPermissionByEndpoint(endpoint: string, menus: Menu[] | Children[], userRole: UserRole): Menu | Children {
  //   let resultMenu: Menu;
  //   let menu: Menu;
  //
  //   for (let i = 0; i < menus.length; i++) {
  //     menu = menus[i];
  //
  //     if (menu.action && menu.action.endpoint === endpoint) {
  //       resultMenu = menu;
  //       break;
  //     }
  //
  //     if (menu.children && menu.children.length > 0) {
  //       resultMenu = this.checkPermissionByEndpoint(endpoint, menu.children, userRole);
  //       break;
  //     }
  //   }
  //
  //   if (resultMenu) {
  //     if (this.isMenuPermitted(resultMenu, userRole, this.activ)) {
  //       return resultMenu;
  //     } else {
  //       return null;
  //     }
  //   } else {
  //     return null;
  //   }
  // }


  removeVirtualTabs(workflowName: string, tabViewName: string, configuraiton: Configuration): Configuration {
    const workflow: Workflow = configuraiton.workflows.find(r => r.name === workflowName);
    const view: View = workflow.views[tabViewName];

    const children: Children[] = [];

    if (view.children && view.children.length > 0) {
      view.children.forEach((child: Children) => {
        if (!child.isVirtual) {
          children.push(child);
        }
      });
      view.children = children;
    }

    return configuraiton;

  }

  generateVirtualTabs(endpoint: string, entityId: string, configuration): Observable<any> {
    let targetTabView: View = null;
    let targetTabWorkflow: Workflow = null;
    let targetView: string;

    // targetView = inputFormData.view.activators[0].action.endpoint;

    let targetViewArr = endpoint.split(':');

    const targetWorkflow: Workflow = configuration.workflows.find(r => r.name === targetViewArr[0]);


    const workFlowView: WorkflowView =
      NavigationService.getWorkFlowAndViewFromEndpoint(targetWorkflow.views[targetViewArr[1]].tabsViewTemplate, configuration);
    targetTabWorkflow = workFlowView.workflow;
    targetTabView = workFlowView.view;

    return this.entitySmartService.getEntityHandle(targetWorkflow.views[targetViewArr[1]].binding,
      entityId).pipe(mergeMap((handle: number) => {
      return this.listService.getListDataByAttribute(targetWorkflow.views[targetViewArr[1]].tabsAttribute, handle,
        targetWorkflow.views[targetViewArr[1]], null).pipe(mergeMap((entityDataResult: any) => {
        const tabChildren: Children[] = [];
        const entityData: EntityData[] = entityDataResult.EntityDataArray.EntityData;

        this.configService.setConfiguration(this.removeVirtualTabs(targetViewArr[0], targetViewArr[1], configuration));

        let i = 0;
        const viewCopy: View = Object.assign({}, workFlowView.view);


        if (targetWorkflow.views[targetViewArr[1]].children && targetWorkflow.views[targetViewArr[1]].children.length > 0) {
          targetWorkflow.views[targetViewArr[1]].children.forEach((child: Children) => {
            if ((child.placement && child.placement === ChildPlacementEnum.BEFORE) || !child.placement) {
              tabChildren.push(child);
            }
          });
        }


        entityData.forEach((entityDataRow: EntityData) => {
          const nameEntityData: EntityData = entityDataRow.Attributes.Attribute.find(r => r.Name === targetWorkflow.views[targetViewArr[1]].tabsTitle);

          tabChildren.push({
            path: targetWorkflow.views[targetViewArr[1]].tabsViewTemplate + i,
            icon: 'xxx.png',
            type: 'detail',
            name: nameEntityData.Value,
            isVirtual: true,
            entityId: entityDataRow.Attributes.Attribute.find(r => r.Name === 'id').Value
          });

          workFlowView.workflow.views[viewCopy.name + i] = Object.assign({}, viewCopy);
          workFlowView.workflow.views[viewCopy.name + i].name += i;
          i = i + 1;
        });


        if (targetWorkflow.views[targetViewArr[1]].children && targetWorkflow.views[targetViewArr[1]].children.length > 0) {
          targetWorkflow.views[targetViewArr[1]].children.forEach((child: Children) => {
            if (child.placement && child.placement === ChildPlacementEnum.BEHIND) {
              tabChildren.push(child);
            }
          });
        }

        targetWorkflow.views[targetViewArr[1]].isEnrichedWithVirtualTabs = true;
        targetWorkflow.views[targetViewArr[1]].children = tabChildren;
        this.configService.setConfiguration(configuration);
        const copyTargetTabView: View = Object.assign({}, targetTabView);

        const copyTargetTabWorkflowView: WorkflowView = this.formDataService.getWorkFlowAndViewFromEndpoint(tabChildren[0].path);

        // const workflow: Workflow = this.formDataService.getWor


        const navigationElement: NavigationElement = NavigationService.getRouteFromEndpoint(
          endpoint,
          configuration,
          entityId,
          copyTargetTabWorkflowView.view,
          null,
          null,
          copyTargetTabWorkflowView.workflow, null, null, tabChildren[0].entityId);
        return of(navigationElement);
      }));
    }));


  }

  navigateToTabView(
    activators: Activator[],
    filters: FilterFile[],
    entityId: string,
    configuration: Configuration,
    inputDashboardData: InputDashboardData = null,
    entityHandle: number = null): Observable<any> {
    let targetTabView: View = null;
    let targetTabWorkflow: Workflow = null;

    let targetView: string;
    let targetViewArr: string[];

    if (filters && filters.length > 0 && inputDashboardData.dashboardFilter.filterFile) {
      const filterVIew: FilterFile = filters.find(r => r.file === inputDashboardData.dashboardFilter.filterFile);
      targetView = filterVIew.activators[0].action.endpoint;
    }

    if (activators && activators.length > 0) {
      targetView = activators[0].action.endpoint;
    }

    targetViewArr = targetView.split(':');
    const targetWorkflow: Workflow = configuration.workflows.find(r => r.name === targetViewArr[0]);

    if (targetWorkflow.views[targetViewArr[1]].tabsAttribute && targetWorkflow.views[targetViewArr[1]].tabsAttribute !== '') {
      return this.generateVirtualTabs(targetView, entityId, this.configService.getConfiguration());
    }

    if (targetWorkflow.views[targetViewArr[1]].children && targetWorkflow.views[targetViewArr[1]].children.length > 0) {
      const targetTabEndpoint: string = targetWorkflow.views[targetViewArr[1]].children[0].path;
      const targetTabEndpointArr: string[] = targetTabEndpoint.split(':');

      targetTabWorkflow = configuration.workflows.find(r => r.name === targetTabEndpointArr[0]);
      targetTabView = targetTabWorkflow.views[targetTabEndpointArr[1]];
    }

    if ((activators && activators.length > 0) || (filters && filters.length > 0)) {
      return of(NavigationService.getRouteFromEndpoint(
        targetView,
        configuration,
        entityId,
        targetTabView,
        null,
        entityHandle,
        targetTabWorkflow));

    }
    return of(null);
  }

  navigateToList(endpoint: string, view: DashboardList, filter: FilterFile, config: Configuration): void {
    const navigationElement: NavigationElement =
      NavigationService.getRouteFromEndpoint(endpoint, config, null, null, filter);


    if (view.sortField) {
      navigationElement.dashboardFilter.sortField = view.sortField;
      navigationElement.dashboardFilter.sortDirection = view.sortDirection ? view.sortDirection : SortingEnum.E_ASCENDING;
    }


    this.router.navigate(navigationElement.urlArr, {queryParams: navigationElement.dashboardFilter}).then(() => {
    });

  }

  getActionByEndpoint(endpoint: string, menus: Menu[] | Children[]): Action {
    let result: Action;

    for (let i = 0; i < menus.length; i++) {
      const menu: Menu = menus[i];

      if (menu.action && menu.action.endpoint === endpoint) {
        result = menu.action;
        break;
      }

      if (menu.children && menu.children.length > 0) {
        result = this.getActionByEndpoint(endpoint, menu.children);
        if (result) {
          break;
        }

      }
    }
    return result;
  }

  navigateFromMenu(action: Action, dataService: DataService): void {
    let filterFile: FilterFile;

    if (action.defaultFilter) {
      filterFile = action.filters.find(r => r.name === action.defaultFilter);
    }
    document.body.classList.remove('main-sidebar-open');
    document.body.classList.remove('quick-menu-open');

    const workflowView: WorkflowView = this.formDataService.getWorkFlowAndViewFromEndpoint(action.endpoint);
    if (workflowView.view && workflowView.view.type === ViewEnum.WIZARD) {

      const inputData: InputData = {};
      this.formDataService.createEntityObs(action, inputData, dataService, dataService.callbackService, this, this.router).subscribe((res: any) => {

        document.body.classList.remove('main-sidebar-open');
        document.body.classList.remove('quick-menu-open');

        if (res && res.urlArr) {
          this.router.navigate(res.urlArr, res.dashboardFilter ?
            {queryParams: res.dashboardFilter} : {}).then(() => {
            res.lastEntityId = null;
          });
        }

      });

    } else {

      let navigationElements: NavigationElement;

      if (action.mode === 'dashboard') {
        navigationElements = {urlArr: ['dashboard', action.endpoint], dashboardFilter: null};
      } else {
        navigationElements = NavigationService.getRouteFromEndpoint(action.endpoint,
          this.formDataService.configService.getConfiguration(), null, null, filterFile);
      }

      this.router.navigate(navigationElements.urlArr, {queryParams: navigationElements.dashboardFilter}).then(() => {

      });
      document.body.classList.remove('main-sidebar-open');
      document.body.classList.remove('quick-menu-open');
    }
  }

  getTabsByAttribute(view: View, entityId: string): Observable<any> {
    const className: string = view.binding;


    return this.entitySmartService.getEntityHandle(className, entityId).pipe(mergeMap((handle: any) => {
      return this.listService.getListDataByAttribute(view.tabsAttribute, handle, view, null, null).pipe(mergeMap((res: any) => {

        return of(res);
      }));
    }));
  }

  public static getWorkFlowAndViewFromEndpoint(endpoint: string, config: Configuration): WorkflowView {
    let view: View;
    let tabWorkflowView: WorkflowView = null;
    const endpointNameArr: string[] = endpoint.split(':');
    const workflow: Workflow = config.workflows.find(r => r.name === endpointNameArr[0]);

    if (workflow) {
      let tabData: View = null;
      view = workflow.views[endpointNameArr[1]] ? workflow.views[endpointNameArr[1]] : null;

      if (endpointNameArr.length === 3) {
        const tabView: View = workflow.views[endpointNameArr[2]] ? workflow.views[endpointNameArr[2]] : null;
        view.name = endpointNameArr[1];

        tabData = workflow.views[endpointNameArr[1]];
        tabWorkflowView = NavigationService.getWorkFlowAndViewFromEndpoint(workflow.name + ':' + tabView.name, config);
      } else {
        if (view) {
          if (view.children && view.children.length > 0) {
            view.name = endpointNameArr[1];
            const firstTabChild: Children = view.children[0];
            tabWorkflowView = NavigationService.getWorkFlowAndViewFromEndpoint(firstTabChild.path, config);
          }
        }
      }
    }

    return {workflow, view, tabWorkflowView};
  }

  static getDashboardConfig(dashboardName: string, config: Configuration): Dashboard {
    return config.dashboards.find(r => r.name === dashboardName);
  }

  getFilterParameters(view: View, params: Params): FilterParameter[] {
    const filterParameters: FilterParameter[] = [];
    if (params && params.filterBarParams) {

      for (let key in params?.filterBarParams) {
        if (key !== 'dashboardTime') {
          const keyArray: string[] = key.split('_');


          if (key.indexOf('_entity') > -1) {
            const newKey: string = keyArray.slice(0, keyArray.length - 1).join('_');
            const filterParameter: FilterParameter = filterParameters.find(r => r.key === newKey);
            const filterParameterConfig: Column = view.filterBar.columns.find(r => r.name === newKey);

            if (filterParameterConfig) {
              const dataType: string = filterParameterConfig.filterParameterType;
              let value: string;
              if (dataType === FilterParameterTypeEnum.STRING) {
                value = params.filterBarParams[newKey];
              } else {
                value = params.filterBarParams[key];
              }

              if (!filterParameter) {
                filterParameters.push({key: newKey, value, dataType});
              } else {
                filterParameter.value = value;
                filterParameter.dataType = dataType;
              }
            }
          } else {
            filterParameters.push({key, value: params.filterBarParams[key], dataType: 'Date'});
          }

        }
      }
    }

    return filterParameters;
  }

  static parseUrl(url: string): UrlArrayQueryParams {
    const [baseUrl, queryString] = url.split('?');
    const queryParams: queryParamArray = {};
    const urlArray: string[] = baseUrl.split('/');

    if (queryString) {
      queryString.split('&').forEach(param => {
        const [key, value] = param.split('=');
        queryParams[key] = decodeURIComponent(value || '');
      });
    }

    return {urlArray, queryParams};
  }

}
