import {Injectable} from '@angular/core';

import {BaseFilters} from './swagger/models/base-filters';
import {DashboardFilter} from '../models/DashboardFilter';
import {AttributeClassField} from './swagger/models/attribute-class-field';
import {InputDashboardData, InputData, InputFormData, KeyValue} from '../../pit/input-data';
import {EntityListRequest} from './swagger/models/entity-list-request';
import {FormControl, FormGroup} from '@angular/forms';
import {Activator, Column, FilterFile, FormatEnum, ListControlEnum, ListSettingsTypeEnum, View} from '../../pit/structure';
import {
  EntityData,
  EntityDescriptor,
  FilterCondition,
  FilterParameter,
  FilterParameterDescriptor,
  GetFilterParameterDescriptorsRequest,
  Value
} from './swagger/models';
import {FormDataService} from './form-data.service';
import {Observable} from 'rxjs';
import {EntitylistService} from './swagger/services/entitylist.service';
import {Params, Router} from '@angular/router';
import {DataRow} from '../../pit/views/datagrid/structure';
import {ResponsivenessService} from './responsiveness.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardDataService {

  private attributeClassFields: AttributeClassField[] = [];

  private dashboardFilter: DashboardFilter;

  private entityListRequest: EntityListRequest = {
    AttributeNames: [],
    BaseFilter: [],
    Start: 0,
    Limit: 20,
    Sortings: []
  };

  private pageCount: number[] = [];

  private paginationView: number[] = [];

  entityCount: number;

  adjustedNameArray: string[] = [];

  static getDefaultDashboardFilter(params: any = {}): DashboardFilter {
    const date: Date = new Date();

    return {
      filterFile: params['filterFile'] ? params['filterFile'] : '',
      keyword: params['keyword'] ? params['keyword'] : '',
      baseFilter: params['baseFilter'] ? params['baseFilter'] : '',
      page: params['page'] ? params['page'] : 1,
      start: params['start'] ? params['start'] : 0,
      limit: params['limit'] ? params['limit'] : 20,
      sortField: params['sortField'] ? params['sortField'] : 'ID',
      sortDirection: params['sortDirection'] ? params['sortDirection'] : 'eAscending',
      time: date.getTime() + '' + date.getMilliseconds()
    };
  }


  constructor(
    private formDataService: FormDataService,
    private entityListService: EntitylistService,
    private responsivenessService: ResponsivenessService
  ) {
  }


  setAttributeClassFields(attributeClassFields: AttributeClassField[]): void {
    this.attributeClassFields = attributeClassFields;
  }

  getDashboardFilter(): DashboardFilter {
    return this.dashboardFilter;
  }

  setDashboardFilter(queryParams: Params): DashboardFilter {
    const params: any = queryParams.valueOf();
    return DashboardDataService.getDefaultDashboardFilter(params);
  }


  setSorting(dashboardRequest: EntityListRequest, dashboardFilter: DashboardFilter): void {
    this.setDashboardSorting(dashboardRequest, dashboardFilter);
  }

  switchSorting(sortField: string): void {
    this.dashboardFilter.sortField = sortField;
    this.dashboardFilter.sortDirection = this.dashboardFilter.sortDirection === 'eAscending' ? 'eDescending' : 'eAscending';
    // this.setDashboardSorting();
  };

  setDashboardSorting(dashboardRequest: EntityListRequest, dashboardFilter: DashboardFilter): void {
    dashboardRequest.Sortings = [];
    dashboardRequest.Sortings.push({
      ComplexAttributeName: dashboardFilter.sortField,
      SortingDirection: dashboardFilter.sortDirection
    });
  }

  setDashboardRequestBaseFilters(baseFilters: BaseFilters[]): void {
    this.entityListRequest.BaseFilter = baseFilters;
  }

  getDashboardRequest(): EntityListRequest {
    this.entityListRequest.ActualEntityHandle = null;

    return this.entityListRequest;
  }

  setPageCount(limit: number, entityCount: number, activePage: number): number[] {
    const pageCount: number[] = [];

    if (limit === 0) {
      return null;
    }
    const count = Math.ceil(entityCount / limit);
    for (let i = 0; i < count; i++) {
      pageCount.push(i);
    }

    return this.setPaginationView(pageCount, activePage);
  }

  getPaginationView(): number[] {
    return this.paginationView;
  }

  setPaginationView(pageCount: number[], activePage: number): number[] {
    const paginationView: number[] = [];
    let paginationStart = 1;
    let paginationEnd = pageCount.length + 1;

    if (this.responsivenessService.isTabletOrMobileView() && pageCount.length > 3) {
      if (activePage > 2) {
        paginationStart = activePage - 1;
        paginationEnd = activePage + 2;
      } else {
        paginationEnd = activePage + 3;
      }

      if (paginationEnd > pageCount.length) {
        paginationEnd = pageCount.length + 1;
        paginationStart = paginationEnd - 3;
      }
    } else {
      if (!this.responsivenessService.isTabletOrMobileView() && pageCount.length > 8) {
        if (activePage > 5) {
          paginationEnd = activePage + 4;
          paginationStart = activePage - 4;
        } else {
          paginationEnd = paginationStart + 8;
        }

        if (paginationEnd >= pageCount.length) {
          paginationEnd = pageCount.length + 1;
          paginationStart = paginationEnd - 8;
        }
      }
    }
    for (let i = paginationStart; i < paginationEnd; i++) {
      paginationView.push(i);

    }

    return paginationView;
  }

  getPageCount(): number[] {
    return this.pageCount;
  }

  setPage(inputDashboardData: InputDashboardData, pageCount: number): void {
    if (inputDashboardData.activePage < 1) {
      inputDashboardData.activePage = 1;
    }

    if (inputDashboardData.activePage > pageCount) {
      inputDashboardData.activePage = pageCount;
    }

    inputDashboardData.dashboardFilter.page = inputDashboardData.activePage;

    inputDashboardData.dashboardFilter.start = (inputDashboardData.dashboardFilter.page - 1) * inputDashboardData.dashboardFilter.limit;
  }

  setLimit(limit: number): void {
    this.dashboardFilter.limit = limit;
  }

  getActivePage(): number {
    return this.dashboardFilter.page;
  }


  calculatePagination(inputDashboardData: InputDashboardData): void {
    inputDashboardData.pageCount = Math.ceil(inputDashboardData.entityCount / inputDashboardData.dashboardFilter.limit);
    inputDashboardData.pageView = this.setPageCount(inputDashboardData.dashboardFilter.limit,
      inputDashboardData.entityCount,
      inputDashboardData.activePage);
    this.calcPageLabels(inputDashboardData);
  }

  calcPageLabels(inputDashboardData: InputDashboardData): void {
    inputDashboardData.pageLabelFrom = (inputDashboardData.activePage - 1)
      * parseInt(inputDashboardData.dashboardFilter.limit.toString(), 10) + 1;

    if (inputDashboardData.activePage === inputDashboardData.pageCount) {
      inputDashboardData.pageLabelTill = inputDashboardData.entityCount;
    } else {
      inputDashboardData.pageLabelTill = (inputDashboardData.activePage - 1)
        * parseInt(inputDashboardData.dashboardFilter.limit.toString(), 10)
        + parseInt(inputDashboardData.dashboardFilter.limit.toString(), 10);
    }

  }

  getFilterFormGroup(columns: Column[], dashboardData: InputDashboardData, inputData: InputData): FormGroup {

    let keyword: string;
    let filterFile: string;
    const form = {};
    dashboardData.refFilterHandles = [];

    filterFile = dashboardData.dashboardFilter.filterFile ? dashboardData.dashboardFilter.filterFile : '';

    if (dashboardData.dashboardFilter.baseFilter) {
      const baseFilters: BaseFilters[] = JSON.parse(dashboardData.dashboardFilter.baseFilter);

      if (dashboardData.dashboardFilter.allFilterKeyword === 'false') {
        const filterColumns: Column[] = [];
        keyword = '';
        dashboardData.isExtendedFilterBar = true;
        const form = {};

        for (let i = 0; i < baseFilters.length; i++) {
          if (!baseFilters[i].FilterConditions) {
          } else {
            baseFilters[i].FilterConditions.forEach((filterCondition: FilterCondition) => {
              const refColumn: Column = columns.find(r => r.refName === filterCondition.Name);
              if (refColumn) {
                filterColumns.push(refColumn);
                dashboardData.refFilterHandles.push({key: filterCondition.Name, value: filterCondition.Value});
                form[refColumn.attribute] = new FormControl(filterCondition.FilterText);
              } else {
                const column: Column = columns.find(r => r.attribute === filterCondition.Name);

                if (column) {
                  filterColumns.push(column);
                  form[column.attribute] = new FormControl(filterCondition.Value);
                }
              }
            });
          }
        }
        inputData.formGroup = new FormGroup(form);
        dashboardData.extendedFilterColumns = filterColumns;
        this.formDataService.setFilterPermissions(filterColumns, inputData.formGroup);
      } else {
        dashboardData.isExtendedFilterBar = false;
        keyword = baseFilters[0].FilterConditions[0] ? baseFilters[0].FilterConditions[0]['Value'] : '';
      }
    } else {

      inputData.formGroup = new FormGroup({});
    }

    if (dashboardData.dashboardFilter) {
      form['filterFile'] = new FormControl(dashboardData.dashboardFilter.filterFile ?
        dashboardData.dashboardFilter.filterFile : null);
      form['sortField'] = new FormControl(dashboardData.dashboardFilter.sortField ?
        dashboardData.dashboardFilter.sortField : null);
      form['sortDirection'] = new FormControl(dashboardData.dashboardFilter.sortDirection ?
        dashboardData.dashboardFilter.sortDirection : null);
      form['keyword'] = new FormControl(dashboardData.dashboardFilter.keyword ?
        dashboardData.dashboardFilter.keyword : null);
      form['filters'] = new FormControl(dashboardData.dashboardFilter.filterFile ?
        dashboardData.dashboardFilter.filterFile : null);
    }

    return new FormGroup(form);
  }

  randomName(name: string, result: any) {
    let checkName: boolean = false;
    let randomName: string;
    do {
      randomName = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
      randomName = name + randomName;
      const checkFieldValue = result.hasOwnProperty(randomName);
      if (!result.find(r => r.name === randomName)) {
        checkName = true;
      }
    } while (!checkName);
    return randomName;
  }

  convertToJsonData(inputFormData: InputFormData,
                    inputDashboardData: InputDashboardData,
                    entityData: EntityData[],
                    entityDescriptors: EntityDescriptor[],
                    dashboardColumn: Column[],
                    locale: string,
                    mapping: KeyValue[] = null) {
    if (!mapping) {
      mapping = [];
      dashboardColumn.forEach((column: Column) => {
        mapping.push({key: column.attribute, value: column.attribute});
      });
    }

    const dataResult: any[] = [];
    let entry: any;
    let fieldName: string;
    let columnProperties: Column;
    let x = 0;
    if (!dashboardColumn.find(r => r.attribute === 'ID')) {
      dashboardColumn.push({attribute: 'ID', label: 'ID'});
    }

    if (entityData) {
      for (let i = 0; i < entityData.length; i++) {
        entry = {};
        x = 0;
        let adjustedNameCounter = 0;
        mapping.forEach((column: KeyValue) => {

          if (inputFormData.view.columns) {
            columnProperties = inputFormData.view.columns.find(r => r.attribute === column.key);
          }


          const entityDataX: EntityData | any = entityData[i].Attributes.Attribute.find(r => r.Name.toLowerCase() === column.key.toLowerCase());
          if (inputFormData.view.columns || inputFormData.view.filters) {
            if (inputFormData.view.filters && inputFormData.view.filters.length > 0) {
              const filterView: View = inputFormData.view.filters.find(r => r.file === inputDashboardData.dashboardFilter.filterFile);
              columnProperties = filterView.columns.find(r => r.attribute === column.key);
            } else {
              columnProperties = inputFormData.view.columns.find(r => r.attribute === column.key);
            }

          }
          if (entityDataX && entityDescriptors) {
            const entityDescriptor: EntityDescriptor = entityDescriptors.find(r => r.attribute === column.key);


            if (entityDataX.Value && columnProperties && columnProperties.valueFormat) {

              switch (columnProperties.valueFormat.type) {
                case FormatEnum.CURRENCY:
                  entityDataX.Value = new Intl.NumberFormat(inputFormData.userLocale, {
                    style: 'currency',
                    currency: columnProperties.valueFormat.unit
                  }).format(parseInt(entityDataX.Value, 10));
              }

            }

            if (entityDataX.Value && entityDataX.Value.timestamp) {
              const dateTime = entityDataX.Value;

              if (entityDescriptor && entityDescriptor.DataType === 'Date') {
                entityDataX.Value = new Date(dateTime['timestamp'] * 1000).toLocaleDateString(locale, {timeZone: dateTime['timezone'].name});
              }

              if (entityDescriptor && entityDescriptor.DataType === 'Time') {
                const dateObj: Date = new Date((dateTime['timestamp']) * 1000);
                const timeAmPm: string[] = dateObj.toLocaleTimeString(locale, {timeZone: dateTime['timezone'].name}).split(' ');
                const time: string[] = dateObj.toLocaleTimeString(locale, {timeZone: dateTime['timezone'].name}).split(':');

                if (timeAmPm[1]) {
                  entityDataX.Value = dateObj.toLocaleDateString(locale, {timeZone: dateTime['timezone'].name}) + ' ' + time[0] + ':' + time[1] + ' ' + timeAmPm[1];
                } else {
                  entityDataX.Value = dateObj.toLocaleDateString(locale, {timeZone: dateTime['timezone'].name}) + ' ' + time[0] + ':' + time[1];
                }
              }

            }


            if (entityDescriptor && (entityDataX.Value || entityDataX.Value === 0) && entityDescriptor.DefaultValues.NamedValueEx && entityDescriptor.DefaultValues.NamedValueEx.length > 0) {
              const value: Value = entityDescriptor.DefaultValues.NamedValueEx.find(r => r.Value === entityDataX.Value.toString());


              if (value) {
                entityDataX.Value = value.Name;
              } else {
                entityDataX.Value = '';
              }

            }

            const boolArr: Array<string> = entityDescriptors.filter(r => r.DataSemantic === 'Boolean' && r.DataType === 'Integer').map(ele => ele.attribute);
            if (boolArr.indexOf(entityDataX.Name) > -1) {
              entityDataX.Value = {value: entityDataX.Value, type: 'bool'};
            }
          }
          if (entityDataX) {
            fieldName = column.value;
            fieldName = fieldName.replace(/[^a-zA-Z#]/g, '').toLowerCase();
            const checkFieldValue = entry.hasOwnProperty(fieldName);
            if (checkFieldValue) {
              fieldName = this.adjustedNameArray[adjustedNameCounter];
              adjustedNameCounter++;
            }

            if (columnProperties && columnProperties.attributes && columnProperties.attributes.length > 0) {
              const concatResult: string[] = [];
              columnProperties.attributes.forEach((attribute: string) => {
                concatResult.push(entityData[i].Attributes.Attribute.find(r => r.Name === attribute).Value);
              });
              entry[fieldName] = concatResult.join(' ');
            } else {
              entry[fieldName] = entityDataX.Value;
            }

            if (entityData[i].Children && entityData[i].Children.length > 0) {
              entry['subChild'] = this.convertToJsonData(inputFormData, inputDashboardData, entityData[i].Children, entityDescriptors, dashboardColumn, locale, mapping);
            }


          }
          x++;
        });
        entry['handle'] = entityData[i].Handle;
        dataResult.push(entry);
      }
    }

    return dataResult;

  }


  convertToJsonDataArray(inputFormData: InputFormData,
                         inputDashboardData: InputDashboardData,
                         entityData: EntityData[],
                         entityDescriptors: EntityDescriptor[],
                         dashboardColumn: Column[],
                         locale: string,
                         mapping: KeyValue[] = null): DataRow[] {

    if (!mapping) {
      mapping = [];
      dashboardColumn.forEach((column: Column) => {
        mapping.push({key: column.attribute, value: column.attribute});
      });
    }

    const dataResult: any[] = [];
    let entry: any;
    let fieldName: string;
    let columnProperties: Column;
    let x = 0;
    if (!dashboardColumn.find(r => r.attribute === 'ID')) {
      dashboardColumn.push({attribute: 'ID', label: 'ID'});
    }

    if (entityData) {
      for (let i = 0; i < entityData.length; i++) {
        entry = {};
        x = 0;
        let adjustedNameCounter = 0;
        mapping.forEach((column: KeyValue) => {

          if (inputFormData.view.columns) {
            columnProperties = inputFormData.view.columns.find(r => r.attribute === column.key);
          }


          const entityDataX: EntityData | any = entityData[i].Attributes.Attribute.find(r => r.Name.toLowerCase() === column.key.toLowerCase());
          if (inputFormData.view.columns || inputFormData.view.filters) {
            if (inputFormData.view.filters && inputFormData.view.filters.length > 0) {
              const filterView: View = inputFormData.view.filters.find(r => r.file === inputDashboardData.dashboardFilter.filterFile);
              columnProperties = filterView.columns.find(r => r.attribute === column.key);
            } else {
              columnProperties = inputFormData.view.columns.find(r => r.attribute === column.key);
            }

          }
          if (entityDataX && entityDescriptors) {
            const entityDescriptor: EntityDescriptor = entityDescriptors.find(r => r.attribute === column.key);


            if (entityDataX.Value && columnProperties && columnProperties.valueFormat) {

              switch (columnProperties.valueFormat.type) {
                case FormatEnum.CURRENCY:
                  entityDataX.Value = new Intl.NumberFormat(inputFormData.userLocale, {
                    style: 'currency',
                    currency: columnProperties.valueFormat.unit
                  }).format(parseInt(entityDataX.Value, 10));
              }

            }

            if (entityDataX.Value && entityDataX.Value.timestamp) {
              const dateTime = entityDataX.Value;

              if (entityDescriptor && entityDescriptor.DataType === 'Date') {
                entityDataX.Value = new Date(dateTime['timestamp'] * 1000).toLocaleDateString(locale, {timeZone: dateTime['timezone'].name});
              }

              if (entityDescriptor && entityDescriptor.DataType === 'Time') {
                const dateObj: Date = new Date((dateTime['timestamp']) * 1000);
                const timeAmPm: string[] = dateObj.toLocaleTimeString(locale, {timeZone: dateTime['timezone'].name}).split(' ');
                const time: string[] = dateObj.toLocaleTimeString(locale, {timeZone: dateTime['timezone'].name}).split(':');

                if (timeAmPm[1]) {
                  entityDataX.Value = dateObj.toLocaleDateString(locale, {timeZone: dateTime['timezone'].name}) + ' ' + time[0] + ':' + time[1] + ' ' + timeAmPm[1];
                } else {
                  entityDataX.Value = dateObj.toLocaleDateString(locale, {timeZone: dateTime['timezone'].name}) + ' ' + time[0] + ':' + time[1];
                }
              }

            }


            if (entityDescriptor && (entityDataX.Value || entityDataX.Value === 0) && entityDescriptor.DefaultValues.NamedValueEx && entityDescriptor.DefaultValues.NamedValueEx.length > 0) {
              const value: Value = entityDescriptor.DefaultValues.NamedValueEx.find(r => r.Value === entityDataX.Value.toString());


              if (value) {
                entityDataX.Value = value.Name;
              } else {
                entityDataX.Value = '';
              }

            }

            const boolArr: Array<string> = entityDescriptors.filter(r => r.DataSemantic === 'Boolean' && r.DataType === 'Integer').map(ele => ele.attribute);
            if (boolArr.indexOf(entityDataX.Name) > -1) {
              entityDataX.Value = {value: entityDataX.Value, type: 'bool'};
            }
          }
          if (entityDataX) {
            fieldName = column.value;
            fieldName = fieldName.toLowerCase();
            const checkFieldValue = entry.hasOwnProperty(fieldName);
            if (checkFieldValue) {
              fieldName = this.adjustedNameArray[adjustedNameCounter];
              adjustedNameCounter++;
            }

            if (columnProperties && columnProperties.attributes && columnProperties.attributes.length > 0) {
              const concatResult: string[] = [];
              columnProperties.attributes.forEach((attribute: string) => {
                concatResult.push(entityData[i].Attributes.Attribute.find(r => r.Name === attribute).Value);
              });
              entry[fieldName] = concatResult.join(' ');
            } else {
              entry[fieldName] = entityDataX.Value;
            }

            if (entityData[i].Children && entityData[i].Children.length > 0) {
              entry['subChild'] = this.convertToJsonData(inputFormData, inputDashboardData, entityData[i].Children, entityDescriptors, dashboardColumn, locale, mapping);
            }


          }
          x++;
        });
        entry['handle'] = entityData[i].Handle;
        dataResult.push(entry);
      }
    }

    return dataResult;

  }


  convertToJsonDataHeader(entityData: EntityData[], inputFormData: InputFormData, inputDashboardData: InputDashboardData, displayColumns: Column[]): any[] {
    const result: any[] = [];
    inputDashboardData.selected = [];

    if (inputFormData.view.listSettings && (inputFormData.view.listSettings.type === ListSettingsTypeEnum.MULTI_SELECT)) {

      inputDashboardData.checkboxMode = true;

      result.push({
        prop: 'selected',
        name: '',
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizable: false,
        headerCheckboxable: true,
        checkboxable: true,
        width: 30
      });
    }

    let i = 0;
    displayColumns.forEach((column: Column) => {
      let width: number;
      if (column.visible === undefined || !!column.visible) {


        if (inputFormData.view.listControl && inputFormData.view.listControl === ListControlEnum.FROZEN_GRID) {


          if ((i === 0) || (inputFormData.view.frozenGrid.frozenYColumns && i < inputFormData.view.frozenGrid.frozenYColumns)) {
            width = inputFormData.view.frozenGrid && inputFormData.view.frozenGrid.yWidth ? inputFormData.view.frozenGrid.yWidth : 300;
          } else {
            width = inputFormData.view.frozenGrid && inputFormData.view.frozenGrid.columnWidth ? inputFormData.view.frozenGrid.columnWidth : 60;
            ;
          }
        }
        if (column.attribute !== 'ID') {
          const entry = {};
          if (!column.label) {
            entry['name'] = column.attribute.toLowerCase();
            entry['label'] = column.attribute;
          } else {
            // result.push({name: column.label});
            entry['name'] = column.attribute.toLowerCase();
            entry['label'] = column.label;
          }

          if (result.find(r => r.name === entry['name'])) {
            entry['name'] = this.randomName(entry['name'], result);
            this.adjustedNameArray.push(entry['name']);
          }


          if (column.name) {
            if (column.name.toLowerCase() === '$displayname') {
              entry['sortable'] = false;
            } else {
              entry['sortable'] = true;
            }
          }

          result.push({
            id: entry['name'],
            name: entry['name'],
            label: entry['label'],
            sortable: entry['sortable'],
            width,
            useDisplayColor: column.useDisplayColor
          });
        }
      }
      i++;
    });


    return result;
  }

  getFilterParameterDescriptors(filterFile: FilterFile): Observable<FilterParameterDescriptor[]> {
    const getFilterParameterDescriptorsRequest: GetFilterParameterDescriptorsRequest = {filterFile: filterFile.file};
    return this.entityListService.getFilterParameterDescriptors(getFilterParameterDescriptorsRequest);
  }

  getFilterParameters(inputFormData: InputFormData): FilterParameter[] {
    const filterParameters: FilterParameter[] = [];

    const params: Params = inputFormData.queryParams['filterBarParams'] ? JSON.parse(inputFormData.queryParams['filterBarParams']) : [];

    for (let key in params) {

      if (key !== 'dashboardTime') {
        if (key.indexOf('_entity') > -1) {
          const keyArray: string[] = key.split('_');
          const newKey: string = keyArray.slice(0, keyArray.length - 1).join('_');

          const filterParameter: FilterParameter = filterParameters.find(r => r.key === newKey);

          if (!filterParameter) {
            filterParameters.push({key: newKey, value: params[key], dataType: 'entity'});
          } else {
            filterParameter.value = params[key];
            filterParameter.dataType = 'entity';
          }

        } else {
          filterParameters.push({key, value: params[key], dataType: 'Date'});
        }
      }

    }
    return filterParameters;
  }

  getPermittedFilterFormGroup(columns: Column[], dashboardFilter: DashboardFilter): FormGroup {
    const form = {};

    columns.forEach((column: Column) => {
      form[column.attribute] = new FormControl('');
      column.permissions = {writeable: true, required: false, visible: true};
    });

    return new FormGroup(form);
  }

  setFilterControls(queryParams: any,
                    inputDashboardData: InputDashboardData,
                    formGroup: FormGroup,
                    viewListColumns: Column[]): void {
    const dashboardFilter: DashboardFilter = queryParams;

    if (dashboardFilter.baseFilter && dashboardFilter.baseFilter !== '') {
      const baseFilters: BaseFilters[] = JSON.parse(dashboardFilter.baseFilter);
      baseFilters.forEach((baseFilter: BaseFilters) => {
        if (baseFilter.FilterConditions.length > 0) {


          if (baseFilter.FilterConditions[0].DataType === 'Entity') {
            const column: Column = viewListColumns.find(r => r.refName === baseFilter.FilterConditions[0].Name);
            inputDashboardData.refFilterHandles.push({key: column.refName, value: baseFilter.FilterConditions[0].Value});
            formGroup.controls[column.attribute].setValue(baseFilter.FilterConditions[0].FilterText);
          } else {
            const column: Column = viewListColumns.find(r => r.attribute === baseFilter.FilterConditions[0].Name);
            if (column) {
              formGroup.controls[column.attribute].setValue(baseFilter.FilterConditions[0].Value);
            }

          }
        }
      });

    }
  }


}
