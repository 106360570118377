import {Component, OnInit} from '@angular/core';
import {MessageStackService} from '../../services/message-stack.service';
import {Settings} from '../../../pit/structure';
import {ConfigService} from '../../services/config.service';

@Component({
  selector: 'app-message-toast',
  templateUrl: './message-toast.component.html',
  styleUrls: ['./message-toast.component.css']
})
export class MessageToastComponent implements OnInit {


  settings: Settings;

  constructor(public messageStackService: MessageStackService,
              private configService: ConfigService) { }

  ngOnInit(): void {
      this.settings = this.configService.getSettings();
  }


}
