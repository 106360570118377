<div class="col-lg-12">
<span [innerHTML]="data.helpText">

</span>
</div>


<div class="col-lg-12">
  <div class="row">
    <div class="col-lg-4">
    </div>
    <div class="col-lg-4">
      <button id="btn_close_help_text" type="button" mat-button (click)="closeDialog()">
        OK
      </button>
    </div>
    <div class="col-lg-4">
    </div>
  </div>
</div>

