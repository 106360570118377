/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Client } from '../models/client';
import { SetClientArrayRequest } from '../models/set-client-array-request';
import { SetClientRequest } from '../models/set-client-request';
@Injectable({
  providedIn: 'root',
})
class ClientsService extends __BaseService {
  static readonly getAvailableClientsPath = '/client/getavailables';
  static readonly getActiveClientsPath = '/client/getactives';
  static readonly getWritableClientsPath = '/client/getwriteable';
  static readonly setActivePath = '/client/setactives';
  static readonly setWriteablePath = '/client/setwriteable';
  static readonly setActiveScopePath = '/client/setactivescope';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list all available clients
   *
   * list all available clients
   * @return successful operation
   */
  getAvailableClientsResponse(): __Observable<__StrictHttpResponse<Array<Client>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/client/getavailables`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Client>>;
      })
    );
  }
  /**
   * list all available clients
   *
   * list all available clients
   * @return successful operation
   */
  getAvailableClients(): __Observable<Array<Client>> {
    return this.getAvailableClientsResponse().pipe(
      __map(_r => _r.body as Array<Client>)
    );
  }

  /**
   * list all available clients
   *
   * list all available clients
   * @return successful operation
   */
  getActiveClientsResponse(): __Observable<__StrictHttpResponse<Array<Client>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/client/getactives`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Client>>;
      })
    );
  }
  /**
   * list all available clients
   *
   * list all available clients
   * @return successful operation
   */
  getActiveClients(): __Observable<Array<Client>> {
    return this.getActiveClientsResponse().pipe(
      __map(_r => _r.body as Array<Client>)
    );
  }

  /**
   * list all writable clients
   *
   * list all writable clients
   * @return successful operation
   */
  getWritableClientsResponse(): __Observable<__StrictHttpResponse<Client>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/client/getwriteable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Client>;
      })
    );
  }
  /**
   * list all writable clients
   *
   * list all writable clients
   * @return successful operation
   */
  getWritableClients(): __Observable<Client> {
    return this.getWritableClientsResponse().pipe(
      __map(_r => _r.body as Client)
    );
  }

  /**
   * set client to active
   *
   * set client to active
   * @param body Array of Client Numbers
   */
  setActiveResponse(body: SetClientArrayRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/client/setactives`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * set client to active
   *
   * set client to active
   * @param body Array of Client Numbers
   */
  setActive(body: SetClientArrayRequest): __Observable<null> {
    return this.setActiveResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * set client to writable
   *
   * set client to writable
   * @param body Array of Client Numbers
   */
  setWriteableResponse(body: SetClientRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/client/setwriteable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * set client to writable
   *
   * set client to writable
   * @param body Array of Client Numbers
   */
  setWriteable(body: SetClientRequest): __Observable<null> {
    return this.setWriteableResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param body Array of Client Numbers
   */
  setActiveScopeResponse(body: SetClientArrayRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/client/setactivescope`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param body Array of Client Numbers
   */
  setActiveScope(body: SetClientArrayRequest): __Observable<null> {
    return this.setActiveScopeResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ClientsService {
}

export { ClientsService }
