import {EntityData} from '../shared/services/swagger/models/entity-data';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {PermissionEnum} from '../shared/services/permission.service';
import {DashboardList, ListHead} from '../shared/interfaces/dashboard-list';
import {FilterParameterDescriptor} from '../shared/services/swagger/models/filter-parameter-descriptor';
import {EntityDescriptor} from '../shared/services/swagger/models/entity-descriptor';
import {EntityDescriptorResponse} from '../shared/services/swagger/models/entity-descriptor-response';
import {Gantt, InputData, InputFormData, KeyValue, NavButton, Scheduler, TimelineFilter} from './input-data';
import {EntityCallFunctionContextResponse} from '../shared/services/swagger/models/entity-call-function-context-response';
import {Tile} from '@angular/material/grid-list/tile-coordinator';
import {Entity} from '@angular/compiler-cli/src/ngtsc/file_system/testing/src/mock_file_system';
import {EventSettingsModel} from '@syncfusion/ej2-angular-schedule';
import {MenuItemModel} from '@syncfusion/ej2-angular-navigations';
import {DataSetsDt, DoughnutData} from '../shared/interfaces/chart';

export interface Configuration {
  menus: MenuTypes;
  landingPages?: LandingPage[];
  workflows: Workflow[];
  dashboards?: Dashboard[];
}

export interface LandingPage {
  htmlFile?: string;
  type: LandingPageEnum;
}

export interface ReleaseNotes {
  currentVersion: ReleaseNotesCurrentVersion;
  history: HistoryEntry[];
}

export interface ReleaseNotesCurrentVersion {
  version: string;
  build: string;
}

export interface HistoryEntry {
  version: string;
  build: string;
  date: Date;
  notes: string[];
}

export interface Dashboard {
  filterBar?: FilterBar,
  name: string;
  widgets?: Widget[];
  lists?: DashboardList[];
  charts?: Chart[];
  teaser?: Teaser[];
  tiles?: Menu[];
  tilesGroups?: TileGroup[];
  settings: ViewSettings;
}

export interface ViewSettings {
  refreshInterval?: number;
}

export interface Widget {
  tileGroup?: TileGroup;
  chart?: Chart;
  list?: DashboardList;
}

export interface FilterBar {
  columns: Column[];
}

export interface Teaser {
  title: string;
  image: string;
  color?: string;
}

export interface Chart {
  title: string;
  type: string;
  pieMode?: string;
  barMode?: string;
  filter: FilterFile;
  endpoint?: string;
  filterAttribute: string;
  binding: string;
  chartFilters: ChartFilter[];
  listHead?: ListHead;
  yAxis: string;
  xAxis: string;
  color: string;
  filterFile?: string;
  colorSettings: ColorSettings;
  width?: string;
  height?: string;
  showPercentages?: boolean;
}

export interface ColorSettings {
  mainColor: string;
  amount: number;
}

export interface BarStatus {
  filterFile: string;
  name: string;
}

export interface ChartFilter {
  stack?: string;
  filterFile: string;
  pitId: string;
  binding?: string;
  barStatus: BarStatus[];
  endpoint?: string;
  name: string;
  color: string;
  observer?: Observable<any>
  rawData?: EntityData[];
  doughnutData?: DoughnutData[];
}

export interface MenuTypes {
  tiles: Menu[];
  main: Menu[];
}

export interface Menu {
  name: string;
  class?: string;
  userRoles?: string[];
  allowedClients?: string[];
  icon: string;
  color?: string;
  children?: Children[];
  action?: Action;
  actions?: Action[];
  guid?: string;
  width?: string;
}

export interface TileGroup {
  name: string;
  width?: string;
  height?: string;
  tiles: Menu[];
}

export interface Children {
  path?: string;
  userRoles?: string[];
  isDisplayed?: boolean;
  icon: string;
  name: string;
  type?: string;
  attribute?: string;
  filterFile?: FilterFile;
  action?: Action;
  referenceInfos?: ReferenceInfos;
  helpText?: string;
  permission?: EntityDescriptorFlagsEnum;
  guid?: string;
  entityId?: string;
  isVirtual?: boolean;
  placement?: ChildPlacementEnum;
  allowedClients?: string[];
}

export enum ChildPlacementEnum {
  BEFORE = 'before',
  BEHIND = 'behind'
}

export interface FilterFile {
  file?: string;
  name?: string;
  binding?: string;
  listSettings?: ListSettings;
  activators?: Activator[];
  columns?: Column[];
  defaultFilterSortingField?: string;
  defaultFilterSortingDirection?: SortingEnum;
}

export interface Filter {
  name: string;
  path: string;
  default?: boolean;
}

export interface Workflow {
  name: string;
  landingPages?: LandingPage[];
  views: ViewList[];
}

export interface ViewList {
  [index: string]: View;
}

export interface View {
  name?: string;
  type?: string;
  binding?: string;
  commitAttribute?: string;
  EntityPermission?: EntityDescriptorFlagsEnum;
  children?: Children[];
  action?: Action;
  columns?: Column[];
  defaultFilterColumns?: Column[];
  filter?: FilterFile;
  filterView?: FilterFile;
  activators?: Activator[];
  sections?: Section[];
  sortField?: string;
  sortDirection?: SortingEnum;
  topbar?: ButtonBar;
  viewbar?: ButtonBar;
  referenceInfos?: ReferenceInfos;
  isCatalogView?: boolean;
  wizardCompleteActivators?: Activator[];
  listSettings?: ListSettings;
  kanban?: Kanban;
  topic?: string;
  windowClass?: string;
  targetSubmitView?: string;
  searchableAttributes?: string[];

  function?: string;
  matrix?: Matrix;
  guid?: string;
  onInit?: Activator;
  cancelRoute?: string;
  scheduler?: Scheduler;
  gantt?: Gantt;
  filters?: FilterFile[];
  defaultFilterFileName?: string;
  defaultFilterSortingField?: string;
  defaultFilterSortingDirection?: SortingEnum;
  sectionType?: SectionTypeEnum;
  showMode?: boolean;
  restrictionClassName?: string;
  wizardNavNames?: WizardNavNames;
  wizardNavButtons?: NavButton[];
  helpText?: string;
  tabsAttribute?: string;
  tabsTitle?: string;
  tabsViewTemplate?: string;
  isVirtual?: boolean;
  isEnrichedWithVirtualTabs?: boolean;
  timeline?: Timeline;
  contextMenu?: Activator[];
  events?: Event[];
  filterBar?: FilterBar;
  listControl?: ListControlEnum;
  frozenGrid?: FrozenGrid;
  showFilterBar?: boolean;
}

export interface FrozenGrid {
  yWidth?: number;
  columnWidth?: number;
  frozenYColumns?: number;
}

export enum ListControlEnum {
  INFINITE = 'infinite',
  PAGINATION = 'pagination',
  FROZEN_GRID = 'frozenGrid'
}

export interface Event {
  name: EventEnum;
  actions: Action[];
}

export enum EventEnum {
  BEFORE_VIEW_INIT = 'before_view_init',
  AFTER_VIEW_INIT = 'after_view_init',
}

export enum SectionTypeEnum {
  COLUMN = 'column',
  ROW = 'row'
}

export interface Matrix {
  xAxis: AxisData;
  yAxis: AxisData;
  appointments: AxisData;
}

export enum NavButtonEnum {
  NEXT = 'next',
  PREVIOUS = 'previous',
  CANCEL = 'cancel',
  SUBMIT = 'submit'

}

export interface Kanban {
  x: AxisData;
  y: AxisData;
}

export interface AxisData {
  binding: string;
  attribute?: string;
  attributes?: string[];
  appointmentYAttribute?: string;
  appointmentXAttribute?: string;
  filterFile?: string;
  enums?: Enums[];
  groupAttribute?: string;
  groupBinding?: string;
}

export interface Enums {
  enum: string;
  initial: string;
  value: string;
  activator?: Activator;
}


export interface ListSettings {
  type: ListSettingsTypeEnum;
  activators: Activator[];
}


export interface ReferenceInfos {
  ReferenceAttribute: string;
  ReferenceClassname: string;
  HTClassname: string;
  HTReferenceAttribute: string;
  catalogColumns?: CatalogColumns[];
  displayedColumns?: string[];
  searchableAttributes?: string[];
  filter?: FilterFile;
  searchableColumns: Column[];
}

export interface ButtonBar {
  title?: ButtonBarTitle[];
  activators?: Activator[];
  filters?: FilterFile[];
}

export interface ButtonBarTitle {
  text: string;
  type: ButtonBarTitleTypeEnum;
}

export enum ButtonBarTitleTypeEnum {
  STATIC = 'static',
  ATTRIBUTE = 'attribute'
}

export interface ButtonFunction {
  name: string;
  func: any;
}

export interface Column {
  key?: string;
  attributeDescriptor?: any;
  activator?: Activator;
  activators?: Activator[];
  entityData?: EntityData;
  controlType?: string;
  class?: string;
  refEntity?: string;
  refName?: string;
  attribute?: string;
  attributes?: string[];
  displayedColumns?: string[];
  catalogColumns?: CatalogColumns[];
  searchableAttributes?: string[];
  searchableColumns?: Column[];
  filter?: FilterFile;
  FilterParameterDescriptors?: FilterParameterDescriptor[];
  helpText?: string;
  label?: string;
  name?: string;
  Name?: string;
  sortable?: boolean;
  visible?: boolean;
  permissionType?: PermissionEnum;
  permissions?: Permissions;
  staticText?: string;
  topic?: string;
  treeView?: TreeView;
  refListView?: RefListView;
  colors?: ColorMapping[];
  useDisplayColor?: boolean;
  colorAttribute?: string;
  svgMenu?: Activator[];
  svgMarkerContextMenu?: Activator[];
  svgObjects?: SvgObjects;
  guid?: string;
  height?: string;
  valueFormat?: Format;
  showInInfobox?: boolean;
  isPrintable?: boolean;
  printTitleAttribute?: ButtonBarTitle[];
  svgToolbarButtons?: SvgToolbarButtons[];
  initialActiveToolbar?: boolean;
  type?: string;
  filterParameterType?: FilterParameterTypeEnum;
  icon?: string;
  isTemporary?: boolean;
  isSortable?: boolean;
  isConcatAttribute?: boolean;
}

export interface SvgToolbarButtons {
  icon: string;
  title: string;
  styleClass: string;
  enabled: true;
  activators: Activator[];
}

export interface CatalogColumns {
  attribute: string;
  label: string;
}

export interface RefListView {
  sortField?: string;
  sortDirection?: SortingEnum;

}


export interface Format {
  type: FormatEnum;
  unit: string;
}

export enum FormatEnum {
  CURRENCY = 'currency'
}

export interface SvgObjects {
  binding: string;
  attribute: string;
  markerAttribute: string;
  filterFile?: string;
  pinDialog?: PinDialog;
}

export interface PinDialog {
  columns?: Column[];
  images?: Asset;
  title?: ButtonBarTitle[];
  activators?: Activator[];
}


export interface ColorMapping {
  name: string;
  color: string;
  svgAttribute?: string;
}

export interface MarkerResults {
  rawData?: EntityData[];
  markers: PinData[];
}


export interface TreeView {
  attribute?: string;
  attributes?: TreeViewAttribute[];
}


export interface TreeViewAttribute {
  attribute: string;
  binding: string;
  filter?: FilterFile;
  treeViewDisplayAttribute: string;
}


export interface Permissions {
  visible: boolean;
  writeable: boolean;
  required: boolean;
}

export interface Activator {
  action?: Action;
  role?: ActivatorRoleEnum;
  actions?: Action[];
  default?: boolean;
  name?: string;
  icon?: string;
  title?: string;
  class?: string;
  children?: Activator[];
  type?: string;
  ui?: Ui;
  size?: number;
  toasts?: ToastEvent[];
  svgFunction?: SvgFunctionEnum;
  guid?: string;
  callFunctionContexts?: EntityCallFunctionContextResponse[];
  callFunctionContext?: EntityCallFunctionContextResponse[];
  isCallFunctionContextChecked?: boolean;
  commandId?: string;
  permissionFlags?: number;
  permission?: EntityDescriptorFlagsEnum;
}

export enum ActivatorRoleEnum {
  ENTITY_FLAG = 'entity_flag',
}

export interface Action {
  attribute?: string;
  element?: string;
  type?: ActivatorTypeEnum;
  endpoint?: string;
  mode?: ActivatorTypeEnum;
  parameters?: Parameters;
  successor?: string;
  function?: string;
  callFunctionParameters?: CallFunctionParameter[];
  defaultFilter?: string;
  filters?: FilterFile[];
  closeHandleMode?: boolean;
  attributeHandover?: Handover;
  attributeHandovers?: Handover[];
  handleAttribute?: string;
  target?: string;
  elementId?: string;
  defaultValues?: DefaultValues[];
  hideNoFilterOptionInDropdown?: boolean;
  sessionHandover?: boolean;
  presentationMode?: boolean;
  controlName?: string;
  value?: string;
  contextType?: string;
}

export interface DefaultValues {
  attribute: string;
  binding: string;
  type: DefaultValuesEnum;
  referenceAttribute?: string;
  value: string;
}

export enum DefaultValuesEnum {
  REFERENCE = 'reference'
}

export interface ToastEvent {
  event: ToastEventEnum;
  title: string;
  text: string;
}

export enum ToastEventEnum {
  SUCCESS = 'success',
  ERROR = 'error'
}

export interface CallFunctionParameter {
  parameterType?: string;
  value?: string;
  type?: string;
}

export interface Handover {
  data?: any;
  target?: string;
  binding?: string;
  dataType?: HandoverTypeEnum;
  xAxis?: string;
  yAxis?: string;
  context?: string;
}

export enum HandoverTypeEnum {
  REF = 'ref',
  TEXT = 'text'
}


export interface Parameters {
  input: any[];
  output: string;
}

export interface Ui {
  name: string;
  icon: string;
}

export interface ViewContainer {
  type: string;
  binding: string;
  children?: Children[];
}


export interface Section {
  name: string;
  visible?: boolean;
  legendWidth?: string;
  rows: Column[];
  guid?: string;
  class?: string;
}

export interface Row {
  id: string;
  attribute: string;
  readonly?: boolean;
  required?: boolean;
}

export enum ViewEnum {
  VIEW_LIST = 'view_list',
  VIEW_CONTAINER_TABBED = 'tabbed',
  TAB = 'tab',
  WIZARD = 'wizard',
  LIST = 'list',
  KANBAN = 'kanban',
  VIEW_CONTAINER_STACK = 'view_container_tabbed',
  RECURRENCE_EDITOR = 'recurrenceeditor',
  ENTITY_MATRIX = 'entity_matrix',
  DETAIL = 'detail',
  SCHEDULER = 'scheduler',

  TIMELINE = 'timeline',
  GANTT = 'gantt'
}

export enum ActivatorTypeEnum {
  CREATE_ENTITY = 'createentity',
  CREATE_AND_ASSIGN_ENTITY = 'createandassignentity',
  ASSIGN_ENTITY = 'assignEntity',
  SHOW_FILE = 'showFile',
  SAVE_ENTITY = 'save_entity',
  UPLOAD = 'upload',
  ROUTE = 'route',
  ROUTE_TO_HANDLE = 'route_to_handle',
  ROLLBACK_ENTITY = 'rollback_entity',
  ROLLBACK_AND_GET_DATA_ENTITY = 'rollback_and_get_data_entity',
  CALL_FUNCTION_WITH_HANDLER = 'callfunctionwithhandler',
  CALL_FUNCTION = 'callfunction',
  LOGOUT = 'logout',
  DASHBOARD = 'dashboard',
  QUICK_MENU = 'quickMenu',
  LIST_IMAGE_HOVER = 'list_image_hover',
  SET_ENTITY_DATA = 'set_entity_data',
  COMMIT = 'commit',
  NEXT_STEP = 'nextStep',
  PREVIOUS_STEP = 'previousStep',
  ON_BEGIN_EDIT = 'on_begin_edit'
}

export enum ControlTypeEnum {
  TEXTAREA = 'textarea',
  BOOLEAN = 'Boolean',
  Integer = 'Integer',
  HTML = 'html',
  TEXT = 'text',
  TEXT_STATIC = 'text-static',
  FILE = 'File',
  SVG = 'SVG',
  RADIO = 'radio',
  BUTTON = 'button',
  MERMAID = 'mermaid'
}

export interface WorkflowView {
  workflow: Workflow;
  view: View;
  tabWorkflowView?: WorkflowView;
}

export interface ConfigEntry {
  name?: string;
  path?: string;
  observer?: Observable<any>;
  config?: any;
}

export interface ConfirmDialogData {
  title: string;
  targetUrl: string;
  rollback: boolean;
}

export interface Settings {
  env?: string;
  appIdSuffix?: string;
  backend_url?: string;
  sso_url?: string;
  allowed_upload_file_formats?: string;
  allowed_upload_max_file_size?: string;
  default_route_after_login?: string;
  default_dashboard_after_login?: string;
  navbar_logo: AssetPic;
  login_background_image?: AssetPic;
  login_navbar_image?: AssetPic;
  auto_login?: boolean;
  userRole?: string;
  poller?: boolean;
  pollerInterval?: number;
  AppIdSuffix?: string;
  login?: Login;
  loaderEffect?: boolean;
  defaultUserRoleCallFunction?: string;
  defaultClientCallFunction?: string;
  userRoleAlternative?: boolean;
  toastTimeout?: number;
  kioskMode: KioskMode;
  initialCollapsedSidebar?: boolean;
  userMessagesType: UserMessagesTypeEnum;
  pitSessionTimeoutInMinutes?: number;
}

export enum UserMessagesTypeEnum {
  DIALOG = 'dialog',
  toast = 'toast'
}

export interface KioskMode {
  hideToolbar?: boolean;
}

export interface Login {
  imprint?: string;
}

export interface PaginationLabels {
  pageBegin: number;
  pageEnd: number;
  entryCount: number;
}

export interface Languages {
  languages: LanguagesElement[];
}

export interface LanguagesElement {
  default?: boolean;
  countryCode: string;
  languageName: string;
  regionName: string;
  locale: string;
}


export interface TranslationFile {
  [key: string]: TranslationEntry;
}

export interface TranslationEntry {
  [key: string]: string;
}

export interface ConfigFileElement {
  name: string;
  path: string;

}

export interface RefFilter {
  entityData: EntityData;
  column: Column;
  entityHandle?: number;
}

export interface PitException {
  error: PitError;


}

export interface PitError {
  message?: string;
  title?: string;
  type?: number;
  detail?: string;
  errorDetail?: PitExceptionDetail;
}


export interface PitExceptionDetail {
  message: string;
  result: number;
  buttonsAndIconsType: number;
  type: PitTypeEnum;
  queryTypeId: number;
  titleCaption: string;
}

export interface MessageToast {
  cssClass?: string;
  id?: string;
  title: string;
  status?: string;
  content: string;
  type: MessageEnum;
}


export enum PitTypeEnum {
  CLIENTCALLBACKMESSAGEBOXQUERY = 'ClientCallbackMessageBoxQuery',
}

export interface MessageBox {
  queryButtonEnum: number;
  queryIconEnum: number;
  title: string;
  type: number;
  message: string;
}

export enum QueryButtonEnum {
  OK = 0,
  OK_CANCEL = 1,
  ABORT_RETRY_IGNORE = 2,
  YES_NO_CANCEL = 3,
  YES_NO = 4,
  RETRY_CANCEL = 5,
}

export enum QueryIconEnum {
  ICON_ERROR = 16,
  ICON_QUESTION = 32,
  ICON_WARNING = 48,
  ICON_INFORMATION = 64,
}

export enum SortingEnum {
  E_ASCENDING = 'eAscending',
  E_DESCENDING = 'eDescending'
}

export enum EntityDescriptorFlagsEnum {
  FLAG_HAS_NO_PRIVILEGE0 = -1,
  FLAG_IS_TEMPORARY = 16,
  FLAG_IS_SORTABLE = 32,
  FLAG_HAS_READ_PRIVILEGE = 256,
  FLAG_HAS_WRITE_PRIVILEGE = 512,
  FLAG_CREATE_DELETE_PRIVILEGE = 1024
}

export const CreatePermittedActivatorModes: ActivatorTypeEnum[] = [
  ActivatorTypeEnum.CREATE_ENTITY,
  ActivatorTypeEnum.CREATE_AND_ASSIGN_ENTITY,
  ActivatorTypeEnum.ASSIGN_ENTITY,
  ActivatorTypeEnum.SAVE_ENTITY,
  ActivatorTypeEnum.UPLOAD,
  ActivatorTypeEnum.ROLLBACK_ENTITY,
  ActivatorTypeEnum.ROLLBACK_AND_GET_DATA_ENTITY,

];


export enum MessageEnum {
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success'
}

export enum ListSettingsTypeEnum {
  MULTI_SELECT = 'multiselect'
}

export interface AssetPic {
  path?: string;
  width?: string;
  height?: string;
  bgColor?: string;
}

export interface KanbanData {
  x: KanbanAxisData[];
  projects: any;
  y: KanbanAxisData[];
}


export interface KanbanAxisData {
  text: string;
  id: string,
}

export interface HierarchicalData {
  id: string;
  name: string;
  subChild: HierarchicalData;
}

export interface TreeViewNode {
  id: string;
  name: string;
  subChild: TreeViewNode;
}

export interface FindInEntityDataResponse {
  entityData?: EntityData;
  level?: number;

}

export interface EntityObservers {
  observe: Observable<any>;
  binding: string;
  result: EntityDescriptorResponse;
}

export interface MatrixData {
  x: EntityData[];
  y: EntityData[];
  intersections: EntityData[];
  data?: any[];
  headerColumns?: HeaderColumn[];
}

export enum SvgFunctionEnum {
  CREATE = 'create',
  EDIT = 'edit',
  MOVE = 'move'
}

export interface HeaderColumn {
  id: string;
  name: string;
  label?: string;
  width?: string;
}

export enum LandingPageEnum {
  NOT_FOUND = '404',
  CLIENT_CHANGE = 'client_change',
  ROLE_CHANGE = 'role_change',
  CUSTOM = 'custom'
}

export enum PitNameEnum {
  PIT_FM_SMART = 'pit_fm_smart'
}

export interface Timeline {
  settings?: TimelineSettings;
  yAxis?: AxisData;
  yAxisGroup?: AxisData;
  appointment: TimelineAttributes;
  filter: FilterFile;
  onCreate?: Activator;
  onEdit?: Activator;
  contextMenu: Activator[];
  activators: Activator[];
}


export interface TimelineSettings {
  allowPastAppointment?: boolean;
  allowAppointmentCollision?: boolean;

}


export interface TimelineResult {
  timelineRawData: EntityData[];
  timelineResources: TimelineResource[];
  timelineData: TimelineData[];
  eventSettings: EventSettingsModel;
  contextMenu: MenuItemModel[];
  timelineFilter: TimelineFilter;
  handoverStartTime?: Date;
  handoverEndTime?: Date;
  handoverObject?: number;
  timelineCategories?: TimelineCategory[];
  timelineCategoryMatrix?: TimelineCategory[];
}

export interface FileMapping {
  config: string;
  settings: string;
  languages: string;
  releaseNotes: string;
  i18nFolder: string;
  imageFolder: string;
  supportedFiles: string;
  landingPages: string;
}

export enum FileMappingEnum {
  CONFIG = 'config',
  SETTINGS = 'settings',
  LANGUAGES = 'languages',
  releaseNotes = 'releaseNotes',
  I_18N_FOLDER = 'i18nFolder',
  SUPPORTED_FILES = 'supportedFiles',
  LANDING_PAGES = 'langingPages'
}

export interface TimelineData {
  Id: string;
  // groupId: number,
  text: string;
  Description: string[] | string;
  Subject: string;
  Location: string;
  StartTime: Date;
  EndTime: Date;
  CategoryColor: string;
  RoomId: number;
  ProjectId: number;
  TaskId: number;
  AppointmentHandle?: number;
}

export interface TimelineAttributes {

  Subject: string[];
  Location: string;
  StartTime: string;
  EndTime: string;
  groupAttribute?: string;
  CategoryColor: string;
  yAxis: string;
  filter?: FilterFile;
}

export interface TimelineResource {
  handle?: number;
  id?: number;
  color?: string;
  text?: string[];
  groupId?: number;
  Capacity?: string;

}

export interface TimeLineResourcesData {
  timelineResources: TimelineResource[];
  timelineCategory: TimelineCategory[];
  timelineResourceRawData: EntityData[];
  timelineCategoryMatrix: TimelineCategory[];
}

export interface TimelineCategory {
  id: number;
  text: string;
  color: string;
}

export interface FilterBarData {

  inputFormData: InputFormData;
  inputData: InputData;
}

export enum FilterParameterTypeEnum {
  STRING = 'string',
  ENTITY = 'entity'
}

export enum MessageTypeEnum {
  SUCCESS = 'success',
  ERROR = 'error',
  NOTICE = 'notice'
}

export interface Message {
  title: string;
  text: string;
  type: MessageTypeEnum;
}

export interface WizardNavNames {
  submit?: string;
  cancel?: string;
  next?: string;
  previous?: string;
}

export interface Asset {
  icon?: string;
  title?: string;
  attribute?: string;
  key?: string;
  value?: string;
  type?: string;
}

export interface PinData {
  pin_id?: string;
  room_id?: string;
  roomId?: string;
  ereignis_id?: string;
  X?: number;
  Y?: Number;
}

export interface PinDialogData {
  titles?: KeyValue[];
  images?: Asset[];
  documents?: Asset[];
  markerHandle?: number;
  markerEntityId?: string;
}

export interface MarkerResults {
  rawData?: EntityData[];
  markers: PinData[];
}

export interface SupportedFilesConfig {
  supported_files: SupportedFile[];
  unknown_file_type: SupportedFile;
}

export interface SupportedFile {
  type?: string;
  icon: string;
  mime?: MimeTypeEnum;
}

export enum MimeTypeEnum {
  IMAGE = 'image',
  DOCUMENT = 'document'
}

export enum TranslationScopes {
  CONTENT_FROM_CONFIG_JSON = 'content_from_config_json'
}
